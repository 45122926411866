import React, { useState } from 'react';
import EarnPoints from './EarnPoints';
import Connection from 'app/entities/connection/connection';
import { Container } from 'reactstrap';
import Inspiration from 'app/entities/inspiration/inspiration';
import InspirationUpdate from './inspirations-update';

function ManagePoints() {
  const [showView, setShowView] = useState('Manage');
  const handleSubmit = async () => {
    setShowView('list');
  };
  return (
    <div>
      <Container className="inspiration-page card meeting-border">
        {showView == 'Manage' && <EarnPoints />}
        {showView == 'add' && <InspirationUpdate handleSubmit={handleSubmit} showView={showView} />}
      </Container>
    </div>
  );
}

export default ManagePoints;
