import { IUser } from 'app/shared/model/user.model';
import { IConnection } from 'app/shared/model/connection.model';

export interface IQuestion {
  id?: number;
  question?: string | null;
  user?: IUser | null;
  connections?: IConnection[] | null;
}

export const defaultValue: Readonly<IQuestion> = {};
