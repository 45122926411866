export enum PointAllocationTypes {
  Referral = 'Referral',

  CollegeAdmission = 'CollegeAdmission',

  Graduation = 'Graduation',

  Master = 'Master',

  Phd = 'Phd',

  CommunityService = 'CommunityService',

  CrowedFunding = 'CrowedFunding',

  StartUp = 'StartUp',

  KindnessAct = 'KindnessAct',
}
