import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Button, CloseButton, Col, Modal, ModalBody, ModalFooter } from 'reactstrap';

const DocumentUploadComponent = ({ isOpen, toggle, onDocumentUpload, achievements }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState('');
  const [imagePreview, setImagePreview] = useState('');
  const baseImageUrl = '/image';
  const [uploadedImage, setUploadedImage] = useState(null);
  const [imageName, setImageName] = useState(null);

  useEffect(() => {
    // Clear the old data when achievement changes or modal is closed
    setSelectedFile(null);
    setError('');
    setImagePreview('');
    setUploadedImage(null);
    setImageName(null);
  }, [achievements, isOpen]);

  const handleFileSelect = event => {
    const selectedFile = event.target.files[0];
    setSelectedFile(selectedFile);

    if (selectedFile && selectedFile.type === 'application/pdf') {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        const result = fileReader.result;
        if (typeof result === 'string') {
          setImagePreview(result);
        }
      };
      fileReader.readAsDataURL(selectedFile);
    } else {
      setImagePreview('');
    }
  };

  const handleUpload = () => {
    if (selectedFile) {
      // Check if the file size is greater than 10MB
      if (selectedFile.size > 10 * 1024 * 1024) {
        setError('File size should be less than 10MB');
        return;
      }

      const formData = new FormData();
      formData.append('docFile', selectedFile);

      axios
        .post(`/api/document/upload?achievement=${achievements}`, formData)
        .then(response => {
          const fullImageUrl = `${baseImageUrl}/${response.data}`;
          const fileUrl = `/doc/${response.data}`;
          setImagePreview(fullImageUrl);
          setImageName(response.data);
          setUploadedImage(response.data);
          onDocumentUpload(fileUrl);
          setError(''); // Clear any previous error
          //onDocumentUpload(selectedFile); // Call the parent component's function to handle document upload
          toggle(); // Close the modal on success
        })
        .catch(error => {
          console.error('Error uploading image:', error);
          setError('Error uploading image. Please try again.'); // Set an appropriate error message
        });
    }
  };

  const handleRemoveImage = () => {
    setImagePreview('');
    setUploadedImage(null);
    setImageName('');
  };

  return (
    <Modal className="earn-point-popup" isOpen={isOpen} toggle={toggle}>
      <ModalBody>
        <h5>Select a document to upload:</h5>
        <input type="file" onChange={handleFileSelect} />
        {error && <div className="text-danger">{error}</div>}
      </ModalBody>
      <ModalFooter>
        <Col md={12}>
          {imagePreview && (
            <div className="remove-img-btn">
              <CloseButton onClick={handleRemoveImage} />
              <embed className="img-thumbnail aaa mb-4 p-0" src={imagePreview} width="100%" height="400px" type="application/pdf" />
            </div>
          )}
        </Col>
        <Button color="primary" onClick={handleUpload} disabled={!selectedFile}>
          Upload
        </Button>
        {/* <Button color="secondary" onClick={toggle}>
          Cancel
        </Button> */}
      </ModalFooter>
    </Modal>
  );
};

export default DocumentUploadComponent;
