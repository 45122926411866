import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './meeting.reducer';

export const MeetingDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const meetingEntity = useAppSelector(state => state.meeting.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="meetingDetailsHeading">
          <Translate contentKey="fivePlusApp.meeting.detail.title">Meeting</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{meetingEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="fivePlusApp.meeting.name">Name</Translate>
            </span>
          </dt>
          <dd>{meetingEntity.name}</dd>
          <dt>
            <span id="meetingTime">
              <Translate contentKey="fivePlusApp.meeting.meetingTime">Meeting Time</Translate>
            </span>
          </dt>
          <dd>{meetingEntity.meetingTime}</dd>
          <dt>
            <span id="duration">
              <Translate contentKey="fivePlusApp.meeting.duration">Duration</Translate>
            </span>
          </dt>
          <dd>{meetingEntity.duration}</dd>
          <dt>
            <span id="statusType">
              <Translate contentKey="fivePlusApp.meeting.statusType">Status Type</Translate>
            </span>
          </dt>
          <dd>{meetingEntity.statusType}</dd>
          <dt>
            <span id="dailyCoLink">
              <Translate contentKey="fivePlusApp.meeting.dailyCoLink">Daily Co Link</Translate>
            </span>
          </dt>
          <dd>{meetingEntity.dailyCoLink}</dd>
          <dt>
            <span id="daliyCoRoom">
              <Translate contentKey="fivePlusApp.meeting.daliyCoRoom">Daliy Co Room</Translate>
            </span>
          </dt>
          <dd>{meetingEntity.daliyCoRoom}</dd>
          <dt>
            <Translate contentKey="fivePlusApp.meeting.user">User</Translate>
          </dt>
          <dd>{meetingEntity.user ? meetingEntity.user.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/meeting" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/meeting/${meetingEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default MeetingDetail;
