import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ReferralCode from './referral-code';
import ReferralCodeDetail from './referral-code-detail';
import ReferralCodeUpdate from './referral-code-update';
import ReferralCodeDeleteDialog from './referral-code-delete-dialog';

const ReferralCodeRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ReferralCode />} />
    <Route path="new" element={<ReferralCodeUpdate />} />
    <Route path=":id">
      <Route index element={<ReferralCodeDetail />} />
      <Route path="edit" element={<ReferralCodeUpdate />} />
      <Route path="delete" element={<ReferralCodeDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ReferralCodeRoutes;
