import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import UserAchievements from './user-achievements';
import UserAchievementsDetail from './user-achievements-detail';
import UserAchievementsUpdate from './user-achievements-update';
import UserAchievementsDeleteDialog from './user-achievements-delete-dialog';

const UserAchievementsRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<UserAchievements />} />
    <Route path="new" element={<UserAchievementsUpdate />} />
    <Route path=":id">
      <Route index element={<UserAchievementsDetail />} />
      <Route path="edit" element={<UserAchievementsUpdate />} />
      <Route path="delete" element={<UserAchievementsDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default UserAchievementsRoutes;
