import React from 'react';
import { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Button } from 'reactstrap';
import ReactDOM from 'react-dom';

const ReferralCopyButton = props => {
  const [isCopied, setIsCopied] = useState(false);
  // const textToCopy = 'Text to be copied';
  const handleCopy = () => {
    console.log(props.referallink, 'props.referallink');
    setIsCopied(true);
    // You can add additional logic here after the text is copied
  };

  return (
    <div>
      <CopyToClipboard text={props.referallink} onCopy={handleCopy}>
        <Button>Copy to Clipboard</Button>
      </CopyToClipboard>
      {isCopied ? <span>Copied!</span> : null}
    </div>
  );
};
export default ReferralCopyButton;
