import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IMeeting, defaultValue } from 'app/shared/model/meeting.model';

const initialState: EntityState<IMeeting> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/meetings';
const meetingApiUrl = '/api/createMeeting';
//const meetingDataUrl = 'api/get/meetings/information';

// Actions

export const getEntities = createAsyncThunk('meeting/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}`;
  return axios.get<IMeeting[]>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'meeting/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IMeeting>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);
// export const getEntityMeetingDetails = createAsyncThunk(
//   'meeting/fetch_entity_meeting_details',
//   async () => {
//     const requestUrl = `${meetingDataUrl}`;
//     return axios.get<IMeeting>(requestUrl);
//   },
//   { serializeError: serializeAxiosError }
// );

export const createEntity = createAsyncThunk(
  'meeting/create_entity',
  async (entity: IMeeting, thunkAPI) => {
    const result = await axios.post<IMeeting>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const createUserMeeting = createAsyncThunk(
  'meeting/create_user_meeting',
  async (entity: IMeeting, thunkAPI) => {
    try {
      const result = await axios.post<IMeeting>(meetingApiUrl, cleanEntity(entity));
      thunkAPI.dispatch(getEntities({}));
      return result;
    } catch (error) {
      if (error.response && error.response.status === 406) {
        // Handle the 406 Not Acceptable error here
        // You can pass an error message to be displayed in the component
        throw new Error('Points are not sufficient to create a meeting.');
      } else {
        // Handle other errors or rethrow them if needed
        throw error;
      }
    }
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'meeting/update_entity',
  async (entity: IMeeting, thunkAPI) => {
    const result = await axios.put<IMeeting>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'meeting/partial_update_entity',
  async (entity: IMeeting, thunkAPI) => {
    const result = await axios.patch<IMeeting>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'meeting/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IMeeting>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const MeetingSlice = createEntitySlice({
  name: 'meeting',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      // .addCase(getEntityMeetingDetails.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.entity = action.payload.data;
      // })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, createUserMeeting, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      // .addMatcher(isPending(getEntities, getEntity, getEntityMeetingDetails), state => {
      //   state.errorMessage = null;
      //   state.updateSuccess = false;
      //   state.loading = true;
      // })
      .addMatcher(isPending(createEntity, createUserMeeting, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = MeetingSlice.actions;

// Reducer
export default MeetingSlice.reducer;
