import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { ITopic } from 'app/shared/model/topic.model';
import { getEntities as getTopics } from 'app/entities/topic/topic.reducer';
import {
  getEntityInspirationsAdmin,
  partialUpdateEntityInspiration,
  deleteInspirationEntity,
} from 'app/entities/inspiration/inspiration.reducer';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import { Container, Row, Col, Button, Label, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { JhiItemCount, JhiPagination, Translate, getSortState } from 'react-jhipster';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
const InspirationAdminPage: React.FC = () => {
  const inspirationEntity = useAppSelector(state => state.inspiration.entity);
  const account = useAppSelector(state => state.authentication.account);
  const [selectedInspirationId, setSelectedInspirationId] = useState(null);
  const [inspirationData, setInspirationData] = useState([]);
  const loading = useAppSelector(state => state.inspiration.loading);
  const [showView, setShowView] = useState('list');
  const baseImageUrl = '/image';
  const dispatch = useAppDispatch();
  const id = account.id;
  const navigate = useNavigate();
  const totalItems = useAppSelector(state => state.inspiration.totalItems);
  const baseImageUrlProfile = '/profile/get/image';
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, 20, 'id', 'desc'), location.search)
  );
  useEffect(() => {
    dispatch(
      getEntityInspirationsAdmin({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      })
    );
  }, [paginationState]);
  useEffect(() => {
    setInspirationData(inspirationEntity);
  }, [inspirationEntity]);
  const onApprove = async inspirationId => {
    try {
      const updatedInspiration = { id: inspirationId, approved: true };
      await dispatch(partialUpdateEntityInspiration(updatedInspiration));
      dispatch(
        getEntityInspirationsAdmin({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
        })
      );
    } catch (error) {
      console.error('Error approving inspiration:', error);
    }
  };
  const deleteInspiration = async id => {
    await dispatch(deleteInspirationEntity(id));
    dispatch(
      getEntityInspirationsAdmin({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      })
    );
  };
  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });
  function formatDate(dateString) {
    const inputDate = new Date(dateString);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    if (inputDate >= currentDate) {
      const options = {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      };
      return 'Today ' + inputDate.toLocaleTimeString('en-US', options as Intl.DateTimeFormatOptions);
    } else {
      const oneDayAgo = new Date(inputDate);
      oneDayAgo.setUTCDate(inputDate.getUTCDate() - 1);

      const options = {
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      };

      return oneDayAgo.toLocaleString('en-US', options as Intl.DateTimeFormatOptions);
    }
  }

  return (
    <Container className="inspiration-page card meeting-border">
      {loading ? (
        <Spinner className="body-loader-icon"></Spinner>
      ) : (
        <>
          <Row className="page-header">
            <Col md="6" className="heading">
              <h1>My Inspiration</h1>
            </Col>
          </Row>
          <Row className="inspiration-list">
            {Array.isArray(inspirationData) && inspirationData.length > 0 ? (
              inspirationData.map(inspiration => (
                <Col md="4" key={inspiration.id}>
                  <div className="inspiration-item">
                    <div className="">
                      <p className={`ApprovalStatus ${inspiration.approved ? 'Approved' : 'WaitingApproval'}`}>
                        {inspiration.approved ? 'Approved' : 'Waiting for Approval'}
                      </p>
                    </div>
                    <div className="inspiration-text">{inspiration.description}</div>
                    <div className="inspiration-topic">{inspiration.topics}</div>
                    {
                      <Col md={12} className="inspiration-img">
                        {inspiration?.imageName ? (
                          <img className="img-thumbnail mb-4 p-0" src={`${baseImageUrl}/${inspiration.imageName}`} />
                        ) : null}
                      </Col>
                    }
                    <div className="inspiration-link">
                      <a href="{inspiration.link}">{inspiration.link}</a>
                    </div>
                    <Row>
                      <Col md="6">{<div className="inspiration-time">{formatDate(inspiration.createdDate)}</div>}</Col>
                      {!inspiration.approved && (
                        <button className="approve-btn news-add-btn" onClick={() => onApprove(inspiration.id)}>
                          Approve
                        </button>
                      )}
                    </Row>
                    <div className="inspiration-profile-details">
                      <div className="inspiration-user-profile">
                        {inspiration?.user.imageUrl ? (
                          <img className="img-thumbnail p-0" src={`${baseImageUrlProfile}/${inspiration?.user.imageUrl}`} />
                        ) : null}

                        <div className="inspiration-user-name">{inspiration.user.firstName + ' ' + inspiration.user.lastName}</div>
                      </div>
                    </div>
                    <Button className="news-dlt-btn" onClick={() => deleteInspiration(inspiration.id)}>
                      <FontAwesomeIcon icon="trash" />{' '}
                      {/* <span className="d-none d-md-inline">
                        <Translate contentKey="entity.action.delete">Delete</Translate>
                      </span> */}
                    </Button>
                  </div>
                </Col>
              ))
            ) : (
              <div className="connection-tab-gif">
                <Row>
                  <div className="col-md-6">
                    {/* <h4>Build Your Professional Network</h4> */}
                    <p>
                      Here you can find and share your own inspiring stories, ideas, and experiences! We encourage you to tap into your own
                      creativity and wisdom to inspire others. Whether it's a personal success story, a motivational quote, or a creative
                      project, this is your platform to uplift and empower the community. So don't be shy – share your inspiration and let's
                      create a positive and supportive space together!
                    </p>
                  </div>
                  <div className="col-md-6">
                    <img src="content/images/inspiration_no_content.png" />
                  </div>
                </Row>
              </div>
            )}
          </Row>
          {totalItems ? (
            <div className={inspirationData && inspirationData.length > 0 ? '' : 'd-none'}>
              <div className="justify-content-center d-flex">
                <JhiItemCount
                  page={paginationState.activePage}
                  total={totalItems}
                  itemsPerPage={paginationState.itemsPerPage}
                  i18nEnabled
                />
              </div>
              <div className="justify-content-center d-flex">
                <JhiPagination
                  activePage={paginationState.activePage}
                  onSelect={handlePagination}
                  maxButtons={5}
                  itemsPerPage={paginationState.itemsPerPage}
                  totalItems={totalItems}
                />
              </div>
            </div>
          ) : (
            ''
          )}
        </>
      )}
    </Container>
  );
};

export default InspirationAdminPage;
