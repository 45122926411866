import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardTitle, CardSubtitle, Row, Container, Spinner } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntityAdminTopFive } from 'app/entities/adminprofile/adminprofile.reducer';
import axios from 'axios';
export const UsersAdminDashboard = () => {
  const dispatch = useAppDispatch();
  const adminProfileList = useAppSelector(state => state.adminProfile?.entities || []);
  const [activeUserCount, setActiveUserCount] = useState(0);
  const [inactiveUserCount, setInactiveUserCount] = useState(0);
  const loading = useAppSelector(state => state.adminProfile.loading);
  const baseImageUrl = '/profile/get/image';

  const [totalUsers, setTotalUsers] = useState(Number);
  useEffect(() => {
    // Fetch counts of active and inactive users from the API using Axios
    const fetchActiveUserCounts = async () => {
      try {
        const response = await axios.get('/api/admin/count?isActive=false');
        const data = response.data;
        setInactiveUserCount(data || 0);
      } catch (error) {
        console.error('Error fetching user counts:', error);
      }
    };
    const fetchInActiveUserCounts = async () => {
      try {
        const response = await axios.get('/api/admin/count?isActive=true');
        const data = response.data;
        setActiveUserCount(data || 0);
      } catch (error) {
        console.error('Error fetching user counts:', error);
      }
    };
    const fetchUsersCount = async () => {
      try {
        const response = await axios.get('api/admin/get/profiles');
        const data = response.data.totalElements;
        setTotalUsers(data || 0);
      } catch (error) {
        console.error('Error fetching user counts:', error);
      }
    };
    fetchUsersCount();
    fetchActiveUserCounts();
    fetchInActiveUserCounts();
  }, []);
  useEffect(() => {
    dispatch(getEntityAdminTopFive());
  }, []);

  return (
    <Container className="admin-page card meeting-border">
      {loading ? (
        <Spinner className="body-loader-icon"></Spinner>
      ) : (
        <>
          <Row className="admin-page-row">
            <div className="col-md-6 active-inactive-users">
              <div className="total-user-heading mb-3">
                <div className="total-users">
                  <p>Total Users</p> <span>{totalUsers}</span>
                </div>
                <div className="total-users-img">
                  <img src="content/images/total-users-icon.png" />
                </div>
              </div>

              <div className="active-user mb-2">
                <div className="total-users">
                  <p>Active Users</p> <span>{activeUserCount}</span>
                </div>
                <div className="total-users-img">
                  <img src="content/images/active-user-icon.png" />
                </div>
              </div>

              <div className="inactive-user mb-2">
                <div className="total-users">
                  <p>Inactive Users</p>
                  <span>{inactiveUserCount}</span>
                </div>
                <div className="total-users-img">
                  <img src="content/images/inactive-user-icon.png" />
                </div>
              </div>
            </div>
            {adminProfileList && adminProfileList.length > 0 ? (
              <div className="col-md-6 top-five-users">
                <h3>Top Five users</h3>
                {adminProfileList.map((user, index) => (
                  <Card key={index} className="mb-3 ">
                    <CardBody>
                      <div className="d-flex flex-row align-items-center">
                        <div className="me-3 connection-avatar">
                          {user.user && user.user.imageUrl ? (
                            <img className="rounded-circle initials-avatar" alt="" src={`${baseImageUrl}/${user.user.imageUrl}`} />
                          ) : (
                            <div className="initials-avatar">
                              {user.user &&
                                user.user.firstName &&
                                user.user.lastName &&
                                `${user.user.firstName.charAt(0)}${user.user.lastName.charAt(0)}`}
                            </div>
                          )}
                        </div>
                        <div>
                          <CardTitle tag="h5">
                            {user.user && user.user.firstName && user.user.lastName && `${user.user.firstName} ${user.user.lastName}`}
                          </CardTitle>
                          <CardSubtitle className="text-muted" tag="h6">
                            {user.occupation || 'No occupation'}
                          </CardSubtitle>
                        </div>
                      </div>
                      <div className={`${user.isMentor ? 'Mentor' : 'Mentee'}`}>
                        {user.user && `${user.isMentor ? 'Mentor' : 'Mentee'}`}
                      </div>
                    </CardBody>
                  </Card>
                ))}
              </div>
            ) : (
              ''
            )}
          </Row>
        </>
      )}
    </Container>
  );
};

export default UsersAdminDashboard;
