import React, { useState } from 'react';
interface FilterOption {
  name: string;
  value: string;
}

interface FiltersDropdownProps {
  options: FilterOption[];
  onChange: (selectedOption: string) => void;
}

const FiltersDropdown: React.FC<FiltersDropdownProps> = ({ options, onChange }) => {
  const [selectedOption, setSelectedOption] = useState('');

  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setSelectedOption(value);
    onChange(value);
  };

  return (
    <select className="connection-search-option" value={selectedOption} onChange={handleOptionChange}>
      {options.map(option => (
        <option key={option.value} value={option.value}>
          {option.name}
        </option>
      ))}
    </select>
  );
};

export default FiltersDropdown;
