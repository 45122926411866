import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import UserProfile from './user-profile';
//import UserProfileDetail from './user-profile-detail';
import UserProfileDetail from 'app/views/user_profile/login/user-profile-details';
import UserProfileUpdate from 'app/views/user_profile/login/user-profile-update';
//import UserProfileUpdate from './user-profile-update';
import UserProfileDeleteDialog from './user-profile-delete-dialog';
import PageNotFound from 'app/shared/error/page-not-found';

const UserProfileRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<PageNotFound />} />
    <Route path="new" element={<UserProfileUpdate />} />
    <Route path=":id">
      <Route index element={<UserProfileDetail />} />
      <Route path="edit" element={<UserProfileUpdate />} />
      <Route path="delete" element={<UserProfileDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default UserProfileRoutes;
