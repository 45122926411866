import React, { useEffect } from 'react';
import { REDIRECT_URL } from 'app/shared/util/url-utils';
import { useLocation } from 'react-router';
import { Spinner } from 'reactstrap';

export const LoginRedirect = () => {
  const location = useLocation();

  useEffect(() => {
    localStorage.setItem(REDIRECT_URL, (location.state as any).from.pathname);
    window.location.reload();
  });

  return (
    <div className="token-loader-home">
      <div className="token-loader">
        <Spinner className="body-loader-icon"></Spinner>
      </div>
    </div>
  );
};

export default LoginRedirect;
