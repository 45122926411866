import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import MenteeTopic from './mentee-topic';
import MenteeTopicDetail from './mentee-topic-detail';
import MenteeTopicUpdate from './mentee-topic-update';
import MenteeTopicDeleteDialog from './mentee-topic-delete-dialog';

const MenteeTopicRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<MenteeTopic />} />
    <Route path="new" element={<MenteeTopicUpdate />} />
    <Route path=":id">
      <Route index element={<MenteeTopicDetail />} />
      <Route path="edit" element={<MenteeTopicUpdate />} />
      <Route path="delete" element={<MenteeTopicDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default MenteeTopicRoutes;
