import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import PointAllocation from './point-allocation';
import PointAllocationDetail from './point-allocation-detail';
import PointAllocationUpdate from './point-allocation-update';
import PointAllocationDeleteDialog from './point-allocation-delete-dialog';

const PointAllocationRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<PointAllocation />} />
    <Route path="new" element={<PointAllocationUpdate />} />
    <Route path=":id">
      <Route index element={<PointAllocationDetail />} />
      <Route path="edit" element={<PointAllocationUpdate />} />
      <Route path="delete" element={<PointAllocationDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default PointAllocationRoutes;
