import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IConnection, defaultValue } from 'app/shared/model/search.model';

const initialState: EntityState<IConnection> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const searchApiUrl = 'api/search/user';
// Actions

export const getEntitySearchConnections = createAsyncThunk<IConnection, [string | number, string], {}>(
  'connection/fetch_entity_search_connection',
  async ([name, userType]) => {
    const requestUrl = `${searchApiUrl}?name=${name}&filter=${userType}`;
    // const response = await axios.get<IConnection>(requestUrl);
    // return response.data;
    try {
      const response = await axios.get<IConnection>(requestUrl);
      return response.data;
    } catch (error) {
      // Handle the error and return it with `rejectWithValue`

      if (error.response && error.response.status === 404) {
        return error.response.data;
      }
      throw error;
    }
  },
  { serializeError: serializeAxiosError }
);

// slice

export const SearchSlice = createEntitySlice({
  name: 'search',
  initialState,
  extraReducers(builder) {
    builder

      .addCase(getEntitySearchConnections.pending, state => {
        state.loading = true;
      })
      .addCase(getEntitySearchConnections.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload;
      })
      .addCase(getEntitySearchConnections.rejected, state => {
        state.loading = false;
        //state.errorMessage =action.payload as string;
        // console.log('Error:');
        // Handle the rejected case if needed
      });
  },
});

export const { reset } = SearchSlice.actions;

// Reducer
export default SearchSlice.reducer;
