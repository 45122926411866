import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IMenteeTopic, defaultValue } from 'app/shared/model/mentee-topic.model';

const initialState: EntityState<IMenteeTopic> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/mentee-topics';

// Actions

export const getEntities = createAsyncThunk('menteeTopic/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}`;
  return axios.get<IMenteeTopic[]>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'menteeTopic/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IMenteeTopic>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);
export const getEntityMenteeTopic = createAsyncThunk(
  'menteeTopic/fetch_entity_mentee_topic',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/?userId.equals=${id}`;
    return axios.get<IMenteeTopic>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'menteeTopic/create_entity',
  async (entity: IMenteeTopic, thunkAPI) => {
    const result = await axios.post<IMenteeTopic>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);
export const createEntityMenteeTopic = createAsyncThunk(
  'menteeTopic/create_entity_mentee-topic',
  async (entity: IMenteeTopic, thunkAPI) => {
    const result = await axios.post<IMenteeTopic>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'menteeTopic/update_entity',
  async (entity: IMenteeTopic, thunkAPI) => {
    const result = await axios.put<IMenteeTopic>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);
export const updateEntityMenteeTopics = createAsyncThunk(
  'menteeTopic/update_entity_mentee_topics',
  async (entity: IMenteeTopic, thunkAPI) => {
    const result = await axios.put<IMenteeTopic>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'menteeTopic/partial_update_entity',
  async (entity: IMenteeTopic, thunkAPI) => {
    const result = await axios.patch<IMenteeTopic>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'menteeTopic/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IMenteeTopic>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);
export const deleteEntityMenteeTopics = createAsyncThunk(
  'menteeTopic/delete_entity_Mentee_topics',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IMenteeTopic>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const MenteeTopicSlice = createEntitySlice({
  name: 'menteeTopic',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addCase(deleteEntityMenteeTopics.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addCase(getEntityMenteeTopic.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity, createEntityMenteeTopic), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity, getEntityMenteeTopic), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity, createEntityMenteeTopic), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = MenteeTopicSlice.actions;

// Reducer
export default MenteeTopicSlice.reducer;
