import { IUser } from 'app/shared/model/user.model';
import { ITopic } from 'app/shared/model/topic.model';
import { IQuestion } from 'app/shared/model/question.model';

export interface IConnection {
  id?: number;
  status?: string;
  userMentor?: IUser | null;
  userMentee?: IUser | null;
  topics?: ITopic[] | null;
  questions?: IQuestion[] | null;
}

export const defaultValue: Readonly<IConnection> = {};
