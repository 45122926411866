import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Row, Col, FormText, Container, Form, Spinner, CloseButton } from 'reactstrap';
import { Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IUser } from 'app/shared/model/user.model';
import { getUsers } from 'app/shared/reducers/user-management';
import { ITopic } from 'app/shared/model/topic.model';
import { getEntities as getTopics } from 'app/entities/topic/topic.reducer';
import { IUserProfile } from 'app/shared/model/user-profile.model';
import { updateEntity, createEntity, reset, getEntityProfileDetails, uploadImage } from 'app/entities/user-profile/user-profile.reducer';
import { createEntityMenteeQuestions, getEntityMenteeQuestions } from 'app/entities/question/question.reducer';
import { getEntityMenteeTopic } from 'app/entities/mentee-topic/mentee-topic.reducer';
import { getEntityMentorTopic } from 'app/entities/mentor-topic/mentor-topic.reducer';
import { updateEntityMenteequestion } from 'app/entities/question/question.reducer';
import { Country, State, City } from 'country-state-city';
import { ICountry, IState, ICity } from 'country-state-city';
import { updateEntityMenteeTopics } from 'app/entities/mentee-topic/mentee-topic.reducer';
import { updateEntityMentorTopics } from 'app/entities/mentor-topic/mentor-topic.reducer';
import { createEntityMentorTopic } from 'app/entities/mentor-topic/mentor-topic.reducer';
import { createEntityMenteeTopic } from 'app/entities/mentee-topic/mentee-topic.reducer';
import { deleteEntityMenteeTopics } from 'app/entities/mentee-topic/mentee-topic.reducer';
import { deleteEntityMentorTopic } from 'app/entities/mentor-topic/mentor-topic.reducer';
import Connections from './Connections';
import Tabs from './Tabs';
import Meetings from './Meetings';
import InspirationPage from './Inspiration';
import axios from 'axios';
import EarnPoints from './EarnPoints';

export const UserProfileUpdate = props => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const topics = useAppSelector(state => state.topic.entities);
  const userProfileEntity = useAppSelector(state => state.userProfile.entity[0]);
  const loading = useAppSelector(state => state.userProfile.loading);
  const [loadingAI, setLoadingAI] = useState(false);
  const updating = useAppSelector(state => state.userProfile.updating);
  const updateSuccess = useAppSelector(state => state.userProfile.updateSuccess);
  const questionEntity = useAppSelector(state => state.question.entity);
  const account = useAppSelector(state => state.authentication.account);
  const [selectedTopics, setSelectedTopics] = useState<ITopic[]>([]);
  const mentorTopicEntity = useAppSelector(state => state.mentorTopic.entity);
  const menteeTopicEntity = useAppSelector(state => state.menteeTopic.entity);
  const [mentorTopics, setMentorTopics] = useState([]);
  const [menteeTopics, setMenteeTopics] = useState([]);
  const [isMentee, setIsMentee] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<ICountry | null>(null);
  const [selectedState, setSelectedState] = useState<IState | null>(null);
  const [selectedYear, setSelectedYear] = useState<number>();
  const [menteeQuestions, setMenteeQuestions] = useState([]);
  const [initialmenteetopics, setInitialmenteetopics] = useState([]);
  const [initialmentortopics, setInitialmentortopics] = useState([]);
  const startYear = 1923; // Change this value as per your requirement
  const currentYear = new Date().getFullYear();
  const [currentDecadeField, setCurrentDecadeField] = useState([]);
  const [loadingIndex, setLoadingIndex] = useState<number>();
  const [updatedUserProfileEntity, setUpdatedUserProfileEntity] = useState({});
  const [loadingMainAI, setLoadingMainAI] = useState(false);
  const [imagePreview, setImagePreview] = useState('');
  const [error, setError] = useState('');
  const [uploadedImage, setUploadedImage] = useState(null);
  const baseImageUrl = '/profile/get/image';
  const [imageName, setImageName] = useState(null);
  const years = Array.from({ length: currentYear - startYear + 1 }, (_, index) => startYear + index);
  const [fields, setFields] = useState([
    { name: '', value: '', required: true },
    { name: '', value: '', required: true },
    { name: '', value: '' },
    { name: '', value: '' },
    { name: '', value: '' },
  ]);
  const id = account.id;
  const isNew = id === undefined;
  const [userId, setUserId] = useState({});
  const handleClose = () => {
    navigate('/user-profile/' + userId);
  };

  const [activeTab, setActiveTab] = useState('profile');
  // const handleFileInputChange = event => {
  //   const selectedFile = event.target.files[0];

  //   if (selectedFile) {
  //     // Check if the file size is greater than 10MB
  //     if (selectedFile.size > 10 * 1024 * 1024) {
  //       setError('File size should be less than 10MB');
  //       return;
  //     }

  //     const formData = new FormData();
  //     formData.append('image', selectedFile);
  //     // const finaldata={
  //     //   ...formData,
  //     //   type: 'profile'
  //     // };

  //     axios
  //       .post('/api/upload?type=profile', formData)
  //       .then(response => {
  //         const fullImageUrl = `${baseImageUrl}/${response.data}`;
  //         setImagePreview(fullImageUrl);
  //         setImageName(response.data);
  //         setUploadedImage(response.data);
  //         setError(''); // Clear any previous error
  //       })
  //       .catch(error => {
  //         console.error('Error uploading image:', error);
  //         setError('Error uploading image. Please try again.'); // Set an appropriate error message
  //       });
  //   }
  // };
  const handleFileInputChange = event => {
    event.preventDefault();
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      // Check if the file size is greater than 10MB
      if (selectedFile.size > 10 * 1024 * 1024) {
        setError('File size should be less than 10MB');
        return;
      }

      // Dispatch the uploadImage action
      dispatch(uploadImage(selectedFile))
        .unwrap()
        .then(response => {
          const fullImageUrl = `${baseImageUrl}/${response.imageName}`;
          //debugger;
          //console.log('response',response)
          setImagePreview(fullImageUrl);
          setImageName(response.imageName);
          setUploadedImage(response.imageName);
          // console.log(fullImageUrl, 'fullImageUrl');
          setError('');
        })
        .catch(error => {
          setError('Error uploading image. Please try again.');
        });
    }
  };

  const handleRemoveImage = () => {
    setImagePreview('');
    setImageName('dummy-user.png');
  };

  useEffect(() => {
    dispatch(getEntityProfileDetails(id));
    const countries = Country.getAllCountries();
  }, []);

  useEffect(() => {}, [selectedCountry]);

  const handleCountryChange = (countryName: string) => {
    const newCountry = Country.getAllCountries().find(country => country.name === countryName);
    if (newCountry) {
      setSelectedCountry(newCountry);
      setSelectedState(null);
    }
  };
  const handleYearChange = async value => {
    await setCurrentDecadeField([]);
    //console.log(currentDecadeField, 'intial');
    const selectedYearcurrent = parseInt(value); // Convert value to a number
    value != '' ? setSelectedYear(selectedYearcurrent) : setSelectedYear(undefined);
    const currentYearCurrentDecadeField = new Date().getFullYear();
    const age = currentYear - selectedYearcurrent; // Calculate age correctly

    const fieldGroups = [];
    for (let decade = 20; decade <= age; decade += 10) {
      const fieldGroup = {
        decade,
        name: `selfDescription${decade}`,
        label: `List ${decade}'s Decade Experience`,
        type: 'textarea',
        enhanceWithAI: true,
        value: '',
      };
      fieldGroups.push(fieldGroup);
    }

    setCurrentDecadeField(fieldGroups);
  };
  const handleStateChange = (state: IState) => {
    setSelectedState(state);
  };
  const enhanceWithAIHandler = async (index, retries = 3) => {
    const updatedFields = [...currentDecadeField];
    updatedFields[index] = {
      ...updatedFields[index], // Maintain other properties
      loadingAI: true, // Update the value property
    };
    setCurrentDecadeField(updatedFields);
    try {
      const selectedField = currentDecadeField[index];
      const message = encodeURIComponent(selectedField.value || '');

      const response = await axios.get(`/api/generate/sentence`, {
        params: {
          message: message,
        },
      });

      const generatedSentence = response.data;

      // Update the state immutably
      const updatedFieldsAfterAPI = [...updatedFields];
      updatedFieldsAfterAPI[index] = {
        ...updatedFieldsAfterAPI[index],
        value: generatedSentence,
        loadingAI: false,
      };

      setCurrentDecadeField(updatedFieldsAfterAPI); // Update the state
    } catch (error) {
      console.error('An error occurred while processing the request:', error);
      // Retry logic
      if (retries > 0 && error.response?.status === 406) {
        console.log(`Retrying in 20 seconds... (${retries} retries left)`);
        setTimeout(() => {
          enhanceWithAIHandler(index, retries - 1);
        }, 20000); // Retry after 20 seconds
      } else {
        console.error('Max retries reached or other error occurred.');
      }
    }
  };
  const enhanceQuestionWithAI = async (index, retries = 3) => {
    setLoadingMainAI(true);
    try {
      const selectedQuestion = fields[index];
      const message = encodeURIComponent(selectedQuestion.value || '');

      const response = await axios.get(`/api/generate/question`, {
        params: {
          message: message,
        },
      });
      const generatedSentence = response.data;

      const updatedFields = [...fields];
      updatedFields[index].value = generatedSentence;
      setFields(updatedFields);
      setLoadingMainAI(false);
    } catch (error) {
      console.error('An error occurred while processing the request:', error);
      // Retry logic
      if (retries > 0 && error.response?.status === 406) {
        console.log(`Retrying in 20 seconds... (${retries} retries left)`);
        setTimeout(() => {
          enhanceQuestionWithAI(index, retries - 1);
        }, 20000); // Retry after 20 seconds
      } else {
        console.error('Max retries reached or other error occurred.');
      }
    }
  };
  const handleInputChange = (index, event) => {
    const newValue = event.target.value;
    const updatedFields = [...currentDecadeField];
    updatedFields[index].value = newValue;

    setCurrentDecadeField(updatedFields);
  };
  const handleQuesionsInputChange = (index, event) => {
    const newValue = event.target.value;
    const updatedFields = [...menteeQuestions];
    updatedFields[index].value = newValue;
    updatedFields[index].name = `question_${index + 1}`;

    setFields(updatedFields);
    setMenteeQuestions(updatedFields);
  };
  useEffect(() => {
    let fieldsArray = [];
    Array.isArray(questionEntity) &&
      questionEntity.map((question, index) => {
        fieldsArray.push({ name: 'question_' + index, value: question.question, questionid: question.id });
        // setMenteeQuestions(questionEntity)
      });
    setMenteeQuestions(fieldsArray);
  }, [questionEntity]);
  useEffect(() => {
    setUserId(userProfileEntity?.user?.id);
    const selectedUserType = userProfileEntity?.isMentor;
    if (selectedUserType != null || selectedUserType != undefined) {
      setIsMentee(selectedUserType == true ? false : true);
    }
    let currentCountry = userProfileEntity?.country;
    const selectCountry = Country.getAllCountries().find(country => country.name === currentCountry);
    if (selectCountry) {
      setSelectedCountry(selectCountry);
    }
    let currentState = userProfileEntity?.state;
    // console.log("currentCountry",currentCountry);
    if (currentState) {
      setSelectedState(currentState);
    }
    const birthYear = userProfileEntity?.year;
    const currentYear = new Date().getFullYear();
    const age = currentYear - birthYear;

    // Generate decade-related fields
    const extractedFields = [];
    for (let decade = 20; decade <= age; decade += 10) {
      const fieldName = `selfDescription${decade}`;
      const fieldValue = userProfileEntity?.[fieldName]; // Set initial value from user profile
      const field = {
        name: fieldName,
        label: `List ${decade}'s Decade Experience`,
        type: 'textarea',
        enhanceWithAI: true,
        value: fieldValue,
      };
      extractedFields.push(field);
    }
    setCurrentDecadeField(extractedFields);
  }, [userProfileEntity]);

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntityProfileDetails(id));
      dispatch(getEntityMenteeQuestions(id));
    }
    dispatch(getUsers({}));
    dispatch(getTopics({ page: 0, size: 100, sort: 'id,asc' }));
  }, []);

  useEffect(() => {
    dispatch(getEntityMenteeTopic(id));
    dispatch(getEntityMentorTopic(id));
  }, []);

  useEffect(() => {
    Array.isArray(mentorTopicEntity) ? setMentorTopics(mentorTopicEntity) : '';
    Array.isArray(menteeTopicEntity) ? setMenteeTopics(menteeTopicEntity) : '';
    Array.isArray(menteeTopicEntity) ? setInitialmenteetopics(menteeTopicEntity) : '';
    Array.isArray(mentorTopicEntity) ? setInitialmentortopics(mentorTopicEntity) : '';
  }, [mentorTopicEntity, menteeTopicEntity]);
  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const updatedUserData = { ...userProfileEntity }; // Create a shallow copy of the userData object

    for (const key in updatedUserData) {
      if (key.startsWith('selfDescription')) {
        updatedUserData[key] = '';
      }
    }
    setUpdatedUserProfileEntity(updatedUserData);

    for (const key in values) {
      if (key.startsWith('selfDescription')) {
        values[key] = '';
      }
    }

    if (isMentee) {
      menteeTopics.map(async (topic, index) => {
        const topics = {
          id: topic.id,
          user: { id: account.id },
          topic: { id: topic.topic.id, name: topic.topic.name },
        };
        {
          topic.id ? await dispatch(updateEntityMenteeTopics(topics)) : await dispatch(createEntityMenteeTopic(topics));
        }
      });
      const filteredData = menteeTopics.filter(item => item.hasOwnProperty('id'));
      const uncheckedMenteeTopics = initialmenteetopics.filter(value => !filteredData?.includes(value));
      uncheckedMenteeTopics.map(async (menteetopic, index) => {
        const menteetData = {
          id: menteetopic.id,
        };
        dispatch(deleteEntityMenteeTopics(menteetData.id));
      });
      //deleteEntityMenteeTopic
    } else {
      mentorTopics.map(async (topic, index) => {
        const topics = {
          id: topic.id,
          user: { id: account.id },
          topic: { id: topic.topic.id, name: topic.topic.name },
        };
        topic.id ? await dispatch(updateEntityMentorTopics(topics)) : await dispatch(createEntityMentorTopic(topics));
      });
      const filteredDataMentor = mentorTopics.filter(item => item.hasOwnProperty('id'));
      const uncheckedMentorTopics = initialmentortopics.filter(value => !filteredDataMentor?.includes(value));
      uncheckedMentorTopics.map(async (mentortopics, index) => {
        const mentorTopic = {
          id: mentortopics.id,
        };
        dispatch(deleteEntityMentorTopic(mentorTopic.id));
      });
    }

    const entity = {
      ...updatedUserProfileEntity,
      ...values,
      imageName: imageName != '' ? imageName || userProfileEntity?.user?.imageUrl : null,
      user: { id: account.id },
      isMentor: isMentee ? false : true,
      ...currentDecadeField.reduce((acc, { name, value }) => {
        acc[name] = value;
        return acc;
      }, {}),
    };
    menteeQuestions.map(data => {
      const id = data.questionid;
      const question = data.value;
      const questionEntity = {
        id: Number(id),
        question: question,
        user: { id: account.id, login: account.email },
        connections: null,
      };

      dispatch(updateEntityMenteequestion(questionEntity));
    });
    dispatch(updateEntity(entity));
  };
  const defaultValues = () => {
    const profileData = {
      ...userProfileEntity,
      user: userProfileEntity?.user?.id,
      // image: userProfileEntity?.user?.imageUrl,
      image: userProfileEntity?.user.imageUrl ? `${baseImageUrl}/${userProfileEntity?.user.imageUrl}` : `${baseImageUrl}/dummy-user.png`,
      lastName: userProfileEntity?.user?.lastName,
      firstName: userProfileEntity?.user?.firstName,
      email: userProfileEntity?.user?.email,
      //topics: menteeTopics?.map(e => e.id.toString()),
      year: selectedYear != null ? selectedYear : userProfileEntity?.year,
      country: selectedCountry != null ? selectedCountry.name : userProfileEntity?.country,
      state: selectedState != null ? selectedState.isoCode : userProfileEntity?.state,
      ...currentDecadeField.reduce((acc, { name }) => {
        // Set the initial value based on the user's profile
        acc[name] = userProfileEntity?.[name] || ''; // Assuming user profile fields have the same names as decade fields
        //console.log('acc=>', acc);
        return acc;
      }, {}),
      // ...menteeQuestions,
      // questions:questionEntity?.map ()  ,
      // country: countriesData
    };
    console.log('profileData', profileData);
    return profileData;
  };
  return (
    <>
      <div className="container fiveplus-small-screen guest-viewprofile profile-page">
        <Tabs
          tab1Content={<Connections />}
          tab2Content={
            <Col md="12" className="card user-information-form users-card user-edit-profile">
              {loading ? (
                <p>Loading...</p>
              ) : (
                <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity} className="row">
                  <div className={`banner-img ${isMentee ? 'mentee-profile' : 'mentor-profile'}`}>
                    <div className="ribbon-corner">{isMentee ? 'Mentee' : 'Mentor'}</div>
                  </div>
                  <div className="ms-5 mb-3 user-avtar">
                    {/* {userProfileEntity && userProfileEntity.user && userProfileEntity.user.imageUrl && (
                      <img className="rounded-circle" src={userProfileEntity.user.imageUrl} />
                    )} */}
                    {imageName ? (
                      <>
                        <CloseButton onClick={handleRemoveImage} />
                        <img className="img-thumbnail p-0" src={`${baseImageUrl}/${imageName}`} />
                      </>
                    ) : (
                      userProfileEntity &&
                      userProfileEntity?.user && (
                        <img
                          src={
                            userProfileEntity?.imageName
                              ? `${baseImageUrl}/${userProfileEntity?.imageName}`
                              : `${baseImageUrl}/${userProfileEntity?.user.imageUrl}` || `${baseImageUrl}/dummy-user.png`
                          }
                          className="rounded-circle"
                        />
                      )
                    )}

                    <ValidatedField
                      id="images-file"
                      name="imageName"
                      data-cy="imageName"
                      type="file"
                      className="col-12 col-md-6 col-lg-4 position-relative input-file"
                      onChange={handleFileInputChange}
                    />
                  </div>
                  <div className="ms-5 mb-3">
                    <label htmlFor="images-file" className="custom-file-upload">
                      Update New Image
                    </label>
                  </div>
                  <ValidatedField
                    label={'First Name'}
                    id="user-profile-firstName"
                    name="firstName"
                    data-cy="firstName"
                    type="text"
                    className="col-4"
                    disabled
                  />
                  <ValidatedField
                    label={'Last Name'}
                    id="user-profile-lastName"
                    name="lastName"
                    data-cy="lastName"
                    type="text"
                    className="col-4"
                    disabled
                  />
                  <ValidatedField
                    label={'Email'}
                    id="user-profile-email"
                    name="email"
                    data-cy="email"
                    type="email"
                    className="col-4"
                    disabled
                  />
                  <ValidatedField
                    label={translate('fivePlusApp.userProfile.phoneNumber')}
                    id="user-profile-phoneNumber"
                    name="phoneNumber"
                    data-cy="phoneNumber"
                    type="text"
                    className="col-4"
                    validate={{ pattern: /^(\([0-9]{3}\)|[0-9]{3})-?[0-9]{3}-?[0-9]{4}$/ }}
                    errorMessage={{
                      validate: 'Please enter a valid phone number',
                    }}
                  />
                  <ValidatedField
                    label={'Year Of Birth'}
                    id="user-profile-year"
                    name="year"
                    data-cy="year"
                    type="select"
                    value={selectedYear}
                    onChange={event => handleYearChange(event.target.value)}
                    className="col-4"
                  >
                    {years.map(year => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </ValidatedField>
                  <ValidatedField
                    label={'Area of Expertise'}
                    id="user-profile-occupation"
                    name="occupation"
                    data-cy="occupation"
                    type="text"
                    className="col-4"
                  />
                  <Col md="12" className="mb-2">
                    <div className="align-items-center d-flex form-title pb-3">
                      <h3 className="pt-3">Address</h3> <hr className="w-100 m-0 ms-3" />
                    </div>
                  </Col>
                  <ValidatedField
                    label={translate('fivePlusApp.userProfile.country')}
                    id="user-profile-country"
                    name="country"
                    data-cy="country"
                    type="select"
                    className="col-4"
                    value={selectedCountry?.name}
                    //  value={selectedCountry?.isoCode}
                    onChange={e => {
                      const selectedCountryName = e.target.value;
                      handleCountryChange(selectedCountryName);
                    }}
                  >
                    {Country.getAllCountries().map(country => (
                      <option key={country.name} value={country.name}>
                        {country.name}
                      </option>
                    ))}
                  </ValidatedField>
                  <ValidatedField
                    label={translate('fivePlusApp.userProfile.state')}
                    id="user-profile-state"
                    name="state"
                    data-cy="state"
                    type="select"
                    className="col-4"
                    value={selectedState}
                    onChange={e => {
                      const selectedStateIsoCode = e.target.value; // Use the ISO code of the selected state
                      handleStateChange(State.getStateByCodeAndCountry(selectedStateIsoCode, selectedCountry?.isoCode || ''));
                    }}
                  >
                    {selectedCountry &&
                      State.getStatesOfCountry(selectedCountry.isoCode).map(state => {
                        return (
                          <option key={state.isoCode} value={state.isoCode}>
                            {state.name}
                          </option>
                        );
                      })}
                  </ValidatedField>
                  <ValidatedField
                    label={translate('fivePlusApp.userProfile.zipCode')}
                    id="user-profile-zipCode"
                    name="zipCode"
                    data-cy="zipCode"
                    type="text"
                    className="col-4"
                    validate={{ pattern: /^(?:[A-Z]{1,2}\d[A-Z\d]? \d[A-Z]{2}|[0-9A-Za-z\s\-]+)$/i }}
                    errorMessage={{
                      pattern: 'Please enter a valid ZIP code',
                    }}
                  />
                  <Col md="12" className="mb-2">
                    <div className="align-items-center d-flex form-title pb-3">
                      <h3 className="pt-3">Details </h3>
                      <hr className="w-100 m-0 ms-3 align-items-end" />
                    </div>
                  </Col>
                  <ValidatedField
                    label={'Instagram Id'}
                    id="user-profile-instagramUrl"
                    name="instagramUrl"
                    data-cy="instagramUrl"
                    type="text"
                    className="col-4"
                  />
                  <Col md="12" className="mb-2">
                    <div className="align-items-center d-flex form-title pb-3">
                      <h3 className="pt-3">Self Description</h3> <hr className="w-100 m-0 ms-3" />
                    </div>
                  </Col>
                  {/* {currentDecadeField.length > 0 &&
                    currentDecadeField.map(field => (
                      <ValidatedField
                        key={field.name}
                        label={field.label}
                        id={`user-profile-${field.name}`}
                        name={field.name}
                        data-cy={field.name}
                        type={field.type}
                        className="col-4"
                      />
                    ))} */}
                  {currentDecadeField.length > 0 &&
                    currentDecadeField.map((field, index) => (
                      <React.Fragment key={field.name}>
                        <ValidatedField
                          label={field.label}
                          id={`user-profile-${field.name}`}
                          name={field.name}
                          data-cy={field.name}
                          onChange={e => handleInputChange(index, e)}
                          type={field.type}
                          className="col-4"
                          value={field.value}
                        />
                        {field.loadingAI ? <Spinner className="loading-btn">Loading...</Spinner> : ''}
                        {field.enhanceWithAI && (
                          <Button className="enhance-ai-btn" onClick={() => enhanceWithAIHandler(index)}>
                            Enhance with AI
                          </Button>
                        )}
                      </React.Fragment>
                    ))}
                  <ValidatedField
                    label={'Community Engagement & 5 Causes to Support/Non Profits'}
                    id="user-profile-helpCommunityDescription"
                    name="helpCommunityDescription"
                    data-cy="helpCommunityDescription"
                    type="textarea"
                    className="col-4"
                  />
                  <Col md="12" className="mb-2">
                    <div className="align-items-center d-flex form-title pb-3">
                      <h3 className="pt-3">Topics</h3> <hr className="w-100 m-0 ms-3" />
                    </div>
                  </Col>
                  <div id="topic-ques">
                    <ul className="d-flex">
                      {isMentee == false &&
                        topics.map((topic, index) => (
                          <li>
                            <div className="topic-checkbox" key={topic.id}>
                              <input
                                key={index}
                                type="checkbox"
                                id={`topic-${topic.id}` + ' btn-check-2'}
                                name={`topic-${topic.id}`}
                                value={topic.name}
                                checked={mentorTopics.some(t => t.topic.id == topic.id)}
                                onChange={e => {
                                  if (e.target.checked) {
                                    // const newtopics = [...menteeTopics];
                                    // newtopics[index].value = e.target.value;
                                    setMentorTopics([...mentorTopics, { topic: { id: topic.id, name: topic.name } }]);
                                  } else {
                                    setMentorTopics(mentorTopics.filter(t => t.topic.id !== topic.id));
                                  }
                                }}
                              />
                              <label htmlFor={`topic-${topic.id}`}>{topic.name}</label>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>
                  <div id="topic-ques">
                    <ul className="d-flex">
                      {isMentee &&
                        topics.map((topic, index) => (
                          <li>
                            <div className="topic-checkbox" key={topic.id}>
                              <input
                                key={index}
                                type="checkbox"
                                id={`topic-${topic.id}`}
                                name={`topic-${topic.id}`}
                                value={topic.name}
                                checked={menteeTopics.some(t => t.topic.id == topic.id)}
                                onChange={e => {
                                  if (e.target.checked) {
                                    // const newtopics = [...menteeTopics];
                                    // newtopics[index].value = e.target.value;
                                    setMenteeTopics([...menteeTopics, { topic: { id: topic.id, name: topic.name } }]);
                                  } else {
                                    setMenteeTopics(menteeTopics.filter(t => t.topic.id !== topic.id));
                                  }
                                }}
                              />
                              <label htmlFor={`topic-${topic.id}`}>{topic.name}</label>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>
                  {isMentee &&
                    menteeQuestions.length != 0 &&
                    menteeQuestions.map((field, index) => (
                      <React.Fragment>
                        <ValidatedField
                          key={index}
                          type="text"
                          // name={field.questionid.toString()}
                          name={`question_${field.questionid}`}
                          label={`${index + 1} Question For Mentor`}
                          value={field.value}
                          onChange={e => handleQuesionsInputChange(index, e)}
                          validate={{ required: field.required }}
                          autoFocus={index === fields.length - 1}
                        />
                        {loadingMainAI && loadingIndex === index ? (
                          <Spinner className="loading-btn mentee-loading-btn">Loading...</Spinner>
                        ) : (
                          ''
                        )}
                        <Button
                          className="enhance-ai-btn mentee-enhance-ai-btn"
                          onClick={() => {
                            setLoadingIndex(index); // Set loading index before calling enhanceQuestionWithAI
                            enhanceQuestionWithAI(index);
                          }}
                        >
                          Enhance with AI
                        </Button>
                      </React.Fragment>
                    ))}
                  <div className="save-back-btn">
                    <Button
                      tag={Link}
                      id="cancel-save"
                      data-cy="entityCreateCancelButton"
                      to={`/user-profile/${userProfileEntity?.user.id}`}
                      replace
                      color="info"
                    >
                      <FontAwesomeIcon icon="arrow-left" />
                      &nbsp;
                      <span className="d-none d-md-inline">
                        <Translate contentKey="entity.action.back">Back</Translate>
                      </span>
                    </Button>
                    &nbsp;
                    <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                      <FontAwesomeIcon icon="save" />
                      &nbsp;
                      <Translate contentKey="entity.action.save">Save</Translate>
                    </Button>
                  </div>
                </ValidatedForm>
              )}
            </Col>
          }
          tab3Content={<Meetings />}
          tab4Content={<InspirationPage />}
          tab5Content={<EarnPoints />}
          currentActiveTab={activeTab}
        />
      </div>
      {loadingAI ? (
        <div className="primary">
          <Spinner>Loading...</Spinner>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default UserProfileUpdate;
