import axios, { AxiosResponse } from 'axios';
import { Storage } from 'react-jhipster';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { serializeAxiosError } from './reducer.utils';

import { AppThunk } from 'app/config/store';
import { setLocale } from 'app/shared/reducers/locale';

export const initialState = {
  loading: false,
  isAuthenticated: false,
  isLoggedIn: undefined,
  account: {} as any,
  errorMessage: null as unknown as string, // Errors returned from server side
  redirectMessage: null as unknown as string,
  sessionHasBeenFetched: false,
  logoutUrl: null as unknown as string,
};
export type AuthenticationState = Readonly<typeof initialState>;

// Actions

export const getSession = (): AppThunk => async (dispatch, getState) => {
  await dispatch(getAccount());
  const { account } = getState().authentication;
  if (account && account.langKey) {
    const langKey = Storage.session.get('locale', account.langKey);
    await dispatch(setLocale(langKey));
  }
};
// export const getAccount = createAsyncThunk('authentication/get_account', async () => {
//   try {
//     const response = await axios.get<any>('api/account');
//     return response.data;
//   } catch (error) {
//     if (error.response && error.response.data) {
//       console.error('getAccount error:', error.response.data); // Log detailed error information
//       throw error.response.data;
//     } else {
//       console.error('getAccount error:', error); // Log generic error information
//       throw error;
//     }
//   }
// }, {
//   serializeError: serializeAxiosError,
// });
export const getAccount = createAsyncThunk('authentication/get_account', async () => axios.get<any>('api/account'), {
  serializeError: serializeAxiosError,
});

export const logoutServer = createAsyncThunk(
  'authentication/logout',
  async () => {
    const idToken = localStorage.getItem('id_token');
    return axios.post<any>(`api/logout?idToken=${idToken}`, {});
  },
  {
    serializeError: serializeAxiosError,
  }
);
// export const logoutServer = createAsyncThunk(
//   'authentication/logout',
//   async () => {
//     const refreshToken = localStorage.getItem('refresh_token');
//     return axios.get<any>(`auth/logout?refreshToken=${refreshToken}`);
//   },
//   {
//     serializeError: serializeAxiosError,
//   }
// );
// export const logoutServer = createAsyncThunk('authentication/logout', async () => axios.post<any>('api/logout/', {}), {
//   serializeError: serializeAxiosError,
// });

export const logout: () => AppThunk = () => async dispatch => {
  dispatch(logoutServer());
  localStorage.removeItem('access_token');
  localStorage.removeItem('access_token_expiry');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('id_token');
  localStorage.removeItem('expiryFrom');
};

export const clearAuthentication = messageKey => dispatch => {
  dispatch(authError(messageKey));
  dispatch(clearAuth());
};

export const loginSucessHandler = () => dispatch => {
  dispatch(loginSuccess());
};

export const AuthenticationSlice = createSlice({
  name: 'authentication',
  initialState: initialState as AuthenticationState,
  reducers: {
    authError(state, action) {
      return {
        ...state,
        redirectMessage: action.payload,
      };
    },
    clearAuth(state) {
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
      };
    },
    loginSuccess(state) {
      return {
        ...state,

        isLoggedIn: true,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAccount.rejected, (state, action) => ({
        ...state,
        loading: false,
        isAuthenticated: false,
        sessionHasBeenFetched: true,
        errorMessage: action.error.message || 'Failed to fetch account details',
      }))
      .addCase(getAccount.fulfilled, (state, action) => {
        const isAuthenticated = action.payload && action.payload.data && action.payload.data.activated;
        return {
          ...state,
          isAuthenticated,
          loading: false,
          sessionHasBeenFetched: true,
          account: action.payload.data,
        };
      })
      .addCase(logoutServer.fulfilled, (state, action) => ({
        ...initialState,
        logoutUrl: action.payload.data.logoutUrl,
      }))
      .addCase(getAccount.pending, state => {
        state.loading = true;
      });
  },
});

export const { authError, clearAuth, loginSuccess } = AuthenticationSlice.actions;

// Reducer
export default AuthenticationSlice.reducer;
