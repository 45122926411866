import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody } from 'reactstrap';
//import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import ConnectionMeetingForm from './ConnectionMeetingForm';
import { getEntityFilterConnections } from 'app/entities/connection/connection.reducer';
import { useAppDispatch } from 'app/config/store';
const ConnectionMeetingDropdown = props => {
  const [isOpen, setIsOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const dispatch = useAppDispatch();
  const toggleModal = () => {
    setModal(!modal);
    dispatch(getEntityFilterConnections(['connection', props.selectedFilterType]));
  };
  const handleLetsMeetemClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setModal(true);
    //dispatch(getEntityNotifications(id));
  };
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div>
      {
        <Dropdown isOpen={isOpen} toggle={toggleDropdown}>
          <DropdownToggle tag="div" className="dropdown-toggle">
            <FontAwesomeIcon icon={faEllipsisV} />
          </DropdownToggle>
          <DropdownMenu>
            <div onClick={handleLetsMeetemClick}>Lets Meet</div>
          </DropdownMenu>
        </Dropdown>
      }
      <Modal className="meeting-box" isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Meeting Form</ModalHeader>
        <ModalBody>
          <ConnectionMeetingForm />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ConnectionMeetingDropdown;
