import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntityNotifications } from 'app/entities/notification/notification.reducer';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Card, CardBody, CardImg, CardSubtitle, CardText, CardTitle, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import axios from 'axios';
import { INotification } from 'app/shared/model/notification.model';
const ConnectionNotification = () => {
  const notificationEntity = useAppSelector(state => state.notification.entity);
  const account = useAppSelector(state => state.authentication.account);
  const dispatch = useAppDispatch();
  const id = account.id;
  const [notificationData, SetNotificationData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const baseImageUrl = '/profile/get/image';
  useEffect(() => {
    dispatch(getEntityNotifications(id));
  }, []);
  useEffect(() => {
    if (Array.isArray(notificationEntity)) {
      const fileteredNotifications = notificationEntity.filter(item => item.userReceiver.id === id);
      SetNotificationData(fileteredNotifications);
    }
  }, [notificationEntity]);

  function formatDate(dateString) {
    const options: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };

    const date = new Date(dateString);
    const today = new Date();

    if (date.toDateString() === today.toDateString()) {
      return 'Today ' + date.toLocaleString('en-US', options);
    }

    return date.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  }
  return (
    <Row>
      <Col md="12">
        <div className="notification-heading d-flex">
          <p>Notifications</p>
          {/* {!notification.isRead && <button onClick={markAsRead}>Mark all as read</button>} */}
        </div>
        <div className="card notification-info connection-tab users-card">
          {notificationData.length != 0 &&
            notificationData?.map((connection, index) => (
              <Card className="p-0 mb-2 connection-card " key={index}>
                <CardBody className="d-flex justify-content-between">
                  <div className="d-flex flex-row notification_box">
                    <div className="connection-avtar">
                      {/* <CardImg className="rounded-circle" alt="Card image cap" src={connection.userSender.imageUrl} /> */}
                      {connection.userSender && connection.userSender.imageUrl && (
                        <CardImg className="rounded-circle" src={`${baseImageUrl}/${connection.userSender.imageUrl}`} />
                      )}
                    </div>
                    <div className="notification-title">
                      {connection.userSender && connection.userSender.imageUrl && (
                        <CardTitle tag="h5">{connection.userSender.firstName + ' ' + connection.userSender.lastName}</CardTitle>
                      )}
                      <CardSubtitle className="mb-2 text-muted" tag="h6"></CardSubtitle>
                      <CardText>{connection.message}</CardText>
                      <div className="d-flex flex-row align-items-center">
                        {/* {checkMeetingNotification(connection, 'type', 'meeting') &&
                          !checkMeetingNotification(connection, 'message', 'Meeting link generated') &&
                          !checkMeetingNotification(connection, 'message', 'has accepted your meeting request') &&(
                            
                          )} */}
                        {/* <Button className="connect-later-btn">Connect Later</Button> */}
                      </div>
                      {/* <div className="d-flex flex-row align-items-center">
                        <Button className="accept-btn">Accept</Button>
                        <Button className="connect-later-btn">Connect Later</Button>
                      </div> */}
                    </div>
                    <div className="notification-date">
                      <div>{formatDate(connection.createdDate)}</div>
                      <div className="d-flex user-view-details">
                        <div className="view-detail-btn">
                          {connection.userSender && connection.userSender.id && (
                            <Link to={`/user-profile/${connection.userSender.id}`}>View Detail</Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            ))}
        </div>
      </Col>
    </Row>
  );
};

export default ConnectionNotification;
