import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IUserProfile, defaultValue } from 'app/shared/model/user-profile.model';

const initialState: EntityState<IUserProfile> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/user-profiles';
const userUpdateApiUrl = '/api/updateMentor';
const userMentorMenteeCount = '/api/totalMenteesAndMentors';
const topFiveusers = '/api/admin/top/five/users';
const userProfileImage = '/api/upload?type=profile';
//const baseImageUrl = 'your_base_image_url'; // Replace with your actual base image URL

interface UploadImageResponse {
  imageName: string;
}

// Actions

export const getEntities = createAsyncThunk('userProfile/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}`;
  return axios.get<IUserProfile[]>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'userProfile/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IUserProfile>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);
export const getEntityAdminTopFive = createAsyncThunk(
  'userProfile/fetch_entity_top_five_users',
  async () => {
    const requestUrl = `${topFiveusers}`;
    return axios.get<IUserProfile>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);
export const getUserMentorMenteeCount = createAsyncThunk(
  'userProfile/fetch_entity_totalpoints',
  async () => {
    const requestUrl = `${userMentorMenteeCount}`;
    const data = await axios.get<IUserProfile>(requestUrl);
    const data2 = {
      ...data,
    };

    //console.log('data2', data2);
    return data2;
  },
  { serializeError: serializeAxiosError }
);
export const getEntityProfileDetails = createAsyncThunk(
  'userProfile/fetch_entity_profile_details',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/?userId.equals=${id}`;

    return axios.get<IUserProfile>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const updateEntityUserType = createAsyncThunk<IUserProfile, [string | number, boolean], {}>(
  'connection/update_entity_user_type',
  async ([id, userType], thunkAPI) => {
    const requestUrl = `${userUpdateApiUrl}?userId=${id}&isMentor=${userType}`;
    const response = await axios.put<IUserProfile>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return response.data;
  },
  { serializeError: serializeAxiosError }
);
export const uploadImage = createAsyncThunk<UploadImageResponse, File, { rejectValue: string }>(
  'userProfile/upload_image',
  async (selectedFile, thunkAPI) => {
    if (selectedFile.size > 10 * 1024 * 1024) {
      return thunkAPI.rejectWithValue('File size should be less than 10MB');
    }

    const formData = new FormData();
    formData.append('image', selectedFile);

    try {
      const response = await axios.post(userProfileImage, formData);

      return { imageName: response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue('Error uploading image. Please try again.');
    }
  },
  { serializeError: serializeAxiosError }
);
export const createEntity = createAsyncThunk(
  'userProfile/create_entity',
  async (entity: IUserProfile, thunkAPI) => {
    const result = await axios.post<IUserProfile>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'userProfile/update_entity',
  async (entity: IUserProfile, thunkAPI) => {
    const result = await axios.put<IUserProfile>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'userProfile/partial_update_entity',
  async (entity: IUserProfile, thunkAPI) => {
    const result = await axios.patch<IUserProfile>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'userProfile/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IUserProfile>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const UserProfileSlice = createEntitySlice({
  name: 'userProfile',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getEntityProfileDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getUserMentorMenteeCount.fulfilled, (state, action) => {
        state.loading = false;
        const prevData = state.entity;
        // const payloadData = action.payload.data;
        const currData = {
          ...prevData,
          ...action.payload.data,
        };
        state.entity = currData;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addCase(uploadImage.fulfilled, (state, action) => {
        state.updating = false;
        //state.updateSuccess = true;
        state.errorMessage = null;
        state.entity = {
          ...state.entity,
          imageName: action.payload.imageName,
        };
      })
      .addCase(uploadImage.rejected, (state, action) => {
        state.updating = false;
        state.updateSuccess = false;
        state.errorMessage = action.payload;
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(updateEntityUserType), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload;
      })

      .addMatcher(isPending(getEntities, getEntity, getEntityProfileDetails, getUserMentorMenteeCount), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, updateEntityUserType, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = UserProfileSlice.actions;

// Reducer
export default UserProfileSlice.reducer;
