import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';

export interface IInspiration {
  id?: number;
  imageName?: string | null;
  topics?: string | null;
  approved?: boolean | null;
  description?: string | null;
  link?: string | null;
  createdDate?: string | null;
  modifiedDate?: string | null;
  user?: IUser | null;
}

export const defaultValue: Readonly<IInspiration> = {
  approved: false,
};
