import { IPointAllocationSystem } from 'app/shared/model/point-allocation-system.model';
import { IUserAchivements } from 'app/shared/model/user-achivements.model';

export interface IAchivements {
  id?: number;
  name?: string | null;
  description?: string | null;
  feature?: boolean | null;
  requiredProof?: boolean | null;
  pointAllocationSystem?: IPointAllocationSystem | null;
  userAchivements?: IUserAchivements[] | null;
}

export const defaultValue: Readonly<IAchivements> = {
  feature: false,
  requiredProof: false,
};
