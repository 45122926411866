import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import SelfDescription from './self-description';
import SelfDescriptionDetail from './self-description-detail';
import SelfDescriptionUpdate from './self-description-update';
import SelfDescriptionDeleteDialog from './self-description-delete-dialog';

const SelfDescriptionRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<SelfDescription />} />
    <Route path="new" element={<SelfDescriptionUpdate />} />
    <Route path=":id">
      <Route index element={<SelfDescriptionDetail />} />
      <Route path="edit" element={<SelfDescriptionUpdate />} />
      <Route path="delete" element={<SelfDescriptionDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default SelfDescriptionRoutes;
