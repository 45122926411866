import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntityConnectionsugessions } from 'app/entities/connectionsugession/connectionsugession.reducer';
import { Button, Card, CardBody, CardImg, CardSubtitle, CardTitle, Col, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import { createEntityConnection, getEntityFilterConnections } from 'app/entities/connection/connection.reducer';

function ConnectionSugession() {
  const connectionsugessionEntity = useAppSelector(state => state.connectionsugession.entity);
  const [isRequestSent, setIsRequestSent] = useState(false);
  const account = useAppSelector(state => state.authentication.account);
  const loading = useAppSelector(state => state.connectionsugession.loading);
  const userProfileEntity = useAppSelector(state => state.userProfile.entity);
  const dispatch = useAppDispatch();
  const id = account.id;
  const baseImageUrl = '/profile/get/image';

  useEffect(() => {
    dispatch(getEntityConnectionsugessions());
  }, []);
  //console.log(connectionsugessionEntity,'connectionsugessionEntity')
  const dataArray = Object.keys(connectionsugessionEntity).map(key => ({
    ...connectionsugessionEntity[key],
    id: key,
  }));
  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };
  const [isMentee, setIsMentee] = useState(false);
  const handleSendRequedstButtonClick = async (id, email) => {
    const isMentee = userProfileEntity[0]?.isMentor ? false : true;
    setIsMentee(isMentee);
    console.log(isMentee, 'isMentee');
    const entity = {
      status: 'requested',
      userMentor: {
        id: id,
        login: email,
      },
      userMentee: {
        id: account.id,
        login: account.email,
      },
      topics: [],
      questions: [],
    };
    if (isMentee) {
      if (!isRequestSent) {
        setIsRequestSent(true);
        await dispatch(createEntityConnection(entity));
        await dispatch(getEntityFilterConnections(['connection', 'all']));
        setIsRequestSent(false);
      }
    } else {
      setModal(true);
    }
  };
  return (
    <div>
      <Row>
        {loading ? (
          <Spinner className="body-loader-icon"></Spinner>
        ) : (
          <>
            <Col md="12">
              {Array.isArray(dataArray) && dataArray.length > 0 && <h2 className="pt-4">People You might want to connect</h2>}
              <div className="card connection-tab users-card connection-sugg-tab">
                {Array.isArray(dataArray) ? (
                  dataArray?.map((item, index) => (
                    <Card className="p-0 mb-2 connection-card " key={index}>
                      <div className={` ${item.isMentor ? 'mentor-profile' : 'mentee-profile'}`}></div>
                      <CardBody className="text-center">
                        <div className="text-center">
                          <div className="me-3 connection-avtar">
                            <CardImg className="rounded-circle" src={`${baseImageUrl}/${item.profilePic}`} />
                            <div className={` ${item.isMentor ? 'search-mentor-profile' : 'search-mentee-profile'}`}>
                              <div className="ribbon-corner">{item.isMentor ? 'Mentor' : 'Mentee'}</div>
                            </div>
                          </div>
                          <div className="profile-name-connection pb-2">
                            <CardTitle tag="h5">
                              <Link to={`/user-profile/${item.userId}`}>{item.name}</Link>
                            </CardTitle>
                            <CardSubtitle className="mb-2 text-muted" tag="h6">
                              {item.occupation}
                            </CardSubtitle>
                          </div>
                        </div>
                        <Button className="pending-request-btn mb-2" onClick={() => handleSendRequedstButtonClick(item.userId, item.email)}>
                          Send Request
                        </Button>
                        <Modal className="send-request-popup" isOpen={modal} toggle={toggleModal}>
                          <ModalHeader toggle={toggleModal}></ModalHeader>
                          <ModalBody>
                            Switch to Mentee profile to send Request
                            <div>
                              <Button className="pending-request-btn">
                                <a href={`/user-profile/${id}`}>View Profile</a>
                              </Button>{' '}
                            </div>
                          </ModalBody>
                        </Modal>
                      </CardBody>
                    </Card>
                  ))
                ) : (
                  <div className="connection-tab-gif">
                    <Row>
                      <div className="col-md-6">
                        <h4>Build Your Professional Network</h4>
                        <p>Chose topics of your intrest</p>
                      </div>
                      <div className="col-md-6">
                        <img src="content/images/connection-tab.gif" />
                      </div>
                    </Row>
                  </div>
                )}
              </div>
            </Col>
          </>
        )}
      </Row>
    </div>
  );
}

export default ConnectionSugession;
