import userProfile from 'app/entities/user-profile/user-profile.reducer';
import workExperience from 'app/entities/work-experience/work-experience.reducer';
import topic from 'app/entities/topic/topic.reducer';
import question from 'app/entities/question/question.reducer';
import connection from 'app/entities/connection/connection.reducer';
import connectionmeeting from 'app/entities/connectionmeeting/connectionmeeting.reducer';
import mentorTopic from 'app/entities/mentor-topic/mentor-topic.reducer';
import menteeTopic from 'app/entities/mentee-topic/mentee-topic.reducer';
import notification from 'app/entities/notification/notification.reducer';
import meeting from 'app/entities/meeting/meeting.reducer';
import guest from 'app/entities/guest/guest.reducer';
import referralCode from 'app/entities/referral-code/referral-code.reducer';
import pointAllocation from 'app/entities/point-allocation/point-allocation.reducer';
import selfDescription from 'app/entities/self-description/self-description.reducer';
import search from 'app/entities/search/search.reducer';
// import meetinginfo from 'app/entities/meetinginfo/meetinginfo.reducer';
import meetinginfo from './meetinginfo/meetinginfo.reducer';
import adminProfile from './adminprofile/adminprofile.reducer';

import recordedMeeting from 'app/entities/recorded-meeting/recorded-meeting.reducer';
import inspiration from 'app/entities/inspiration/inspiration.reducer';
import image from 'app/entities/image/image.reducer';
import newsFeed from 'app/entities/news-feed/news-feed.reducer';
import userLoginDetails from 'app/entities/user-login-details/user-login-details.reducer';
import connectionsugession from 'app/entities/connectionsugession/connectionsugession.reducer';
import achivements from 'app/entities/achivements/achivements.reducer';
import points from 'app/entities/points/points.reducer';
import userAchivements from 'app/entities/user-achivements/user-achivements.reducer';
import pointAllocationSystem from 'app/entities/point-allocation-system/point-allocation-system.reducer';
import pointDetails from 'app/entities/point-details/point-details.reducer';
import achievements from 'app/entities/achievements/achievements.reducer';
import userAchievements from 'app/entities/user-achievements/user-achievements.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  userProfile,
  workExperience,
  topic,
  question,
  connection,
  mentorTopic,
  menteeTopic,
  notification,
  meeting,
  guest,
  referralCode,
  pointAllocation,
  selfDescription,
  search,
  meetinginfo,
  recordedMeeting,
  connectionmeeting,
  inspiration,
  image,
  newsFeed,
  userLoginDetails,
  adminProfile,
  connectionsugession,
  achivements,
  points,
  userAchivements,
  pointAllocationSystem,
  pointDetails,
  achievements,
  userAchievements,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;
