import React, { useEffect, useState } from 'react';
import MenuItem from 'app/shared/layout/menus/menu-item';
import { Button, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalHeader, UncontrolledDropdown } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Translate, translate } from 'react-jhipster';
import { getLoginUrl } from 'app/shared/util/url-utils';
import { NavDropdown } from './menu-components';
import { getEntity, getEntityProfileDetails } from 'app/entities/user-profile/user-profile.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Link, useParams } from 'react-router-dom';
import ConnectionNotification from 'app/views/user_profile/login/ConnectionNotification';
import { getEntityNotifications, partialUpdateEntityNotification } from 'app/entities/notification/notification.reducer';
import Bell from './bell';
import ReferralEmailForm from 'app/views/user_profile/login/ReferralEmailForm';
import { createEntityReferralCode } from 'app/entities/referral-code/referral-code.reducer';
import { useDispatch } from 'react-redux';
import SearchConnection from 'app/views/user_profile/login/SearchConnection';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import NewsFeedPage from 'app/views/user_profile/login/newsFeed';
export const AccountMenu = ({ isAuthenticated = false }) => {
  const notificationEntity = useAppSelector(state => state.notification.entity);
  const userProfileEntity = useAppSelector(state => state.userProfile.entity);
  const account = useAppSelector(state => state.authentication.account);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account?.authorities, [AUTHORITIES.ADMIN]));
  const dispatch = useAppDispatch();
  const id = account?.id;
  const [notificationData, SetNotificationData] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalReferral, setModalReferral] = useState(false);
  const baseImageUrl = '/profile/get/image';
  const [isAdminPortal, setIsAdminPortal] = useState(false);

  //const [modalSearch, setModalSearch] = useState(false);
  const toggleModal = () => {
    setModalReferral(!modalReferral);
  };
  const toggleModalSearch = () => {
    setModal(!modal);
  };
  const handleMenuItemClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setModalReferral(true);
    dispatch(createEntityReferralCode(id));
    //dispatch(getEntityNotifications(id));
  };
  const handleSearchButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setModal(true);
  };
  useEffect(() => {
    if (account.id !== undefined) {
      dispatch(getEntityNotifications(id));
      dispatch(getEntityProfileDetails(id));
    }
    SetNotificationData(notificationEntity);
    //setIsAdminPortal(window.location.pathname.includes("/admin/"));
  }, []);
  console.log(userProfileEntity, 'userProfileEntity');
  useEffect(() => {
    setIsAdminPortal(window.location.pathname.includes('/admin/'));
  }, [window.location.pathname]);

  useEffect(() => {
    if (Array.isArray(notificationEntity)) {
      const fileteredNotifications = notificationEntity.filter(item => item.userReceiver.id === id);
      SetNotificationData(fileteredNotifications);
    }
  }, [notificationEntity]);

  const handleClick = async () => {
    const unreadNotifications = notificationData.filter(notificationData => !notificationData.isRead);
    unreadNotifications.map(async notification => {
      const entity = {
        id: notification.id,
        isRead: true,
      };
      await dispatch(partialUpdateEntityNotification(entity));
    });
    setTimeout(() => {
      dispatch(getEntityNotifications(id));
    }, 2000);
  };
  const viewProfileText = isAdminPortal ? 'Switch to User Portal' : 'View Profile';

  const accountMenuItems = () => {
    return (
      <>
        <DropdownItem id="login-item" tag="a" href={getLoginUrl()} data-cy="login">
          <FontAwesomeIcon icon="sign-in-alt" /> <Translate contentKey="global.menu.account.login">Sign in</Translate>
        </DropdownItem>
      </>
    );
  };
  const accountMenuItemsAuthenticatedisAdmin = () => (
    <>
      <MenuItem icon="wrench" to="/account/settings" data-cy="settings">
        <Translate contentKey="global.menu.account.settings">NewsFeed</Translate>
      </MenuItem>
      <MenuItem icon="lock" to="/account/password" data-cy="passwordItem">
        <Translate contentKey="global.menu.account.password">Password</Translate>
      </MenuItem>
      <MenuItem icon="sign-out-alt" to="/logout" data-cy="logout">
        <Translate contentKey="global.menu.account.logout">Sign out</Translate>
      </MenuItem>
    </>
  );
  const accountMenuItemsAuthenticated = () => {
    const menuItem = (
      <>
        <div className="header-user-profile">
          <MenuItem icon="user" to={`/user-profile/${account.id}`} data-cy="">
            {viewProfileText}
          </MenuItem>
          <div className="refer-box" onClick={handleMenuItemClick}>
            {' '}
            <img className="refer-icon" src="content/images/refer-icon.png" />
            Invite A Mentee/Mentor
          </div>
          <Modal isOpen={modalReferral} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>Invite A Mentee/Mentor</ModalHeader>
            <ModalBody>
              <ReferralEmailForm />
            </ModalBody>
          </Modal>
          <MenuItem icon="sign-out-alt" to="/logout" data-cy="logout">
            <Translate contentKey="global.menu.account.logout">Sign out</Translate>
          </MenuItem>
          {isAdmin && (
            <div className={isAdminPortal ? 'active' : 'inactive'}>
              <MenuItem icon="user" to="/admin/admindashboard/" data-cy="logout">
                <Link onClick={() => setIsAdminPortal(true)} to="/admin/admindashboard/">
                  Admin Portal
                </Link>
              </MenuItem>
            </div>
          )}
        </div>
      </>
    );

    return menuItem;
  };
  return (
    <>
      {Object.keys(account)?.length === 0 ? (
        ''
      ) : (
        <>
          <UncontrolledDropdown nav inNavbar>
            <div onClick={handleClick}>
              <DropdownToggle nav>
                <Bell notification={notificationData} />
              </DropdownToggle>
              <DropdownMenu className="notification-box">
                <DropdownItem toggle={true}>
                  <ConnectionNotification />
                </DropdownItem>
              </DropdownMenu>
            </div>
          </UncontrolledDropdown>
          <div className="nav-searchbar">
            <button type="button" onClick={handleSearchButtonClick} className="DocSearch DocSearch-Button" aria-label="Search">
              <span className="DocSearch-Button-Container">
                <svg width="16" height="16" className="DocSearch-Search-Icon" viewBox="0 0 20 20">
                  <path
                    d="M14.386 14.386l4.0877 4.0877-4.0877-4.0877c-2.9418 2.9419-7.7115 2.9419-10.6533 0-2.9419-2.9418-2.9419-7.7115 0-10.6533 2.9418-2.9419 7.7115-2.9419 10.6533 0 2.9419 2.9418 2.9419 7.7115 0 10.6533z"
                    stroke="currentColor"
                    fill="none"
                    fillRule="evenodd"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
                <span className="DocSearch-Button-Placeholder">Search</span>
              </span>
            </button>
          </div>
          <Modal className="search-mentor-mentee-popup" isOpen={modal} toggle={toggleModalSearch}>
            <ModalHeader toggle={toggleModalSearch}>Search</ModalHeader>
            <ModalBody>
              <SearchConnection />
            </ModalBody>
          </Modal>
        </>
      )}
      <NavDropdown
        icon="user"
        imgUrl={
          isAuthenticated
            ? userProfileEntity?.imageUrl
              ? `${baseImageUrl}/${userProfileEntity.imageUrl}`
              : `${baseImageUrl}/${account?.imageUrl}`
            : null
        }
        name={Object.keys(account).length === 0 && !isAuthenticated ? 'Login' : `${account.firstName} ${account.lastName}`}
        account="login"
        id="account-menu"
        data-cy="accountMenu"
      >
        {isAuthenticated ? accountMenuItemsAuthenticated() : accountMenuItems()}
      </NavDropdown>
    </>
  );
};

export default AccountMenu;
