import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import PointDetails from './point-details';
import PointDetailsDetail from './point-details-detail';
import PointDetailsUpdate from './point-details-update';
import PointDetailsDeleteDialog from './point-details-delete-dialog';

const PointDetailsRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<PointDetails />} />
    <Route path="new" element={<PointDetailsUpdate />} />
    <Route path=":id">
      <Route index element={<PointDetailsDetail />} />
      <Route path="edit" element={<PointDetailsUpdate />} />
      <Route path="delete" element={<PointDetailsDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default PointDetailsRoutes;
