import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import RecordedMeeting from './recorded-meeting';
import RecordedMeetingDetail from './recorded-meeting-detail';
import RecordedMeetingUpdate from './recorded-meeting-update';
import RecordedMeetingDeleteDialog from './recorded-meeting-delete-dialog';

const RecordedMeetingRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<RecordedMeeting />} />
    <Route path="new" element={<RecordedMeetingUpdate />} />
    <Route path=":id">
      <Route index element={<RecordedMeetingDetail />} />
      <Route path="edit" element={<RecordedMeetingUpdate />} />
      <Route path="delete" element={<RecordedMeetingDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default RecordedMeetingRoutes;
