import React from 'react';

const bell = props => {
  const unreadNotifications = Array.isArray(props.notification) ? props.notification.filter(notification => !notification.isRead) : [];

  // console.log(unreadNotifications, 'unreadNotifications');

  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.27172 12.25V11.375C5.27172 11.375 5.26927 9.62998 5.93806 8.0169C5.93806 8.0169 6.60685 6.40383 7.84419 5.17163C7.84419 5.17163 9.08152 3.93943 10.6974 3.27735C10.6974 3.27735 12.3132 2.61527 14.0594 2.62498C14.0594 2.62498 15.835 2.63843 17.4518 3.34776C17.4518 3.34776 19.0074 4.03021 20.2049 5.26031C20.2049 5.26031 21.3978 6.4856 22.052 8.06557C22.052 8.06557 22.728 9.69817 22.728 11.4734V12.25C22.728 12.25 22.728 16.7887 24.1524 19.2491C24.1524 19.2491 24.387 19.6553 24.3874 20.1234C24.3874 20.1234 24.3878 20.5915 24.1545 20.9973C24.1545 20.9973 23.9212 21.4031 23.5164 21.6382C23.5164 21.6382 23.1116 21.8733 22.6436 21.875L5.35922 21.875C5.35922 21.875 4.88805 21.8733 4.4833 21.6382C4.4833 21.6382 4.07855 21.4031 3.84522 20.9973C3.84522 20.9973 3.61189 20.5915 3.61231 20.1234C3.61231 20.1234 3.61272 19.6553 3.84677 19.25C3.84677 19.25 5.27172 16.7887 5.27172 12.25ZM7.02172 12.25C7.02172 12.25 7.02172 17.2587 5.36231 20.125L22.6374 20.125C22.6374 20.125 20.978 17.2579 20.978 12.25V11.4734C20.978 11.4734 20.978 8.5631 18.951 6.48104C18.951 6.48104 16.9219 4.39672 14.0497 4.37496C14.0497 4.37496 12.6531 4.36719 11.3609 4.89669C11.3609 4.89669 10.0686 5.42618 9.07905 6.41163C9.07905 6.41163 8.08949 7.39708 7.55463 8.68714C7.55463 8.68714 7.01976 9.9772 7.02172 11.375V12.25Z"
        fill="#111111"
      />
      <path
        d="M18.3748 21.8751V21.0001C18.3748 20.5168 17.9831 20.1251 17.4998 20.1251C17.0166 20.1251 16.6248 20.5168 16.6248 21.0001V21.8751C16.6248 22.9624 15.856 23.7312 15.856 23.7312C15.0871 24.5001 13.9998 24.5001 13.9998 24.5001C12.9125 24.5001 12.1437 23.7312 12.1437 23.7312C11.3748 22.9624 11.3748 21.8751 11.3748 21.8751V21.0001C11.3748 20.5168 10.9831 20.1251 10.4998 20.1251C10.0166 20.1251 9.62483 20.5168 9.62483 21.0001V21.8751C9.62483 23.6872 10.9062 24.9686 10.9062 24.9686C12.1876 26.2501 13.9998 26.2501 13.9998 26.2501C15.812 26.2501 17.0934 24.9686 17.0934 24.9686C18.3748 23.6872 18.3748 21.8751 18.3748 21.8751Z"
        fill="#111111"
      />

      {props.notification.length > 0 && unreadNotifications.length > 0 && (
        <g>
          <circle cx="19.5" cy="8.5" r="8" fill="#DF0000" />
          <text x="19.5" y="8" fill="#FFFFFF" fontSize="10" fontWeight="bold" textAnchor="middle" dominantBaseline="central">
            {unreadNotifications.length}
          </text>
        </g>
      )}
    </svg>
  );
};

export default bell;
