import React, { useDebugValue, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, Input, Label, FormGroup, Modal, ModalHeader, ModalBody, Spinner } from 'reactstrap';
import { Translate, byteSize, TextFormat, log } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import {
  getEntity,
  getEntityProfileDetails,
  updateEntityUserType,
  getUserMentorMenteeCount,
} from 'app/entities/user-profile/user-profile.reducer';
import { getEntityMenteeQuestions } from 'app/entities/question/question.reducer';
import { MenteeQuesions } from './MenteeQuestions';
import { getEntityMenteeTopic } from 'app/entities/mentee-topic/mentee-topic.reducer';
import { MentorTopicSlice, getEntityMentorTopic } from 'app/entities/mentor-topic/mentor-topic.reducer';
import Tabs from './Tabs';
import Connections from './Connections';
import { Country, State } from 'country-state-city';
import { getEntityTotalPoints } from 'app/entities/point-allocation/point-allocation.reducer';
import TotalPoints from './TotalPoints';
import Meetings from './Meetings';
import { getStateByCode, getStatesOfCountry } from 'country-state-city/lib/state';
import countriesList from 'countries-list';
import InspirationPage from './Inspiration';
import EarnPoints from './EarnPoints';
import ManagePoints from './ManagePoints';

interface StateType {
  tab: string;
  // Define other properties as needed
}

export const UserProfileDetail = props => {
  const dispatch = useAppDispatch();
  const { id } = useParams<'id'>();
  const [isMentee, setIsMentee] = useState(false);
  const [profileType, setProfileType] = useState('Mentor');
  const [questionData, setQuestionData] = useState([]);
  const [mentorTopics, setMentorTopics] = useState([]);
  const [menteeTopics, setMenteeTopics] = useState([]);
  const userProfileEntity = useAppSelector(state => state.userProfile.entity);
  const stateData = useAppSelector(state => state);
  const questionEntity = useAppSelector(state => state.question.entity);
  const mentorTopicEntity = useAppSelector(state => state.mentorTopic.entity);
  const menteeTopicEntity = useAppSelector(state => state.menteeTopic.entity);
  const account = useAppSelector(state => state.authentication.account);
  const loggedIn_id = account.id;
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('profile');
  const navigate = useNavigate();
  const location = useLocation();
  const baseImageUrl = '/profile/get/image';
  const toggleModal = () => {
    setModal(!modal);
  };
  const [Mainloading, setmainLoading] = useState(false);

  const onSwitchChange = async value => {
    setLoading(true);
    let userType = value == true ? 'Mentee' : 'Mentor';
    value == true ? setModal(true) : '';
    await dispatch(updateEntityUserType([id, !value]));
    setProfileType(userType);
    setIsMentee(value);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };
  useEffect(() => {}, [stateData]);

  useEffect(() => {
    const tabData = location?.state as StateType | undefined;
    if (tabData) {
      const tabActive = tabData.tab;
      setActiveTab(tabActive);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setmainLoading(true);
      await dispatch(getEntityProfileDetails(id));
      await dispatch(getEntityTotalPoints());
      await dispatch(getEntityMenteeQuestions(id));
      await dispatch(getEntityMenteeTopic(id));
      await dispatch(getEntityMentorTopic(id));
      await dispatch(getUserMentorMenteeCount());
      setmainLoading(false);
    };

    fetchData();
  }, [modal, profileType, id]);
  useEffect(() => {
    const isMentee = userProfileEntity[0]?.isMentor ? false : true;
    setIsMentee(isMentee);
    let userType = userProfileEntity[0]?.isMentor ? 'Mentor' : 'Mentee';
    setProfileType(userType);
    Array.isArray(questionEntity) ? setQuestionData(questionEntity) : '';
  }, [userProfileEntity, questionEntity]);

  useEffect(() => {
    Array.isArray(mentorTopicEntity) ? setMentorTopics(mentorTopicEntity) : '';
    Array.isArray(menteeTopicEntity) ? setMenteeTopics(menteeTopicEntity) : '';
  }, [mentorTopicEntity, menteeTopicEntity]);

  const country = userProfileEntity[0]?.country;
  const countryName = country ? country : '';
  const stateCode = userProfileEntity[0]?.state;
  const countryCode = Object.keys(countriesList.countries).find(code => countriesList.countries[code].name === countryName);
  const statesOfCountry = getStatesOfCountry(countryCode);
  const stateInfo = statesOfCountry.find(state => state.isoCode === stateCode);

  const StateName = stateInfo?.name;
  const profileinfo = loggedIn_id === id ? 'loggedin' : 'guest';
  switch (profileinfo) {
    case 'guest':
      return (
        <>
          {Mainloading ? (
            <div className="token-loader-home">
              <div className="token-loader">
                <Spinner className="body-loader-icon"></Spinner>
              </div>
            </div>
          ) : (
            <div className="container fiveplus-small-screen guest-viewprofile">
              <Row>
                <Col md="12">
                  <div className="card view-user-profile users-card">
                    <h2 data-cy="userProfileDetailsHeading"></h2>
                    <div className={`banner-img ${isMentee ? 'mentee-profile' : 'mentor-profile'}`}>
                      <div className="ribbon-corner">{isMentee ? 'Mentee' : 'Mentor'}</div>
                    </div>
                    <Row className="profile-btn-card align-items-end mb-5">
                      <Col md={8} lg={8}>
                        <div className="align-items-center d-flex ms-5 image-name">
                          <div className="me-4 user-avtar">
                            {/* {userProfileEntity && userProfileEntity[0]?.user && userProfileEntity[0]?.user.imageUrl && (
                            <img src={userProfileEntity[0]?.user.imageUrl} className="rounded-circle" />
                          )} */}
                            {userProfileEntity && userProfileEntity[0]?.user && (
                              <img
                                src={
                                  userProfileEntity[0]?.user.imageUrl
                                    ? `${baseImageUrl}/${userProfileEntity[0]?.user.imageUrl}`
                                    : `${baseImageUrl}/dummy-user.png`
                                }
                                className="rounded-circle"
                              />
                            )}
                          </div>
                          {userProfileEntity &&
                            userProfileEntity[0]?.user &&
                            userProfileEntity[0]?.user.firstName &&
                            userProfileEntity[0]?.user.lastName && (
                              <div className="user-avtar-name">
                                <h2 className="mb-0">
                                  {userProfileEntity[0]?.user.firstName} {userProfileEntity[0]?.user.lastName}
                                  {userProfileEntity && userProfileEntity[0]?.occupation && <div>{userProfileEntity[0]?.occupation}</div>}
                                </h2>
                              </div>
                            )}
                        </div>
                      </Col>
                    </Row>

                    <dl className="jh-entity-details">
                      <h3>Basic Information</h3>
                      <Row className="pt-3">
                        <Col sm="3">
                          <dt>
                            <span id="phoneNumber">
                              <Translate contentKey="fivePlusApp.userProfile.phoneNumber">Phone Number</Translate>
                            </span>
                          </dt>
                          <dd className="fw-medium">{userProfileEntity[0]?.phoneNumber}</dd>
                        </Col>
                        <Col sm="3">
                          <dt>
                            <span id="dateOfBirth">
                              <span>Year Of Birth</span>
                            </span>
                          </dt>

                          <dd className="fw-medium">{userProfileEntity[0]?.year}</dd>
                        </Col>
                        <Col sm="6">
                          <dt>
                            <span id="occupation">
                              <span>Area of Expertise</span>
                            </span>
                          </dt>
                          <dd className="fw-medium">{userProfileEntity[0]?.occupation}</dd>
                        </Col>
                      </Row>
                      <Row>
                        <h3 className="pt-3">Address</h3>
                        <Col sm="3">
                          <dt>
                            <span id="country">
                              <Translate contentKey="fivePlusApp.userProfile.country">Country</Translate>
                            </span>
                          </dt>
                          <dd className="fw-medium">{countryName}</dd>
                        </Col>
                        <Col sm="3">
                          <dt>
                            <span id="state">
                              <Translate contentKey="fivePlusApp.userProfile.state">State</Translate>
                            </span>
                          </dt>

                          <dd className="fw-medium">{StateName}</dd>
                        </Col>
                        <Col sm="3">
                          <dt>
                            <span id="zipCode">
                              <Translate contentKey="fivePlusApp.userProfile.zipCode">Zip Code</Translate>
                            </span>
                          </dt>
                          <dd className="fw-medium">{userProfileEntity[0]?.zipCode}</dd>
                        </Col>
                        <Col></Col>
                      </Row>
                      <hr></hr>
                      <Row className="pb-3 pt-3">
                        <dt>
                          <span id="instagramUrl">
                            <span>Instagram Id</span>
                          </span>
                        </dt>
                        <dd className="fw-medium">{userProfileEntity[0]?.instagramUrl}</dd>
                      </Row>
                      <hr></hr>
                      <Row className="pb-3 pt-3">
                        <dt>
                          <span id="selfDescription">
                            <h3 className="pt-3">Self Description</h3>
                          </span>
                        </dt>
                        <dd className="fw-medium">
                          {userProfileEntity && userProfileEntity[0] ? (
                            <Row>
                              {Object.entries(userProfileEntity[0]).map(([key, value]) => {
                                if (key.startsWith('selfDescription') && value) {
                                  const year = key.slice(-2);
                                  return (
                                    <Col sm="6" className="pb-2">
                                      <div key={key}>
                                        <dt className="decade-title"> {`${year}'s Decade Experience`} </dt>
                                        <div> {value as React.ReactNode}</div>
                                      </div>
                                    </Col>
                                  );
                                }
                                return null;
                              })}
                            </Row>
                          ) : (
                            'No self-description available'
                          )}
                        </dd>
                      </Row>
                      <Row className="pb-3 pt-3">
                        <dt>
                          <span id="helpCommunityDescription">
                            <span>Community Engagement & 5 Causes to Support/Non Profits</span>
                          </span>
                        </dt>
                        <dd className="fw-medium">{userProfileEntity[0]?.helpCommunityDescription}</dd>
                      </Row>
                      <hr></hr>
                      {profileType == 'Mentor' ? (
                        <Row className="pb-3 pt-3">
                          <div id="topic-ques">
                            <div>
                              <h3 className="pt-3">Topics</h3>
                            </div>
                            <ul className="d-flex">
                              {mentorTopics?.map(item => (
                                <li className="topic-uncheckbox" key={item.id}>
                                  {item.topic.name}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </Row>
                      ) : (
                        <>
                          {profileType == 'Mentee' ? (
                            <>
                              <div id="topic-ques" className="topic-checkbox">
                                <div>
                                  <h3 className="pt-3">Topics</h3>
                                </div>
                                <ul className="d-flex">
                                  {menteeTopics.length != 0 &&
                                    menteeTopics?.map(item => (
                                      <li className="topic-uncheckbox" key={item.id}>
                                        {item.topic.name}
                                      </li>
                                    ))}
                                </ul>
                                <div>
                                  <h3 className="pt-3">My 5 Questions to mentors?</h3>
                                </div>
                                {questionData.length != 0 ? (
                                  questionData.map((item, index) => (
                                    <span>
                                      <div className="mentor-question"> {` ${index + 1} Question to mentors`}</div>
                                      <div>{item.question}</div>
                                      <br></br>
                                    </span>
                                  ))
                                ) : (
                                  <Modal isOpen={modal} toggle={toggleModal}>
                                    <ModalHeader toggle={toggleModal}>Modal Header</ModalHeader>
                                    <ModalBody>
                                      <MenteeQuesions popup={setModal} />
                                    </ModalBody>
                                  </Modal>
                                )}
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                      <hr></hr>
                    </dl>
                    <Row className="">
                      <Col md={6} lg={6}>
                        <span id="mentors_count">Number of Mentors</span>

                        <dd className="fw-medium">{userProfileEntity?.mentors_count}</dd>
                      </Col>
                      <Col md={6} lg={6}>
                        <span id="mentee_count">No of Mentees</span>

                        <dd className="fw-medium">{userProfileEntity?.mentees_count}</dd>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </>
      );
    default:
      return (
        <>
          <div className="container">
            <Tabs
              tab1Content={<Connections />}
              tab2Content={
                Mainloading ? (
                  <div className="token-loader">
                    <Spinner className="body-loader-icon"></Spinner>
                  </div>
                ) : (
                  <Row>
                    <Col md="12">
                      <div className="card view-user-profile users-card">
                        <h2 data-cy="userProfileDetailsHeading">
                          {/* <Translate contentKey="fivePlusApp.userProfile.detail.title">UserPswdsdofile</Translate> */}
                          <div className="">My Profile </div>
                        </h2>
                        <div className={`banner-img ${isMentee ? 'mentee-profile' : 'mentor-profile'}`}>
                          <div className="ribbon-corner">{isMentee ? 'Mentee' : 'Mentor'}</div>
                          {isMentee}
                          <FormGroup switch className="tabs-switch">
                            <Input
                              type="switch"
                              checked={isMentee}
                              onClick={async () => {
                                onSwitchChange(!isMentee);
                              }}
                            />
                            <Label className="mentor-label">Mentor</Label>
                            <Label className="mentee-label">Mentee</Label>
                          </FormGroup>
                        </div>
                        <Row className="profile-btn-card align-items-end mb-5">
                          <Col md={8} lg={8}>
                            <div className="align-items-center d-flex ms-5 image-name">
                              <div className="me-4 user-avtar">
                                {/* {userProfileEntity && userProfileEntity[0]?.user && userProfileEntity[0]?.user.imageUrl && (
                                <img src={userProfileEntity[0]?.user.imageUrl} className="rounded-circle" />
                              )} */}
                                {userProfileEntity && userProfileEntity[0]?.user && (
                                  <img
                                    src={
                                      userProfileEntity[0]?.imageName
                                        ? `${baseImageUrl}/${userProfileEntity[0]?.imageName}`
                                        : userProfileEntity[0]?.user.imageUrl || `${baseImageUrl}/dummy-user.png`
                                    }
                                    className="rounded-circle"
                                  />
                                )}
                              </div>
                              {userProfileEntity &&
                                userProfileEntity[0]?.user &&
                                userProfileEntity[0]?.user.firstName &&
                                userProfileEntity[0]?.user.lastName && (
                                  <div className="user-avtar-name">
                                    <h2 className="mb-0">
                                      {userProfileEntity[0]?.user.firstName} {userProfileEntity[0]?.user.lastName}
                                    </h2>
                                    {userProfileEntity && userProfileEntity[0]?.occupation && <div>{userProfileEntity[0]?.occupation}</div>}
                                  </div>
                                )}
                            </div>
                          </Col>
                          <Col md={4} lg={4} className="profile-btns pe-5 text-end">
                            <Button
                              tag={Link}
                              outline
                              id="edit-profile-btn"
                              to={`/user-profile/${userProfileEntity[0]?.user.id}/edit`}
                              replace
                              color="primary"
                            >
                              <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit Profile</span>
                            </Button>

                            <Button tag={Link} outline id="logout-btn" to="/logout" replace color="info" data-cy="entityDetailsBackButton">
                              <FontAwesomeIcon icon="right-from-bracket" /> <span className="d-none d-md-inline">Logout</span>
                            </Button>
                          </Col>
                        </Row>

                        <dl className="jh-entity-details">
                          <h3>Basic Information</h3>
                          <Row className="pt-3">
                            <Col sm="3">
                              <dt>
                                <span id="phoneNumber">
                                  <Translate contentKey="fivePlusApp.userProfile.phoneNumber">Phone Number</Translate>
                                </span>
                              </dt>
                              <dd className="fw-medium">{userProfileEntity[0]?.phoneNumber}</dd>
                            </Col>
                            <Col sm="3">
                              <dt>
                                <span id="dateOfBirth">
                                  <span>Year Of Birth</span>
                                </span>
                              </dt>

                              <dd className="fw-medium">{userProfileEntity[0]?.year}</dd>
                            </Col>
                            <Col sm="6">
                              <dt>
                                <span id="occupation">
                                  <span>Area of Expertise</span>
                                </span>
                              </dt>
                              <dd className="fw-medium">{userProfileEntity[0]?.occupation}</dd>
                            </Col>
                          </Row>
                          <Row>
                            <h3 className="pt-3">Address</h3>
                            <Col sm="3">
                              <dt>
                                <span id="country">
                                  <Translate contentKey="fivePlusApp.userProfile.country">Country</Translate>
                                </span>
                              </dt>
                              <dd className="fw-medium">{countryName}</dd>
                            </Col>
                            <Col sm="3">
                              <dt>
                                <span id="state">
                                  <Translate contentKey="fivePlusApp.userProfile.state">State</Translate>
                                </span>
                              </dt>

                              <dd className="fw-medium">{StateName}</dd>
                            </Col>
                            <Col sm="3">
                              <dt>
                                <span id="zipCode">
                                  <Translate contentKey="fivePlusApp.userProfile.zipCode">Zip Code</Translate>
                                </span>
                              </dt>
                              <dd className="fw-medium">{userProfileEntity[0]?.zipCode}</dd>
                            </Col>
                          </Row>
                          <hr></hr>
                          <Row className="pb-3 pt-3">
                            <dt>
                              <span id="instagramUrl">
                                <span>Instagram Id</span>
                              </span>
                            </dt>
                            <dd className="fw-medium">{userProfileEntity[0]?.instagramUrl}</dd>
                          </Row>
                          <hr></hr>
                          <Row className="pb-3 pt-3">
                            <dt>
                              <span id="selfDescription">
                                <h3 className="pt-3">Self Description</h3>
                              </span>
                            </dt>
                            <dd className="fw-medium">
                              {userProfileEntity && userProfileEntity[0] ? (
                                <Row>
                                  {Object.entries(userProfileEntity[0]).map(([key, value]) => {
                                    if (key.startsWith('selfDescription') && value) {
                                      const year = key.slice(-2);
                                      return (
                                        <Col sm="6" className="pb-2">
                                          <div key={key}>
                                            <dt className="decade-title"> {`${year}'s Decade Experience`} </dt>
                                            <div>{value as React.ReactNode}</div>
                                          </div>
                                        </Col>
                                      );
                                    }
                                    return null;
                                  })}
                                </Row>
                              ) : (
                                'No self-description available'
                              )}
                            </dd>
                          </Row>
                          <hr></hr>
                          <Row className="pb-3 pt-3">
                            <dt>
                              <span id="helpCommunityDescription">
                                <span>Community Engagement & 5 Causes to Support/Non Profits</span>
                              </span>
                            </dt>
                            <dd className="fw-medium">{userProfileEntity[0]?.helpCommunityDescription}</dd>
                          </Row>
                          <hr></hr>
                          {profileType == 'Mentor' ? (
                            <Row className="pb-3 pt-3">
                              <div id="topic-ques">
                                <div>
                                  <h3 className="pt-3">Topics</h3>
                                </div>
                                <ul className="d-flex">
                                  {mentorTopics?.map(item => (
                                    <li className="topic-uncheckbox" key={item.id}>
                                      {item.topic.name}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </Row>
                          ) : (
                            <>
                              {profileType == 'Mentee' ? (
                                <>
                                  <div id="topic-ques" className="topic-checkbox">
                                    <div>
                                      <h3 className="pt-3">Topics</h3>
                                    </div>
                                    <ul className="d-flex">
                                      {menteeTopics.length != 0 &&
                                        menteeTopics?.map(item => (
                                          <li className="topic-uncheckbox" key={item.id}>
                                            {item.topic.name}
                                          </li>
                                        ))}
                                    </ul>
                                    <div>
                                      <h3 className="pt-3">My 5 questions to mentors?</h3>
                                    </div>
                                    {questionData.length != 0 ? (
                                      questionData.map((item, index) => (
                                        <span>
                                          <div className="mentor-question"> {` ${index + 1} question to mentors`}</div>
                                          <div>{item.question}</div>
                                          <br></br>
                                        </span>
                                      ))
                                    ) : (
                                      <Modal isOpen={modal} toggle={toggleModal}>
                                        <ModalHeader toggle={toggleModal}>My Five Questions to mentors ?</ModalHeader>
                                        <ModalBody>
                                          <MenteeQuesions popup={setModal} />
                                        </ModalBody>
                                      </Modal>
                                    )}
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          )}
                          <hr></hr>
                        </dl>
                        <h3 className="pt-3 pb-3">My Points</h3>
                        <TotalPoints />
                        <Row className="pt-5">
                          <Col md={3} lg={3}>
                            <span id="mentors_count">Number of Mentors</span>

                            <dd className="fw-medium">{userProfileEntity?.mentors_count}</dd>
                          </Col>
                          <Col md={3} lg={3}>
                            <span id="mentee_count">Number of Mentees</span>
                            <dd className="fw-medium">{userProfileEntity?.mentees_count}</dd>
                          </Col>
                        </Row>
                        {/* {<button onClick={referralCode()}>Refer a friend</button>} */}
                      </div>
                    </Col>
                    {loading ? (
                      <div className="loader_custom">
                        <Spinner>Loading...</Spinner>
                      </div>
                    ) : (
                      ''
                    )}
                  </Row>
                )
              }
              tab3Content={<Meetings />}
              tab4Content={<InspirationPage />}
              tab5Content={<ManagePoints />}
              currentActiveTab={activeTab}
            />
          </div>
        </>
      );
  }
};

export default UserProfileDetail;
