import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, byteSize, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './inspiration.reducer';

export const InspirationDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const inspirationEntity = useAppSelector(state => state.inspiration.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="inspirationDetailsHeading">
          <Translate contentKey="fivePlusApp.inspiration.detail.title">Inspiration</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{inspirationEntity.id}</dd>
          <dt>
            <span id="imageName">
              <Translate contentKey="fivePlusApp.inspiration.imageName">Image Name</Translate>
            </span>
          </dt>
          <dd>{inspirationEntity.imageName}</dd>
          <dt>
            <span id="topics">
              <Translate contentKey="fivePlusApp.inspiration.topics">Topics</Translate>
            </span>
          </dt>
          <dd>{inspirationEntity.topics}</dd>
          <dt>
            <span id="approved">
              <Translate contentKey="fivePlusApp.inspiration.approved">Approved</Translate>
            </span>
          </dt>
          <dd>{inspirationEntity.approved ? 'true' : 'false'}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="fivePlusApp.inspiration.description">Description</Translate>
            </span>
          </dt>
          <dd>{inspirationEntity.description}</dd>
          <dt>
            <span id="link">
              <Translate contentKey="fivePlusApp.inspiration.link">Link</Translate>
            </span>
          </dt>
          <dd>{inspirationEntity.link}</dd>
          <dt>
            <span id="createdDate">
              <Translate contentKey="fivePlusApp.inspiration.createdDate">Created Date</Translate>
            </span>
          </dt>
          <dd>
            {inspirationEntity.createdDate ? (
              <TextFormat value={inspirationEntity.createdDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="modifiedDate">
              <Translate contentKey="fivePlusApp.inspiration.modifiedDate">Modified Date</Translate>
            </span>
          </dt>
          <dd>
            {inspirationEntity.modifiedDate ? (
              <TextFormat value={inspirationEntity.modifiedDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="fivePlusApp.inspiration.user">User</Translate>
          </dt>
          <dd>{inspirationEntity.user ? inspirationEntity.user.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/inspiration" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/inspiration/${inspirationEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default InspirationDetail;
