import { IGuest } from 'app/shared/model/guest.model';
import { IUser } from 'app/shared/model/user.model';

export interface IMeeting {
  id?: number;
  name?: string | null;
  meetingTime?: number | null;
  processed?: boolean | null;
  duration?: number | null;
  dailyCoLink?: string | null;
  daliyCoRoom?: string | null;
  guests?: IGuest[] | null;
  user?: IUser | null;
}

export const defaultValue: Readonly<IMeeting> = {
  processed: false,
};
