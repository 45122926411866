import React from 'react';
import { Translate } from 'react-jhipster';

import MenuItem from 'app/shared/layout/menus/menu-item';

const EntitiesMenu = () => {
  return (
    <>
      {/* prettier-ignore */}
      <MenuItem icon="asterisk" to="/user-profile">
        <Translate contentKey="global.menu.entities.userProfile" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/work-experience">
        <Translate contentKey="global.menu.entities.workExperience" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/topic">
        <Translate contentKey="global.menu.entities.topic" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/question">
        <Translate contentKey="global.menu.entities.question" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/connection">
        <Translate contentKey="global.menu.entities.connection" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/mentor-topic">
        <Translate contentKey="global.menu.entities.mentorTopic" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/mentee-topic">
        <Translate contentKey="global.menu.entities.menteeTopic" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/notification">
        <Translate contentKey="global.menu.entities.notification" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/meeting">
        <Translate contentKey="global.menu.entities.meeting" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/guest">
        <Translate contentKey="global.menu.entities.guest" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/referral-code">
        <Translate contentKey="global.menu.entities.referralCode" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/point-allocation">
        <Translate contentKey="global.menu.entities.pointAllocation" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/self-description">
        <Translate contentKey="global.menu.entities.selfDescription" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/recorded-meeting">
        <Translate contentKey="global.menu.entities.recordedMeeting" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/inspiration">
        <Translate contentKey="global.menu.entities.inspiration" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/image">
        <Translate contentKey="global.menu.entities.image" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/news-feed">
        <Translate contentKey="global.menu.entities.newsFeed" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/user-login-details">
        <Translate contentKey="global.menu.entities.userLoginDetails" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/achivements">
        <Translate contentKey="global.menu.entities.achivements" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/points">
        <Translate contentKey="global.menu.entities.points" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/user-achivements">
        <Translate contentKey="global.menu.entities.userAchivements" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/point-allocation-system">
        <Translate contentKey="global.menu.entities.pointAllocationSystem" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/point-details">
        <Translate contentKey="global.menu.entities.pointDetails" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/achievements">
        <Translate contentKey="global.menu.entities.achievements" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/user-achievements">
        <Translate contentKey="global.menu.entities.userAchievements" />
      </MenuItem>
      {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
    </>
  );
};

export default EntitiesMenu;
