import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './points.reducer';

export const PointsDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const pointsEntity = useAppSelector(state => state.points.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="pointsDetailsHeading">
          <Translate contentKey="fivePlusApp.points.detail.title">Points</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{pointsEntity.id}</dd>
          <dt>
            <span id="referral">
              <Translate contentKey="fivePlusApp.points.referral">Referral</Translate>
            </span>
          </dt>
          <dd>{pointsEntity.referral}</dd>
          <dt>
            <span id="collegeAdmission">
              <Translate contentKey="fivePlusApp.points.collegeAdmission">College Admission</Translate>
            </span>
          </dt>
          <dd>{pointsEntity.collegeAdmission}</dd>
          <dt>
            <span id="graduation">
              <Translate contentKey="fivePlusApp.points.graduation">Graduation</Translate>
            </span>
          </dt>
          <dd>{pointsEntity.graduation}</dd>
          <dt>
            <span id="master">
              <Translate contentKey="fivePlusApp.points.master">Master</Translate>
            </span>
          </dt>
          <dd>{pointsEntity.master}</dd>
          <dt>
            <span id="phd">
              <Translate contentKey="fivePlusApp.points.phd">Phd</Translate>
            </span>
          </dt>
          <dd>{pointsEntity.phd}</dd>
          <dt>
            <span id="communityService">
              <Translate contentKey="fivePlusApp.points.communityService">Community Service</Translate>
            </span>
          </dt>
          <dd>{pointsEntity.communityService}</dd>
          <dt>
            <span id="crowedFunding">
              <Translate contentKey="fivePlusApp.points.crowedFunding">Crowed Funding</Translate>
            </span>
          </dt>
          <dd>{pointsEntity.crowedFunding}</dd>
          <dt>
            <span id="startUp">
              <Translate contentKey="fivePlusApp.points.startUp">Start Up</Translate>
            </span>
          </dt>
          <dd>{pointsEntity.startUp}</dd>
          <dt>
            <span id="kindnessAct">
              <Translate contentKey="fivePlusApp.points.kindnessAct">Kindness Act</Translate>
            </span>
          </dt>
          <dd>{pointsEntity.kindnessAct}</dd>
          <dt>
            <Translate contentKey="fivePlusApp.points.achivements">Achivements</Translate>
          </dt>
          <dd>{pointsEntity.achivements ? pointsEntity.achivements.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/points" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/points/${pointsEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default PointsDetail;
