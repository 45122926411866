import React, { useDebugValue, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { MentorTopicSlice, getEntityMentorTopic } from 'app/entities/mentor-topic/mentor-topic.reducer';
import AdminTabs from './AdminTabs';
import NewsFeedPage from './newsFeed';
import InspirationAdminPage from './InspirationAdmin';
import UsersAdmin from './UsersAdmin';
import { Row } from 'reactstrap';
import UsersAdminDashoard from './AdminDashboard';
import Topic from './topic';
import PointAllocationSystem from './point-allocation-system';
import PointDetails from './point-details';

interface StateType {
  tab: string;
  // Define other properties as needed
}

export const AdminProfile = props => {
  const dispatch = useAppDispatch();
  const { id } = useParams<'id'>();
  const [activeTab, setActiveTab] = useState('admindashboard');
  const location = useLocation();

  useEffect(() => {
    const tabData = location?.state as StateType | undefined;
    if (tabData) {
      const tabActive = tabData.tab;
      setActiveTab(tabActive);
    }
  }, []);
  return (
    <>
      <div className="container">
        <AdminTabs
          tab1Content={<UsersAdminDashoard />}
          tab2Content={<UsersAdmin />}
          tab3Content={<NewsFeedPage />}
          tab4Content={<InspirationAdminPage />}
          tab5Content={<Topic />}
          tab6Content={<PointDetails />}
          currentActiveTab={activeTab}
        />
      </div>
    </>
  );
};

export default AdminProfile;
