import { IAchivements } from 'app/shared/model/achivements.model';

export interface IPoints {
  id?: number;
  referral?: number | null;
  collegeAdmission?: number | null;
  graduation?: number | null;
  master?: number | null;
  phd?: number | null;
  communityService?: number | null;
  crowedFunding?: number | null;
  startUp?: number | null;
  kindnessAct?: number | null;
  achivements?: IAchivements | null;
}

export const defaultValue: Readonly<IPoints> = {};
