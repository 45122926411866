import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  CardImg,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  UncontrolledTooltip,
} from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntityMeetingDetails } from 'app/entities/meetinginfo/meetinginfo.reducer';
import MeetingLinkCopyButton from './MeetingLinkCopyButton';
import axios from 'axios';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { Translate, getSortState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
const Meetings = () => {
  const meetinginfoEntity = useAppSelector(state => state.meetinginfo.entity);
  const account = useAppSelector(state => state.authentication.account);
  const [meetingData, setMeetingData] = useState([]);
  const [recordingLink, setRecordingLink] = useState([]);
  const [showAllProfiles, setShowAllProfiles] = useState(false);
  const [modal, setModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const baseImageUrl = '/profile/get/image';

  // const [currentDate, setCurrentDate] = useState(() => {
  //   const date = new Date();
  //   date.setHours(date.getHours());
  //   return date;
  // });
  const [currentDate, setCurrentDate] = useState(new Date());
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000); // Update every 1000 milliseconds (1 second)

    return () => clearInterval(intervalId);
  }, [meetinginfoEntity]);
  const toggleModal = () => {
    setModal(!modal);
  };
  const dispatch = useAppDispatch();
  const meetingUrl = 'api/updateMeetingStatus';
  const downloadUrl = '/api/download/meeting/recordings';
  const [loading, setLoading] = useState(false);
  const loggedInUserId = account.id;
  const [totalMeetingItems, setTotalMeetingItems] = useState(Number);
  const [showMoreMap, setShowMoreMap] = useState({});
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, 20, 'id'), location.search)
  );
  const toggleShowAllProfiles = () => {
    setShowAllProfiles(!showAllProfiles);
  };

  useEffect(() => {
    dispatch(
      getEntityMeetingDetails({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
      })
    );
  }, [paginationState]);

  useEffect(() => {
    if (meetinginfoEntity && Object.keys(meetinginfoEntity).length > 0) {
      const contentArray = meetinginfoEntity.content || [];
      meetinginfoEntity.totalElements != undefined ? setTotalMeetingItems(meetinginfoEntity.totalElements) : '';
      const formattedMeetingData = [];

      for (const contentItem of contentArray) {
        for (const dateKey in contentItem) {
          if (contentItem.hasOwnProperty(dateKey)) {
            const meetingsForDate = contentItem[dateKey];

            if (Array.isArray(meetingsForDate)) {
              const filteredMeetings = meetingsForDate.filter(meeting => meeting && meeting.meetingCreator);

              if (filteredMeetings.length > 0) {
                formattedMeetingData.push({
                  date: dateKey,
                  meetings: filteredMeetings,
                });
              }
            }
          }
        }
      }
      setMeetingData(formattedMeetingData);
    }
  }, [meetinginfoEntity]);

  const toggleShowMore = recordIndex => {
    setShowMoreMap(prevState => ({
      ...prevState,
      [recordIndex]: !prevState[recordIndex],
    }));
  };

  const acceptMeeting = async (userID, meetID) => {
    setLoading(true);
    try {
      const requestUrl = `${meetingUrl}?guestId=${userID}&meetingId=${meetID}&status=true`;
      const response = await axios.put(requestUrl);
      if (response.status === 200) {
        await dispatch(
          getEntityMeetingDetails({
            page: paginationState.activePage - 1,
            size: paginationState.itemsPerPage,
          })
        );
        setLoading(false);
      }
    } catch (error) {}
  };
  const downloadMeeting = async meetID => {
    setLoading(true);
    try {
      const requestUrl = `${downloadUrl}?meetingId=${meetID}`;
      const response = await axios.get(requestUrl);
      if (response.status === 200) {
        const videoUrl = response.data;
        setVideoUrl(videoUrl);
        toggleModal();

        setLoading(false);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  useEffect(() => {}, [recordingLink]);

  const handlePagination = async currentPage => {
    setLoading(true);
    await setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

    await dispatch(
      getEntityMeetingDetails({
        page: currentPage - 1,
        size: paginationState.itemsPerPage,
      })
    );
    setLoading(false);
  };
  return (
    <Row className="meeting-tab-inner">
      <Col md="12">
        <div className="card meeting-border">
          {meetinginfoEntity && Object.keys(meetinginfoEntity).length > 0 ? (
            meetingData.map((dateGroup, index) => (
              <div className="meeting-tab-by-date" key={index}>
                <h2>Meeting date: {new Date(dateGroup.date).toLocaleDateString('en-US')}</h2>
                {dateGroup.meetings.map((meeting, meetingIndex) => (
                  <Card className="p-0 mb-2 connection-card " key={meetingIndex}>
                    <CardBody className="d-flex justify-content-between align-items-center">
                      <div>
                        <CardTitle tag="h5">{meeting.meetingCreator.name}</CardTitle>
                        <div className="d-flex">
                          <p className="pe-3">{new Date(meeting.meetingCreator.meetingTime).toLocaleDateString('en-US')}</p>
                          <p className="meeting-time-border">{new Date(meeting.meetingCreator.meetingTime).toLocaleTimeString()}</p>
                          <p className="ps-3">{meeting.meetingCreator.duration / 60000} minutes</p>
                        </div>
                        <div className="menbers-info">
                          <div className="connection-avtar d-flex meeting-org-avtar">
                            <CardImg
                              id={`avatar-${index}-${meetingIndex}`}
                              className="rounded-circle image-user-org"
                              src={`${baseImageUrl}/${meeting.meetingCreator.user.imageUrl}`}
                            />
                            <UncontrolledTooltip placement="top" target={`avatar-${index}-${meetingIndex}`}>
                              {meeting.meetingCreator.user.firstName} {meeting.meetingCreator.user.lastName}
                            </UncontrolledTooltip>
                            <div className="meeting-names">
                              <p className="meeting-org-name">
                                {meeting.meetingCreator.user.firstName} {meeting.meetingCreator.user.lastName}
                              </p>
                              <p className="meeting-org">Organizer</p>
                            </div>
                          </div>
                          <div className="d-flex meeting-member">
                            {meeting.meetingCreator.guests
                              .slice(0, showMoreMap[index + meetingIndex] ? undefined : 3)
                              .map((guest, guestIndex) => (
                                <div className="connection-avtar" key={guestIndex}>
                                  <CardImg
                                    id={`guest-avatar-${index}-${meetingIndex}-${guestIndex}`}
                                    className="rounded-circle"
                                    src={`${baseImageUrl}/${guest.user.imageUrl}`}
                                  />
                                  <UncontrolledTooltip placement="top" target={`guest-avatar-${index}-${meetingIndex}-${guestIndex}`}>
                                    {guest.user.firstName} {guest.user.lastName}
                                  </UncontrolledTooltip>
                                </div>
                              ))}
                            {meeting.meetingCreator.guests.length > 3 && (
                              <div className="view-more-member">
                                <button onClick={() => toggleShowMore(index + meetingIndex)} className="more-button">
                                  {showMoreMap[index + meetingIndex] ? '- Less' : `+${meeting.meetingCreator.guests.length - 3} More`}
                                </button>
                              </div>
                            )}
                          </div>
                          <div className="user-numbers">
                            {meeting.meetingCreator.guests && (
                              <p>
                                {meeting.meetingCreator.guests.filter(guest => guest.status).length} Yes |{' '}
                                {meeting.meetingCreator.guests.filter(guest => !guest.status).length} Waiting
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="meeting-recording">
                        <div className="d-flex align-items-center join-btn">
                          {meeting.guest && !meeting.guest.status && (
                            <button
                              className={`accept-btn ${
                                new Date(meeting.meetingCreator.meetingTime).getTime() + meeting.meetingCreator.duration <
                                currentDate.getTime()
                                  ? 'disabled'
                                  : ''
                              }`}
                              onClick={() => {
                                acceptMeeting(meeting.guest.user.id, meeting.guest.meeting.id);
                              }}
                            >
                              Accept
                            </button>
                          )}
                        </div>
                        <CardSubtitle className="" tag="h6">
                          <div className="d-flex align-items-center join-btn">
                            {loggedInUserId === meeting.meetingCreator.user.id
                              ? meeting.meetingCreator.dailyCoLink && (
                                  <a
                                    href={meeting.meetingCreator.dailyCoLink}
                                    target="_blank"
                                    className={`accept-btn ${
                                      new Date(meeting.meetingCreator.meetingTime).getTime() + meeting.meetingCreator.duration <
                                      currentDate.getTime()
                                        ? 'disabled'
                                        : ''
                                    }`}
                                  >
                                    {new Date(meeting.meetingCreator.meetingTime).getTime() + meeting.meetingCreator.duration <
                                    currentDate.getTime()
                                      ? 'Meeting Completed'
                                      : 'Join Now'}
                                  </a>
                                )
                              : meeting.meetingCreator.guests &&
                                meeting.meetingCreator.guests.some(guest => loggedInUserId === guest.user.id) &&
                                meeting.meetingCreator.guests.find(guest => loggedInUserId === guest.user.id).dailyCoGuestLink && (
                                  <a
                                    href={meeting.meetingCreator.guests.find(guest => loggedInUserId === guest.user.id).dailyCoGuestLink}
                                    target="_blank"
                                    className={
                                      new Date(meeting.meetingCreator.meetingTime).getTime() + meeting.meetingCreator.duration <
                                      currentDate.getTime()
                                        ? 'disabled'
                                        : ''
                                    }
                                  >
                                    {new Date(meeting.meetingCreator.meetingTime).getTime() + meeting.meetingCreator.duration <
                                    currentDate.getTime()
                                      ? 'Meeting Completed'
                                      : 'Join Now'}
                                  </a>
                                )}{' '}
                          </div>

                          <div className="d-flex align-items-center download-btn">
                            {meeting.recordedMeeting && meeting.recordedMeeting.id && (
                              <button className="accept-btn" onClick={() => downloadMeeting(meeting.meetingCreator.id)}>
                                View Recording
                              </button>
                            )}
                          </div>
                        </CardSubtitle>
                      </div>
                    </CardBody>
                  </Card>
                ))}
              </div>
            ))
          ) : (
            <div className="meeting-tab-gif">
              <Row>
                <div className="col-md-6">
                  <h4>Schedule Collaborative Sessions</h4>
                  <p>
                    At the moment, you have no scheduled meetings. Before setting up a meeting, ensure you've established connections.
                    Connect with peers, colleagues, and industry professionals first. Once you've built your network, scheduling meetings
                    becomes a seamless process. Every conversation is an opportunity to grow and collaborate.
                  </p>{' '}
                </div>
                <div className="col-md-6">
                  <img src="content/images/meeting-tab.gif" />
                </div>
              </Row>
            </div>
          )}
        </div>

        {meetinginfoEntity ? (
          <div className={meetingData && meetingData.length > 0 ? '' : 'd-none'}>
            <div className="justify-content-center d-flex pagination-sec">
              <JhiItemCount
                page={paginationState.activePage}
                total={totalMeetingItems}
                itemsPerPage={paginationState.itemsPerPage}
                i18nEnabled
              />
            </div>
            <div className="justify-content-center d-flex">
              <JhiPagination
                activePage={paginationState.activePage}
                onSelect={handlePagination}
                maxButtons={5}
                itemsPerPage={paginationState.itemsPerPage}
                totalItems={totalMeetingItems}
              />
            </div>
          </div>
        ) : (
          ''
        )}
        <Modal className="meeting-recording-popup" isOpen={modal} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>Meeting Recording</ModalHeader>
          <ModalBody>
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div>
                {videoUrl && (
                  <video width="640" height="360" controls>
                    <source src={videoUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )}
              </div>
            )}
          </ModalBody>
        </Modal>
      </Col>

      {loading ? (
        <div className="loader_custom">
          <Spinner>Loading...</Spinner>
        </div>
      ) : (
        ''
      )}
    </Row>
  );
};

export default Meetings;
