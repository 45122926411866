import { IUser } from 'app/shared/model/user.model';
import { IMeeting } from 'app/shared/model/meeting.model';

export interface IGuest {
  id?: number;
  status?: boolean | null;
  dailyCoGuestLink?: string | null;
  user?: IUser | null;
  meeting?: IMeeting | null;
}

export const defaultValue: Readonly<IGuest> = {
  status: false,
};
