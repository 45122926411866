import './header.scss';
import React, { useEffect, useState } from 'react';
import { Translate, Storage } from 'react-jhipster';
import { Navbar, Nav, NavbarToggler, Collapse, Button, NavItem, NavLink, Modal, ModalHeader, ModalBody, Spinner } from 'reactstrap';
import LoadingBar from 'react-redux-loading-bar';
import { Home, Brand } from './header-components';
import { AdminMenu, EntitiesMenu, AccountMenu, LocaleMenu } from '../menus';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { setLocale } from 'app/shared/reducers/locale';
import { Navigate, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import SearchConnection from 'app/views/user_profile/login/SearchConnection';
import axios from 'axios';

export interface IHeaderProps {
  isAuthenticated: boolean;
  isAdmin: boolean;
  ribbonEnv: string;
  isInProduction: boolean;
  isOpenAPIEnabled: boolean;
  currentLocale: string;
}

const Header = (props: IHeaderProps) => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const dispatch = useAppDispatch();
  const [loadingMenu, setLoadingMenu] = useState(false);
  const handleLocaleChange = event => {
    const langKey = event.target.value;
    Storage.session.set('locale', langKey);
    dispatch(setLocale(langKey));
  };
  const auth = useAppSelector(state => state.authentication.isAuthenticated);

  const searchNavigate = e => {
    navigate('/search/');
  };
  useEffect(() => {
    if (localStorage.getItem('access_token') !== null) {
      setLoadingMenu(true);
    }
  }, []);

  useEffect(() => {
    setLoadingMenu(false);
  }, [auth]);
  const renderDevRibbon = () =>
    props.isInProduction === false ? (
      <div className="ribbon dev">
        <a href="">
          <Translate contentKey={`global.ribbon.${props.ribbonEnv}`} />
        </a>
      </div>
    ) : null;
  const loading = useAppSelector(state => state.authentication.loading);
  const toggleMenu = () => setMenuOpen(!menuOpen);
  const user = useAppSelector(state => state.authentication.account);
  //const userName = user.firstName + ' ' + user.lastName;
  //const userNameCookie = Cookies.get('userName');
  //console.log(user, 'user');
  // axios
  //   .get('https://5plus.us/wp-json/wp/v2/custom-ep', {
  //     withCredentials: true,
  //   })
  //   .then(response => {
  //     // Handle the response from the WordPress backend
  //     //console.log(response.data);
  //   })
  //   .catch(error => {
  //     // Handle any errors
  //     // console.error(error);
  //   });

  // axios
  //   .post(
  //     'https://5plus.us/wp-json/wp/v2/custom-ep',
  //     { userName },
  //     {
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //     }
  //   )
  //   .then(response => {
  //     // Handle the response from the WordPress backend
  //     console.log(response.data);
  //   })
  //   .catch(error => {
  //     // Handle any errors
  //     console.error(error);
  //   });
  /* jhipster-needle-add-element-to-menu - JHipster will add new menu items here */
  //const userNameCookie = Cookies.get('userName');
  // console.log(userNameCookie);

  // axios
  //   .get('https://5plus.test6.redblink.net/wp-json/wp/v2/custom-ep', {
  //     withCredentials: true, // Allow Axios to include cookies
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //   })
  //   .then(response => {
  //     // Handle the response from the WordPress backend
  //     console.log(response.data);
  //   })
  //   .catch(error => {
  //     // Handle any errors
  //     console.error(error);
  //   });
  return (
    <div id="app-header" className="mb-4">
      {/* {renderDevRibbon()} */}
      <LoadingBar className="loading-bar" />
      <Navbar data-cy="navbar" dark expand="md" fixed="top" className="jh-navbar">
        <NavbarToggler aria-label="Menu" onClick={toggleMenu} />
        <Brand />
        <Collapse isOpen={menuOpen} navbar>
          <Nav id="header-tabss" className="ms-auto" navbar>
            {!loading && <Home />} {/* Show Home when not loading and user is authenticated */}
            {!loading && (
              <>
                <NavItem>
                  <NavLink href="https://5plus.us/contact-us/">Contact Us</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="https://5plus.us/blog/">Blog</NavLink>
                </NavItem>
                <LocaleMenu currentLocale={props.currentLocale} onClick={handleLocaleChange} />
                <AccountMenu isAuthenticated={props.isAuthenticated} />
              </>
            )}
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default Header;
