import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Achievements from './achievements';
import AchievementsDetail from './achievements-detail';
import AchievementsUpdate from './achievements-update';
import AchievementsDeleteDialog from './achievements-delete-dialog';

const AchievementsRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Achievements />} />
    <Route path="new" element={<AchievementsUpdate />} />
    <Route path=":id">
      <Route index element={<AchievementsDetail />} />
      <Route path="edit" element={<AchievementsUpdate />} />
      <Route path="delete" element={<AchievementsDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default AchievementsRoutes;
