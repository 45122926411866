import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import WorkExperience from './work-experience';
import WorkExperienceDetail from './work-experience-detail';
import WorkExperienceUpdate from './work-experience-update';
import WorkExperienceDeleteDialog from './work-experience-delete-dialog';

const WorkExperienceRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<WorkExperience />} />
    <Route path="new" element={<WorkExperienceUpdate />} />
    <Route path=":id">
      <Route index element={<WorkExperienceDetail />} />
      <Route path="edit" element={<WorkExperienceUpdate />} />
      <Route path="delete" element={<WorkExperienceDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default WorkExperienceRoutes;
