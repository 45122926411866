import { IGuest } from 'app/shared/model/guest.model';
import { IUser } from 'app/shared/model/user.model';
import { IRecordedMeeting } from 'app/shared/model/recorded-meeting.model';
import { MeetingStatus } from 'app/shared/model/enumerations/meeting-status.model';

export interface IMeeting {
  id?: number;
  name?: string | null;
  meetingTime?: number | null;
  duration?: number | null;
  statusType?: MeetingStatus | null;
  dailyCoLink?: string | null;
  daliyCoRoom?: string | null;
  guests?: IGuest[] | null;
  user?: IUser | null;
  recordedMeeting?: IRecordedMeeting | null;
}

export const defaultValue: Readonly<IMeeting> = {};
