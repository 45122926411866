import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import UserProfile from './user-profile';
import WorkExperience from './work-experience';
import Topic from './topic';
import Question from './question';
import Connection from './connection';
import MentorTopic from './mentor-topic';
import MenteeTopic from './mentee-topic';
import Notification from './notification';
import Meeting from './meeting';
import Guest from './guest';
import ReferralCode from './referral-code';
import PointAllocation from './point-allocation';
import SearchConnection from 'app/views/user_profile/login/SearchConnection';
import PageNotFound from 'app/shared/error/page-not-found';
// import TestForm from 'app/views/user_profile/login/testform';

import SelfDescription from './self-description';
import RecordedMeeting from './recorded-meeting';
import Inspiration from './inspiration';
import Image from './image';
import InspirationsUpdate from 'app/views/user_profile/login/inspirations-update';
import NewsFeed from './news-feed';
import NewsFeedPage from 'app/views/user_profile/login/newsFeed';
import NewsFeedRoutes from './news-feed';
import UserLoginDetails from './user-login-details';
import UsersAdmin from 'app/views/user_profile/login/UsersAdmin';
import Achivements from './achivements';
import Points from './points';
import UserAchivements from './user-achivements';
import PointAllocationSystem from './point-allocation-system';
import PointDetails from './point-details';
import Achievements from './achievements';
import UserAchievements from './user-achievements';
import TopicRoutes from 'app/views/user_profile/login/topic';
/* jhipster-needle-add-route-import - JHipster will add routes here */

export default () => {
  return (
    <div>
      <ErrorBoundaryRoutes>
        {/* prettier-ignore */}
        <Route path="user-profile/*" element={<UserProfile />} />
        <Route path="work-experience/*" element={<WorkExperience />} />
        <Route path="search/" element={<SearchConnection />} />
        {/* <Route path="topic/*" element={<TopicRoutes />} /> */}
        <Route path="question/*" element={<Question />} />
        <Route path="connection/*" element={<Connection />} />
        <Route path="mentor-topic/*" element={<MentorTopic />} />
        <Route path="mentee-topic/*" element={<MenteeTopic />} />
        <Route path="notification/*" element={<Notification />} />
        <Route path="meeting/*" element={<Meeting />} />
        <Route path="guest/*" element={<Guest />} />
        <Route path="referral-code/*" element={<ReferralCode />} />
        <Route path="point-allocation/*" element={<PointAllocation />} />
        <Route path="self-description/*" element={<SelfDescription />} />
        <Route path="recorded-meeting/*" element={<RecordedMeeting />} />
        <Route path="inspiration/*" element={<Inspiration />} />
        <Route path="inspiration/*" element={<Inspiration />} />
        <Route path="admin/users" element={<UsersAdmin />}>
          <Route path="new" element={<InspirationsUpdate />} />
          <Route path=":id">
            <Route path="edit" element={<InspirationsUpdate />} />
          </Route>
        </Route>
        <Route path="image/*" element={<Image />} />
        <Route path="news-feed/*" element={<NewsFeed />} />
        <Route path="user-login-details/*" element={<UserLoginDetails />} />
        <Route path="achivements/*" element={<Achivements />} />
        <Route path="points/*" element={<Points />} />
        <Route path="user-achivements/*" element={<UserAchivements />} />
        <Route path="point-allocation-system/*" element={<PointAllocationSystem />} />
        <Route path="point-details/*" element={<PointDetails />} />
        <Route path="achievements/*" element={<Achievements />} />
        <Route path="user-achievements/*" element={<UserAchievements />} />
        {/* jhipster-needle-add-route-path - JHipster will add routes here */}
      </ErrorBoundaryRoutes>
    </div>
  );
};
