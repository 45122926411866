import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import MentorTopic from './mentor-topic';
import MentorTopicDetail from './mentor-topic-detail';
import MentorTopicUpdate from './mentor-topic-update';
import MentorTopicDeleteDialog from './mentor-topic-delete-dialog';

const MentorTopicRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<MentorTopic />} />
    <Route path="new" element={<MentorTopicUpdate />} />
    <Route path=":id">
      <Route index element={<MentorTopicDetail />} />
      <Route path="edit" element={<MentorTopicUpdate />} />
      <Route path="delete" element={<MentorTopicDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default MentorTopicRoutes;
