import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';

export interface IWorkExperience {
  id?: number;
  position?: string;
  employerName?: string | null;
  employmentType?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  isPresentJob?: boolean | null;
  employeeLogo?: string | null;
  user?: IUser | null;
}

export const defaultValue: Readonly<IWorkExperience> = {
  isPresentJob: false,
};
