import React from 'react';

import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const NavDropdown = props => (
  <UncontrolledDropdown nav inNavbar id={props.id} data-cy={props['data-cy']}>
    <DropdownToggle nav caret className="d-flex align-items-center">
      {/* <FontAwesomeIcon icon={props.icon} /> */}
      {/* {!props.imgUrl ? <img src={props.imgUrl} /> : <FontAwesomeIcon icon={props.icon} />} */}
      {props.imgUrl ? <img src={props.imgUrl} className="rounded-circle avatar" /> : <FontAwesomeIcon icon={props.icon} />}
      {props.name ? props.name : props.account}
      {/* <span>{props.name}</span> */}
    </DropdownToggle>
    <DropdownMenu end style={props.style}>
      {props.children}
    </DropdownMenu>
  </UncontrolledDropdown>
);
