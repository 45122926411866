import React, { useEffect, useState } from 'react';
import { Button, Col, Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { getEntityFilterConnectionsmeeting } from 'app/entities/connectionmeeting/connectionmeeting.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { createUserMeeting } from 'app/entities/meeting/meeting.reducer';
import { format } from 'date-fns';
import { components } from 'react-select';
import { default as ReactSelect } from 'react-select';
import makeAnimated from 'react-select/animated';
interface User {
  name: string;
  email: string;
  selected: boolean;
  userId: string;
  image: string;
  occupation: string;
}

const ConnectionMeetingForm: React.FC = () => {
  const [title, setTitle] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [duration, setDuration] = useState('');
  const [users, setUsers] = useState<User[]>([]);
  const [titleError, setTitleError] = useState('');
  const [dateError, setDateError] = useState('');
  const [timeError, setTimeError] = useState('');
  const [durationError, setDurationError] = useState('');
  const [userError, setUserError] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const dispatch = useAppDispatch();
  const connectionMeetingEntity = useAppSelector(state => state.connectionmeeting.entity);
  const account = useAppSelector(state => state.authentication.account);
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const SpecialCharacterRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~/\\]/;
  const baseImageUrl = '/profile/get/image';

  //const [guestUser, setGusetUsers]=useState([]);

  useEffect(() => {
    //await dispatch(getEntityUserConnections('accepted'));
    dispatch(getEntityFilterConnectionsmeeting(['connection', 'accepted']));
    //console.log(connectionMeetingEntity, "connectionMeetingEntity");
  }, []);
  useEffect(() => {
    if (Array.isArray(connectionMeetingEntity)) {
      // console.log("connectionMeetingEntity",connectionMeetingEntity);
      const formattedUsers = connectionMeetingEntity?.map(connection => ({
        value: connection.userId,
        image: connection.imageUrl,
        label: connection.userName,
        name: connection.userName,
        email: connection.email,
        selected: false, // Set an appropriate value for 'selected'
        userId: connection.userId,
        occupation: connection.occupation,
      }));

      setUsers(formattedUsers);
    }
  }, [connectionMeetingEntity]);

  const [selectedUsers, setSelectedUsers] = useState([]);

  const handleMeetingGuests = selected => {
    setUserError('');
    const selectedItems = [];
    const usersSelected = selected.map(user => selectedItems.push(user.value));
    setSelectedUsers(selectedItems);
  };

  const Option = props => {
    return (
      <div>
        <components.Option {...props}>
          {/* <input type="checkbox" checked={props.isSelected} onChange={() => null} />&nbsp;&nbsp; */}
          <img src={`${baseImageUrl}/${props.data.image}`} width={30} height={30} className="rounded-circle" />
          &nbsp;&nbsp;
          <label> {props.label}</label>
        </components.Option>
      </div>
    );
  };

  const currentDate = new Date().toISOString().slice(0, 16);
  const MAX_MEETING_DURATION_MS = 10 * 60 * 1000;
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Reset error messages
    setTitleError('');
    setDateError('');
    setTimeError('');
    setDurationError('');
    setUserError('');
    if (SpecialCharacterRegex.test(title)) {
      setTitleError('Special characters are not allowed');
      return;
    }

    // Check if all fields are filled
    if (!title) {
      setTitleError('Please enter a title');
      return;
    }
    if (!date) {
      setDateError('Please enter a date');
      return;
    }

    if (!duration) {
      setDurationError('Please enter a duration');
      return;
    }

    // Check if at least 2 users are selected
    if (selectedUsers.length < 2) {
      setUserError('Please select at least 2 users');
      return;
    }

    // If any error messages exist, return without submitting the form
    if (titleError || dateError || timeError || durationError || userError) {
      return;
    }
    const formattedDatetime = new Date(date);
    const durationupdate = Number(duration) * 60000;
    if (durationupdate > MAX_MEETING_DURATION_MS) {
      setDurationError('Meeting duration should not exceed 10 minutes');
      return;
    }
    const durationValue = parseInt(duration, 10); // Parse the duration as an integer

    if (isNaN(durationValue) || durationValue <= 0) {
      setDurationError('Meeting duration should be a positive number');
      return;
    }
    //let finaldatetime=formattedDatetime.getTime()
    const meetingData = {
      name: title,
      createdByUserId: account.id,
      dateTime: formattedDatetime.getTime(),
      duration: durationupdate,
      guestsUserIds: selectedUsers,
    };
    const meetingresp = await dispatch(createUserMeeting(meetingData));
    if (createUserMeeting.rejected.match(meetingresp)) {
      const resultData = meetingresp.error; // This is the data returned by the API
      setUserError(resultData.message);
    } else {
      setFormSubmitted(true);
    }
  };

  return (
    <>
      {!formSubmitted ? (
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label for="text">Topic of Discussion</Label>
            <Input
              type="text"
              id="title"
              value={title}
              onChange={e => setTitle(e.target.value)}
              onInput={() => setTitleError('')}
              invalid={!!titleError}
            />
            {titleError && <FormFeedback>{titleError}</FormFeedback>}
          </FormGroup>
          <FormGroup>
            <Label for="date">Date</Label>
            <Input
              type="datetime-local"
              id="date"
              min={currentDate}
              value={date}
              onChange={e => {
                const selectedDate = e.target.value;
                //console.log(selectedDate);
                setDate(selectedDate);
              }}
              onInput={() => setDateError('')}
              invalid={!!dateError}
            />
            {dateError && <FormFeedback>{dateError}</FormFeedback>}
          </FormGroup>
          <FormGroup>
            <Label for="duration">Duration (in minutes)</Label>
            <Input
              type="number"
              id="duration"
              value={duration}
              onChange={e => setDuration(e.target.value)}
              onInput={() => setDurationError('')}
              invalid={!!durationError}
              required
            />
            {durationError && <FormFeedback>{durationError}</FormFeedback>}
          </FormGroup>
          <Label for="Guests">Guests</Label>
          <ReactSelect
            options={users}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={true}
            components={{
              Option,
            }}
            onChange={handleMeetingGuests}
            // allowSelectAll={true}
            // value={this.state.optionSelected}
          />
          {userError && <div className="text-danger">{userError}</div>}
          <Button type="submit" color="primary">
            Submit
          </Button>
        </Form>
      ) : (
        'Meeting Created Successfully.'
      )}
    </>
  );
};
export default ConnectionMeetingForm;
