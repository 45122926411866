import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText, FormGroup, Label, Input, CloseButton, Spinner } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { ITopic } from 'app/shared/model/topic.model';
import { getEntities as getTopics } from 'app/entities/topic/topic.reducer';
import { IUser } from 'app/shared/model/user.model';
import { getUsers } from 'app/shared/reducers/user-management';
import { IInspiration } from 'app/shared/model/inspiration.model';
import { getEntity, updateEntity, reset, createEntityinspirations } from 'app/entities/inspiration/inspiration.reducer';
import axios from 'axios';

export const InspirationUpdate = props => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const id = props.inspirationId;
  const isNew = id === null;
  const topics = useAppSelector(state => state.topic.entities);
  const users = useAppSelector(state => state.userManagement.users);
  const inspirationEntityData = useAppSelector(state => state.inspiration.entity);
  //const inspirationEntity = useAppSelector(state => state.inspiration.entity);
  const [inspirationEntity, setInspirationEntity] = useState<IInspiration | null>(null);
  const loading = useAppSelector(state => state.inspiration.loading);
  const [error, setError] = useState('');
  const [inspiration, setInspiration] = useState('');
  const [apiError, setApiError] = useState('');
  const updating = useAppSelector(state => state.inspiration.updating);
  const updateSuccess = useAppSelector(state => state.inspiration.updateSuccess);
  const [imagePreview, setImagePreview] = useState('');
  const [uploadedImage, setUploadedImage] = useState(null);
  const baseImageUrl = '/image';
  const account = useAppSelector(state => state.authentication.account);
  const userid = account.id;
  const [inspirationTopics, setInspirationTopics] = useState([]);
  const [allTopics, setAllTopics] = useState([]);
  const [imageName, setImageName] = useState(null);
  const handleClose = () => {
    props.handleSubmit();
  };
  useEffect(() => {
    if (isNew) {
    } else {
      if (Array.isArray(inspirationEntityData)) {
        const inspirationEntityArray = inspirationEntityData.filter(item => item.id === id) as IInspiration[];
        if (inspirationEntityArray.length > 0) {
          const inspirationEntityItem = inspirationEntityArray[0];
          setInspirationEntity(inspirationEntityItem);
          // dispatch(getEntity(id));
        } else {
        }
      } else {
      }
    }
  }, [isNew, id, inspirationEntityData]);

  useEffect(() => {
    dispatch(getTopics({ page: 0, size: 100, sort: 'id,asc' }));
    dispatch(getUsers({}));
  }, []);
  useEffect(() => {
    if (topics) {
      setAllTopics(topics);
    }
  }, [topics]);
  useEffect(() => {
    if (inspirationEntity?.imageName) {
      setImagePreview(`${baseImageUrl}/${inspirationEntity?.imageName}`);
      setImageName(inspirationEntity?.imageName);
    }
    if (inspirationEntity?.description) {
      setInspiration(inspirationEntity?.description);
    }
    if (isNew) {
    } else {
      if (inspirationEntity && inspirationEntity.topics) {
        const selectedTopicNames = inspirationEntity.topics.split(', ').map(topic => topic.trim());
        //console.log(selectedTopicNames, 'selectedTopicNames');
        let selectedTopics = [];
        if (selectedTopicNames.length === 1) {
          const singleSelectedTopic = allTopics.find(topic => topic.name === selectedTopicNames[0]);
          selectedTopics = singleSelectedTopic ? [{ topic: { id: singleSelectedTopic.id, name: singleSelectedTopic.name } }] : [];
        } else {
          selectedTopics = allTopics
            .filter(topic => selectedTopicNames.includes(topic.name))
            .map(topic => ({ topic: { id: topic.id, name: topic.name } }));
        }
        setInspirationTopics(selectedTopics);
        //console.log(selectedTopics, 'selectedTopics');
      }
    }
  }, [inspirationEntity, allTopics]);
  const handleFileInputChange = event => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      // Check if the file size is greater than 10MB
      if (selectedFile.size > 10 * 1024 * 1024) {
        setError('File size should be less than 10MB');
        return;
      }

      const formData = new FormData();
      formData.append('image', selectedFile);

      axios
        .post('/api/upload', formData)
        .then(response => {
          const fullImageUrl = `${baseImageUrl}/${response.data}`;
          setImagePreview(fullImageUrl);
          setImageName(response.data);
          setUploadedImage(response.data);
          setError(''); // Clear any previous error
        })
        .catch(error => {
          console.error('Error uploading image:', error);
          setError('Error uploading image. Please try again.'); // Set an appropriate error message
        });
    }
  };
  const handleRemoveImage = () => {
    setImagePreview('');
    setUploadedImage(null);
    setImageName('');
  };
  const saveEntity = async values => {
    values.createdDate = convertDateTimeToServer(values.createdDate);
    values.modifiedDate = convertDateTimeToServer(values.modifiedDate);
    const topicNamesArray = inspirationTopics.map(topicObj => topicObj.topic.name);
    const topicsString = topicNamesArray.join(', ');

    const entity = {
      ...inspirationEntity,
      ...values,
      imageName: imageName != '' ? imageName || inspirationEntity?.imageName : null,
      topics: topicsString,
      user: { id: userid },
      approved: false,
    };
    if (isNew) {
      const resultAction = await dispatch(createEntityinspirations(entity));
      if (createEntityinspirations.fulfilled.match(resultAction)) {
        handleClose();
      } else if (createEntityinspirations.rejected.match(resultAction)) {
        const errorMessage = resultAction.payload as string;
        if (errorMessage) {
          setApiError(errorMessage);
        }
      }
    } else {
      await dispatch(updateEntity(entity));
      handleClose();
    }
  };
  const handleInspirationChange = value => {
    if (value.split(' ').length <= 50) {
      setInspiration(value);
      setError('');
    } else {
      setError('Only 50 words are allowed in inspiration');
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          createdDate: new Date(),
          modifiedDate: displayDefaultDateTime(),
        }
      : {
          ...inspirationEntity,
          description: inspiration,
          modifiedDate: convertDateTimeFromServer(new Date()),
          imageName: imageName != '' ? imageName || inspirationEntity?.imageName : null,
          // topic: inspirationEntity?.topic?.id,
          user: inspirationEntity?.user?.id,
        };
  return (
    <div className="card meeting-border edit-create-pages">
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="fivePlusApp.inspiration.home.createOrEditLabel" data-cy="InspirationCreateUpdateHeading">
            <label>Create or edit an Inspiration</label>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <Spinner className="body-loader-icon"></Spinner>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              <ValidatedField
                label={translate('fivePlusApp.inspiration.description')}
                id="inspiration-description"
                name="description"
                data-cy="description"
                type="textarea"
                value={inspiration}
                onChange={e => handleInspirationChange(e.target.value)}
                placeholder={`Enter your inspiration (max 50 words)`}
                required
              />
              {error && <div className="text-danger">{error}</div>}
              <ValidatedField
                id="images-file"
                name="imageName"
                data-cy="imageName"
                type="file"
                label={'Choose File'}
                className="col-12 col-md-6 col-lg-4 position-raelative"
                onChange={handleFileInputChange}
              />
              {!isNew ? (
                <Col md={12}>
                  {imageName ? (
                    <div className="remove-img-btn">
                      {<CloseButton onClick={handleRemoveImage} />}
                      <img className="img-thumbnail mb-4 p-0" src={`${baseImageUrl}/${imageName}`} />
                    </div>
                  ) : null}
                </Col>
              ) : imagePreview ? (
                <Col md={12}>
                  <div className="remove-img-btn">{<CloseButton onClick={handleRemoveImage} />}</div>
                  <img className="img-thumbnail aaa mb-4 p-0" src={imagePreview} alt="Image Preview" />
                  {/* <button onClick={handleRemoveImage}>Remove</button> */}
                </Col>
              ) : null}
              <div className="align-items-center d-flex form-title pb-1">
                <h3 className="pt-3">Topics</h3> <hr className="w-100 m-0 ms-3" />
              </div>
              <div id="topic-ques">
                <ul className="d-flex">
                  {topics.map((topic, index) => (
                    <li key={topic.id}>
                      <div className="topic-checkbox">
                        <input
                          type="checkbox"
                          id={`topic-${topic.id}`}
                          name={`topic-${topic.id}`}
                          value={topic.name}
                          checked={inspirationTopics.some(t => t && t.topic && t.topic.id === topic.id)}
                          onChange={e => {
                            if (e.target.checked) {
                              setInspirationTopics([...inspirationTopics, { topic: { id: topic.id, name: topic.name } }]);
                            } else {
                              setInspirationTopics(inspirationTopics.filter(t => t.topic.id !== topic.id));
                            }
                          }}
                        />
                        <label htmlFor={`topic-${topic.id}`}>{topic.name}</label>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <ValidatedField
                label={translate('fivePlusApp.inspiration.link')}
                id="inspiration-link"
                name="link"
                data-cy="link"
                type="text"
                validate={{
                  pattern: {
                    value: /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/,
                    message: 'Please enter a valid URL',
                  },
                }}
              />
              {/* <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/inspiration" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
        
              </Button> */}
              {apiError && <div className="text-danger">{apiError}</div>}
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Submit</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default InspirationUpdate;
