import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Loadable from 'react-loadable';

import LoginRedirect from 'app/modules/login/login-redirect';
import Logout from 'app/modules/login/logout';
//import Home from 'app/modules/home/home';
import Home from 'app/views/home/home';
import CheckAuthCode from 'app/views/home/checkauthcode';
import EntitiesRoutes from 'app/entities/routes';
import PrivateRoute from 'app/shared/auth/private-route';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import PageNotFound from 'app/shared/error/page-not-found';
import { AUTHORITIES } from 'app/config/constants';
import { Card } from 'reactstrap';
import NewsFeedPage from './views/user_profile/login/newsFeed';
import AdminProfile from './views/user_profile/login/AdminProfile';
import UsersAdmin from './views/user_profile/login/UsersAdmin';
import Topic from './views/user_profile/login/topic/topic';
import TopicRoutes from './views/user_profile/login/topic';
import UserOnBoradForm from './views/user_profile/login/UserOnboardForm';

const loading = <div>loading ...</div>;

const Admin = Loadable({
  loader: () => import(/* webpackChunkName: "administration" */ 'app/modules/administration'),
  loading: () => loading,
});

const AppRoutes = () => {
  return (
    <div className="view-routes">
      <ErrorBoundaryRoutes>
        <Route
          path="/"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.USER]}>
              <UserOnBoradForm />
            </PrivateRoute>
          }
        />
        <Route path="logout" element={<Logout />} />
        <Route
          path="admin/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN]}>
              <>
                <Admin />
                <Routes>
                  <Route index element={<Admin />} />
                  <Route path="admindashboard" element={<AdminProfile />} />
                  <Route path="users" element={<AdminProfile />} />
                  <Route path="newsfeed" element={<AdminProfile />} />
                  <Route path="inspirations" element={<AdminProfile />} />
                  <Route path="topic/*" element={<AdminProfile />} />
                  <Route path="point-details/*" element={<AdminProfile />} />
                </Routes>
              </>
            </PrivateRoute>
          }
        />
        <Route path="oauth2/authorization/oidc" element={<LoginRedirect />} />
        <Route path="get_token" element={<CheckAuthCode />} />

        <Route
          path="*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.USER]}>
              <EntitiesRoutes />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<PageNotFound />} />
      </ErrorBoundaryRoutes>
    </div>
  );
};

export default AppRoutes;
