import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IConnection, defaultValue } from 'app/shared/model/connection.model';

const initialState: EntityState<IConnection> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/connections';
const filterApiUrl = 'api/connection';
const searchApiUrl = 'api/search/user';
// Actions

export const getEntities = createAsyncThunk('connection/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}`;
  return axios.get<IConnection[]>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'connection/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IConnection>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);
export const getEntityFilterConnections = createAsyncThunk<IConnection, [string | number, string], {}>(
  'connection/fetch_entity_user_connection',
  async ([name, filterType]) => {
    const requestUrl = `${filterApiUrl}?type=${name}&filter=${filterType}`;
    // const response = await axios.get<IConnection>(requestUrl);
    // return response.data;
    try {
      const response = await axios.get<IConnection>(requestUrl);
      return response.data;
    } catch (error) {
      // Handle the error and return it with `rejectWithValue`

      if (error.response && error.response.status === 400) {
        return {};
      }

      throw error;
    }
  },
  { serializeError: serializeAxiosError }
);

// export const getEntitySearchConnections = createAsyncThunk<IConnection, [string | number, string], {}>(
//   'connection/fetch_entity_search_connection',
//   async ([name, userType]) => {
//     const requestUrl = `${searchApiUrl}?name=${name}&filter=${userType}`;
//     // const response = await axios.get<IConnection>(requestUrl);
//     // return response.data;
//     try {
//       const response = await axios.get<IConnection>(requestUrl);
//       return response.data;
//     } catch (error) {
//       // Handle the error and return it with `rejectWithValue`

//       if (error.response && error.response.status === 404) {
//         return error.response.data;
//       }
//       throw error;
//     }
//   },
//   { serializeError: serializeAxiosError }
// );

export const createEntity = createAsyncThunk(
  'connection/create_entity',
  async (entity: IConnection, thunkAPI) => {
    const result = await axios.post<IConnection>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);
export const createEntityConnection = createAsyncThunk(
  'connection/create_entity_connection',
  async (entity: IConnection, thunkAPI) => {
    const result = await axios.post<IConnection>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'connection/update_entity',
  async (entity: IConnection, thunkAPI) => {
    const result = await axios.put<IConnection>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);
export const updateEntityConnection = createAsyncThunk(
  'connection/update_entity_connection',
  async (entity: IConnection, thunkAPI) => {
    const result = await axios.patch<IConnection>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'connection/partial_update_entity',
  async (entity: IConnection, thunkAPI) => {
    const result = await axios.patch<IConnection>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'connection/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IConnection>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);
export const deleteEntityConnection = createAsyncThunk(
  'connection/delete_entity_connection',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IConnection>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const ConnectionSlice = createEntitySlice({
  name: 'connection',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getEntityFilterConnections.pending, state => {
        state.loading = true;
      })
      .addCase(getEntityFilterConnections.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload;
      })
      .addCase(getEntityFilterConnections.rejected, state => {
        state.loading = false;
        //state.errorMessage =action.payload as string;
        //console.log('Error:');
        // Handle the rejected case if needed
      })
      // .addCase(getEntitySearchConnections.pending, state => {
      //   state.loading = true;
      // })
      // .addCase(getEntitySearchConnections.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.entity = action.payload;
      // })
      // .addCase(getEntitySearchConnections.rejected, state => {
      //   state.loading = false;
      //   //state.errorMessage =action.payload as string;
      //  // console.log('Error:');
      //   // Handle the rejected case if needed
      // })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      // .addCase(deleteEntityConnection.fulfilled, state => {
      //   state.updating = false;
      //   state.updateSuccess = true;
      //   state.entity = {};
      // })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = ConnectionSlice.actions;

// Reducer
export default ConnectionSlice.reducer;
