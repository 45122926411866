import './footer.scss';

import React from 'react';
import { Translate } from 'react-jhipster';
import { Col, Input, Nav, NavItem, NavLink, Row } from 'reactstrap';

const Footer = () => (
  <div className="footer page-content container-fluid">
    {/* <Row xs="3">
      <Col className="bg-light border">
        <img className="footer-logo" src="content/images/header-logo.svg" alt="Logo" />
        <div className="">
          You could use this section to narrate a short and crisp description for the Title/Page. This is for when you use section title.
        </div>
      </Col>
      <Col className="bg-light border">
        NEWSLETTER
        <div className="">Subscribe for daily inspiration & learning</div>
        <div>
          <Input />
        </div>
      </Col>
      <Col className="bg-light border">
        Contact Us
        <a href="mailto:info@5plus.com">info@5plus.com</a>
      </Col>
    </Row> */}
    <Row>
      <Col md="6">
        <Nav>
          <NavItem>
            <NavLink active href="https://5plus.us/terms-and-conditions/">
              Privacy Policy
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="https://5plus.us/cookie-policy/">Cookie Policy</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="https://5plus.us/copyright-policy/">Copyright Policy</NavLink>
          </NavItem>
        </Nav>
      </Col>
      <Col md="6" className="footer-copyright">
        <span>© 2023, 5+. All rights reserved.</span>
      </Col>
    </Row>
  </div>
);

export default Footer;
