import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IMeeting, defaultValue } from 'app/shared/model/meetinginfo.model';

const initialState: EntityState<IMeeting> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const meetingDataUrl = 'api/get/meetings/information';

// Actions

export const getEntityMeetingDetails = createAsyncThunk(
  'meeting/fetch_entity_meeting_details',
  async ({ page, size }: IQueryParams) => {
    const requestUrl = `${meetingDataUrl}?page=${page}&size=${size}`;
    return axios.get<IMeeting>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

// slice

export const MeetingInfoSlice = createEntitySlice({
  name: 'meetinginfo',
  initialState,
  extraReducers(builder) {
    builder

      .addCase(getEntityMeetingDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })

      .addMatcher(isPending(getEntityMeetingDetails), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      });
  },
});

export const { reset } = MeetingInfoSlice.actions;

// Reducer
export default MeetingInfoSlice.reducer;
