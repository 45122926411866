import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import PointAllocationSystem from './point-allocation-system';
import PointAllocationSystemDetail from './point-allocation-system-detail';
import PointAllocationSystemUpdate from './point-allocation-system-update';
import PointAllocationSystemDeleteDialog from './point-allocation-system-delete-dialog';

const PointAllocationSystemRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<PointAllocationSystem />} />
    <Route path="new" element={<PointAllocationSystemUpdate />} />
    <Route path=":id">
      <Route index element={<PointAllocationSystemDetail />} />
      <Route path="edit" element={<PointAllocationSystemUpdate />} />
      <Route path="delete" element={<PointAllocationSystemDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default PointAllocationSystemRoutes;
