import { IPointDetails } from 'app/shared/model/point-details.model';
import { IUserAchievements } from 'app/shared/model/user-achievements.model';

export interface IAchievements {
  id?: number;
  name?: string | null;
  description?: string | null;
  feature?: boolean | null;
  requiredProof?: boolean | null;
  pointDetails?: IPointDetails | null;
  userAchievements?: IUserAchievements[] | null;
}

export const defaultValue: Readonly<IAchievements> = {
  feature: false,
  requiredProof: false,
};
