import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IConnection, defaultValue } from 'app/shared/model/connectionmeeting.model';

const initialState: EntityState<IConnection> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const filterApiUrl = 'api/connection';
// Actions

export const getEntityFilterConnectionsmeeting = createAsyncThunk<IConnection, [string | number, string], {}>(
  'connectionmeeting/fetch_entity_user_connection',
  async ([name, filterType]) => {
    const requestUrl = `${filterApiUrl}?type=${name}&filter=${filterType}`;
    // const response = await axios.get<IConnection>(requestUrl);
    // return response.data;
    try {
      const response = await axios.get<IConnection>(requestUrl);
      return response.data;
    } catch (error) {
      // Handle the error and return it with `rejectWithValue`

      if (error.response && error.response.status === 400) {
        return {};
      }
      throw error;
    }
  },
  { serializeError: serializeAxiosError }
);

// slice

export const ConnectionMeetingSlice = createEntitySlice({
  name: 'connectionmeeting',
  initialState,
  extraReducers(builder) {
    builder

      .addCase(getEntityFilterConnectionsmeeting.pending, state => {
        state.loading = true;
      })
      .addCase(getEntityFilterConnectionsmeeting.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload;
      })
      .addCase(getEntityFilterConnectionsmeeting.rejected, state => {
        state.loading = false;
        //state.errorMessage =action.payload as string;
        //console.log('Error:');
        // Handle the rejected case if needed
      });
  },
});

export const { reset } = ConnectionMeetingSlice.actions;

// Reducer
export default ConnectionMeetingSlice.reducer;
