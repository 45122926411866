import { IAchivements } from 'app/shared/model/achivements.model';
import { PointAllocationTypes } from 'app/shared/model/enumerations/point-allocation-types.model';

export interface IPointAllocationSystem {
  id?: number;
  pointType?: PointAllocationTypes | null;
  userPoint?: number | null;
  achivements?: IAchivements | null;
}

export const defaultValue: Readonly<IPointAllocationSystem> = {};
