import React, { useEffect, useState } from 'react';
import FiltersDropdown from './FiltersDropdown';
import { createEntityConnection, getEntityFilterConnections } from 'app/entities/connection/connection.reducer';
import { getEntitySearchConnections } from 'app/entities/search/search.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Button, Card, CardBody, CardImg, CardSubtitle, CardTitle, Col, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap';
import { getEntityProfileDetails } from 'app/entities/user-profile/user-profile.reducer';

const SearchConnection = () => {
  const [name, setName] = useState('');
  const [userType, setUserType] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [disabledConnections, setDisabledConnections] = useState([]);
  const connectionEntity = useAppSelector(state => state.connection.entity);
  const searchEntity = useAppSelector(state => state.search.entity);
  const [isRequestSent, setIsRequestSent] = useState(false);
  const userProfileEntity = useAppSelector(state => state.userProfile.entity);
  const account = useAppSelector(state => state.authentication.account);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const baseImageUrlProfile = '/profile/get/image';

  //const loggedIn_id = account.id;
  const filterOptions = [
    { name: 'All', value: '' },
    { name: 'Mentor', value: 'mentor' },
    { name: 'Mentee', value: 'mentee' },
  ];

  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };
  const [isMentee, setIsMentee] = useState(false);

  const id = account.id;
  const handleUserTypeChange = (selectedOption: string) => {
    //console.log('Selected option:', selectedOption);
    setUserType(selectedOption);
    if (name.length > 3) {
      // setLoading(true);
      dispatch(getEntitySearchConnections([name, selectedOption]));
    }
  };
  const handleSearch = async value => {
    if (value.length > 3) {
      setLoading(true);
      setName(value);
      await dispatch(getEntitySearchConnections([value, userType]));
    } else {
      setSearchResults([]);
    }
    setLoading(false);
  };
  useEffect(() => {
    dispatch(getEntityProfileDetails(id));
  }, []);
  //console.log('profiledetails', userProfileEntity);
  useEffect(() => {
    if (name != '') {
      setSearchResults(searchEntity);
    }
    //console.log("Hi There !!",connectionEntity);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [searchEntity]);

  const handleDeleteButtonClick = id => {
    //dispatch(deleteEntityConnection(id));
  };
  const handleSendRequedstButtonClick = async (id, email) => {
    const isMentee = userProfileEntity[0]?.isMentor ? false : true;
    setIsMentee(isMentee);
    console.log(isMentee, 'isMentee');
    const entity = {
      status: 'requested',
      userMentor: {
        id: id,
        login: email,
      },
      userMentee: {
        id: account.id,
        login: account.email,
      },
      topics: [],
      questions: [],
    };
    if (isMentee) {
      if (!isRequestSent) {
        setIsRequestSent(true);
        await dispatch(createEntityConnection(entity));
        await dispatch(getEntitySearchConnections([name, userType]));
        await dispatch(getEntityFilterConnections(['connection', 'all']));
        setIsRequestSent(false);
        setDisabledConnections(prevConnections => [...prevConnections, id]);
      }
    } else {
      setModal(true);
    }
  };
  const isConnectionDisabled = userId => {
    return disabledConnections.includes(userId);
  };

  return (
    <div className="container">
      <Row>
        <Col md="12">
          <div className="search-box-section">
            <div className="search-box d-flex justify-content-center">
              <input
                type="text"
                placeholder="Search"
                // value={name}
                onChange={e => handleSearch(e.target.value)}
              />
              <FiltersDropdown options={filterOptions} onChange={handleUserTypeChange} />
            </div>
            {/* searchResults && searchResults.length > 0 ?createEntityConnection */}
            {Array.isArray(searchResults)
              ? searchResults?.map((item, index) => (
                  <Card className="p-0 mb-2 connection-card " key={index}>
                    <CardBody className="d-flex justify-content-between">
                      <div className="d-flex flex-row align-items-center">
                        <div className="me-3 connection-avtar">
                          <CardImg className="rounded-circle" src={`${baseImageUrlProfile}/${item.profilePic}`} />
                          <div className={` ${item.isMentor ? 'search-mentor-profile' : 'search-mentee-profile'}`}>
                            <div className="ribbon-corner">{item.isMentor ? 'Mentor' : 'Mentee'}</div>
                          </div>
                        </div>
                        <div className="user-name-post">
                          <CardTitle tag="h5">{item.name}</CardTitle>
                          <CardSubtitle className="mb-2 text-muted" tag="h6">
                            {item.occupation}
                          </CardSubtitle>
                        </div>
                      </div>
                      <div className="d-flex flex-row align-items-center">
                        {item.userId === id ? (
                          <div>
                            <Button className="pending-request-btn">
                              <a href={`/user-profile/${id}`} rel="noopener noreferrer">
                                View Profile
                              </a>
                            </Button>
                          </div>
                        ) : item.status === 'requested' || item.status === 'accepted' ? (
                          <Button className="pending-request-btn">
                            <a href={`/user-profile/${item.userId}`} target="_blank" rel="noopener noreferrer">
                              View Profile
                            </a>
                          </Button>
                        ) : (
                          <Button
                            className="pending-request-btn"
                            onClick={() => handleSendRequedstButtonClick(item.userId, item.email)}
                            disabled={isConnectionDisabled(item.userId)} // Disable the button if the request is sent
                          >
                            {isConnectionDisabled(item.userId) ? 'Request Sent' : 'Send Request'}
                          </Button>
                        )}

                        <Modal className="send-request-popup" isOpen={modal} toggle={toggleModal}>
                          <ModalHeader toggle={toggleModal}></ModalHeader>
                          <ModalBody>
                            Switch to Mentee profile to send Request
                            <div>
                              <Button className="pending-request-btn">
                                <a href={`/user-profile/${id}`}>View Profile</a>
                              </Button>{' '}
                            </div>
                          </ModalBody>
                        </Modal>
                        {/* {item.status=='accepted'? <Button onClick={() => handleDeleteButtonClick(item.connectionId)}>Cancel Request</Button> :<Button onClick={() => handleAcceptButtonClick(item.connectionId)}>Accept</Button>}  */}
                      </div>
                    </CardBody>
                  </Card>
                ))
              : name != '' && (
                  <div className="no-data-found">
                    <img src="content/images/no-data.gif" />
                    Information Not Available
                  </div>
                )}
          </div>
        </Col>
        {loading ? (
          <div className="loader_custom">
            <Spinner>Loading...</Spinner>
          </div>
        ) : (
          ''
        )}
      </Row>
    </div>
  );
};

export default SearchConnection;
