import React, { useState, ComponentProps } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import { Tooltip as ReactTooltip } from 'react-tooltip';

type Props = {
  data: ComponentProps<typeof PieChart>['data'];
  logoUrl: string;
};

function FullOption(props: Props) {
  const [selected, setSelected] = useState<number | undefined>(0);
  const [hovered, setHovered] = useState<number | undefined>(undefined);

  const data = props.data.map((entry, i) => {
    if (hovered === i) {
      return {
        ...entry,
        color: 'grey',
      };
    }
    return entry;
  });

  const lineWidth = 60;
  const defaultRadius = 50;

  return (
    <div style={{ position: 'relative' }}>
      <svg width="100%" height="100%" viewBox="0 0 300 300">
        <PieChart
          style={{
            fontFamily: '"Nunito Sans", -apple-system, Helvetica, Arial, sans-serif',
            fontSize: '6px',
          }}
          data={data}
          radius={defaultRadius - 6}
          lineWidth={60}
          segmentsStyle={{ transition: 'stroke .3s', cursor: 'pointer' }}
          segmentsShift={index => (index === hovered ? 6 : 1)}
          animate
          label={({ dataEntry }) => Math.round(dataEntry.percentage) + '%'}
          labelPosition={100 - lineWidth / 2}
          labelStyle={{
            fill: '#fff',
            opacity: 0.75,
            pointerEvents: 'none',
          }}
          onClick={(_, index) => {
            setSelected(index === selected ? undefined : index);
          }}
          onMouseOver={(_, index) => {
            setHovered(index);
          }}
          onMouseOut={() => {
            setHovered(undefined);
          }}
        />
        {/* Centered logo */}
        <foreignObject x="100" y="100" width="100" height="100">
          {/* Insert logo image */}
          <img src={props.logoUrl} alt="Logo" style={{ width: '100%', height: '100%' }} />
        </foreignObject>
      </svg>
      <ReactTooltip id="pie-chart-tooltip" place="right">
        {/* Use the 'content' prop to specify tooltip content */}
        {typeof hovered === 'number' && props.data[hovered] ? (
          <span>
            Sector {props.data[hovered].title}: {props.data[hovered].value}
          </span>
        ) : (
          ''
        )}
      </ReactTooltip>
    </div>
  );
}

export default FullOption;
