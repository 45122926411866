import { IUser } from 'app/shared/model/user.model';
import { IAchivements } from 'app/shared/model/achivements.model';

export interface IUserAchivements {
  id?: number;
  fileName?: string | null;
  user?: IUser | null;
  achivements?: IAchivements | null;
}

export const defaultValue: Readonly<IUserAchivements> = {};
