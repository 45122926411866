import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText, FormGroup, Label, Input, CloseButton, Spinner } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { ITopic } from 'app/shared/model/topic.model';
import { getEntities as getTopics } from 'app/entities/topic/topic.reducer';
import { IUser } from 'app/shared/model/user.model';
import { getUsers } from 'app/shared/reducers/user-management';
import { IInspiration } from 'app/shared/model/inspiration.model';
import { getEntity, updateEntity, reset, createEntitynewsFeed } from 'app/entities/news-feed/news-feed.reducer';
import axios from 'axios';
import { INewsFeed } from 'app/shared/model/news-feed.model';

export const NewsFeedUpdate = props => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const id = props.inspirationId;
  const isNew = id === null;
  const users = useAppSelector(state => state.userManagement.users);
  const newsFeedListData = useAppSelector(state => state.newsFeed.entity);
  const [newsFeedList, setNewsFeedList] = useState<INewsFeed | null>(null);
  const loading = useAppSelector(state => state.inspiration.loading);
  const [error, setError] = useState('');
  const [newsFeed, setNewsFeed] = useState('');
  const [apiError, setApiError] = useState('');
  const updating = useAppSelector(state => state.newsFeed.updating);
  const updateSuccess = useAppSelector(state => state.newsFeed.updateSuccess);
  const [mediaPreview, setMediaPreview] = useState('');
  const [uploadedMedia, setUploadedMedia] = useState(null);
  const baseImageUrl = '/image';
  const account = useAppSelector(state => state.authentication.account);
  const userid = account.id;
  const [imageName, setImageName] = useState(null);
  const handleClose = () => {
    props.handleSubmit();
  };

  useEffect(() => {
    dispatch(getTopics({}));
    dispatch(getUsers({}));
  }, []);
  useEffect(() => {
    if (isNew) {
    } else {
      if (Array.isArray(newsFeedListData)) {
        const newsFeedListDatayArray = newsFeedListData.filter(item => item.id === id) as IInspiration[];
        if (newsFeedListDatayArray.length > 0) {
          const newsFeedListDataItem = newsFeedListDatayArray[0];
          setNewsFeedList(newsFeedListDataItem);
          // dispatch(getEntity(id));
        } else {
        }
      } else {
      }
    }
  }, [isNew, id, newsFeedListData]);
  // useEffect(() => {
  //   if (updateSuccess) {
  //     handleClose();
  //   }
  // }, [updateSuccess]);
  useEffect(() => {
    if (newsFeedList?.imageName) {
      setMediaPreview(`${baseImageUrl}/${newsFeedList?.imageName}`);
      setImageName(newsFeedList?.imageName);
    }
    if (newsFeedList?.description) {
      setNewsFeed(newsFeedList.description);
    }
  }, [newsFeedList]);

  const handleMediaFileInputChange = event => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const formData = new FormData();
      formData.append('image', selectedFile);

      const uploadUrl = selectedFile.type.startsWith('video/') ? '/api/upload?type=video' : '/api/upload/';

      axios
        .post(uploadUrl, formData)
        .then(response => {
          const fullMediaUrl = `${baseImageUrl}/${response.data}`;
          setMediaPreview(fullMediaUrl);
          setImageName(response.data);
          setUploadedMedia(response.data);
          setError('');
        })
        .catch(error => {
          setError('Error uploading image/video. Please try again.');
          console.error('Error uploading media:', error);
        });
    }
  };

  const handleRemoveMedia = () => {
    setMediaPreview('');
    setUploadedMedia(null);
    setImageName('');
  };
  const saveEntity = async values => {
    values.createdDate = convertDateTimeToServer(values.createdDate);
    values.modifiedDate = convertDateTimeToServer(values.modifiedDate);
    const entity = {
      ...newsFeedList,
      ...values,
      imageName: imageName != '' ? imageName || newsFeedList?.imageName : null,
      //topics: topicsString,
      user: { id: userid },
    };

    if (isNew) {
      const resultAction = await dispatch(createEntitynewsFeed(entity));
      if (createEntitynewsFeed.fulfilled.match(resultAction)) {
        handleClose();
      } else if (createEntitynewsFeed.rejected.match(resultAction)) {
        const errorMessage = resultAction.payload as string;
        if (errorMessage) {
          setApiError(errorMessage);
        }
      }
    } else {
      await dispatch(updateEntity(entity));
      handleClose();
    }
  };
  const handleNewsChange = value => {
    if (value.split(' ').length <= 50) {
      setNewsFeed(value);
      setError('');
    } else {
      setError('Only 50 words are allowed in inspiration');
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          createdDate: new Date(),
          modifiedDate: displayDefaultDateTime(),
        }
      : {
          ...newsFeedList,
          description: newsFeed,
          modifiedDate: convertDateTimeFromServer(new Date()),
          imageName: imageName != '' ? imageName || newsFeedList?.imageName : null,
          user: newsFeedList?.user?.id,
        };
  return (
    <div className="card meeting-border edit-create-pages">
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="fivePlusApp.news.home.createOrEditLabel" data-cy="newsCreateUpdateHeading">
            <label>Add or Edit News</label>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <Spinner className="body-loader-icon"></Spinner>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              <ValidatedField
                label={'Description'}
                id="news-feed-description"
                name="description"
                data-cy="description"
                type="textarea"
                value={newsFeed}
                onChange={e => handleNewsChange(e.target.value)}
                placeholder={`Enter your news (max 50 words)`}
                required
              />
              {error && <div className="text-danger">{error}</div>}
              <ValidatedField
                id="media-file"
                name="mediaFile"
                data-cy="mediaFile"
                type="file"
                label={'Upload Image or Video'}
                className="col-12 col-md-6 col-lg-4 position-relative"
                onChange={handleMediaFileInputChange}
              />
              {mediaPreview && (
                <Col md={12}>
                  <div className="remove-media-btn">
                    <CloseButton onClick={handleRemoveMedia} />
                    {mediaPreview.endsWith('.mp4') ? (
                      <video controls className="mb-4 p-0">
                        <source src={mediaPreview} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <img className="img-thumbnail mb-4 p-0" src={mediaPreview} alt="Media Preview" />
                    )}
                  </div>
                </Col>
              )}
              {apiError && <div className="text-danger">{apiError}</div>}
              &nbsp;
              <ValidatedField
                label={translate('fivePlusApp.newsFeed.link')}
                id="news-feed-link"
                name="link"
                data-cy="link"
                type="text"
                validate={{
                  pattern: {
                    value: /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/,
                    message: 'Please enter a valid URL',
                  },
                }}
              />
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Submit</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default NewsFeedUpdate;
