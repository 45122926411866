import { IMentorTopic } from 'app/shared/model/mentor-topic.model';
import { IMenteeTopic } from 'app/shared/model/mentee-topic.model';

export interface ITopic {
  id?: number;
  name?: string;
  mentorTopics?: IMentorTopic[] | null;
  menteeTopics?: IMenteeTopic[] | null;
}

export const defaultValue: Readonly<ITopic> = {};
