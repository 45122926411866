import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';

export interface IUserLoginDetails {
  id?: number;
  lastLogin?: string | null;
  isActive?: boolean | null;
  user?: IUser | null;
}

export const defaultValue: Readonly<IUserLoginDetails> = {
  isActive: false,
};
