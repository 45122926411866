import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Achivements from './achivements';
import AchivementsDetail from './achivements-detail';
import AchivementsUpdate from './achivements-update';
import AchivementsDeleteDialog from './achivements-delete-dialog';

const AchivementsRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Achivements />} />
    <Route path="new" element={<AchivementsUpdate />} />
    <Route path=":id">
      <Route index element={<AchivementsDetail />} />
      <Route path="edit" element={<AchivementsUpdate />} />
      <Route path="delete" element={<AchivementsDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default AchivementsRoutes;
