import React, { useEffect, useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import classnames from 'classnames';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { useDispatch } from 'react-redux';
import { getEntityNotifications } from 'app/entities/notification/notification.reducer';
import { getEntityFilterConnections } from 'app/entities/connection/connection.reducer';
import { getEntityProfileDetails, getUserMentorMenteeCount } from 'app/entities/user-profile/user-profile.reducer';
import { getEntityTotalPoints } from 'app/entities/point-allocation/point-allocation.reducer';
import { getEntityMentorTopic } from 'app/entities/mentor-topic/mentor-topic.reducer';
import { getEntityMeetingDetails } from 'app/entities/meetinginfo/meetinginfo.reducer';
import { getEntityMenteeQuestions } from 'app/entities/question/question.reducer';
import { getEntityMenteeTopic } from 'app/entities/mentee-topic/mentee-topic.reducer';
import { getEntities, getEntityInspirations } from 'app/entities/inspiration/inspiration.reducer';
interface Props {
  tab1Content: React.ReactNode;
  tab2Content: React.ReactNode;
  tab3Content: React.ReactNode;
  tab4Content: React.ReactNode;
  tab5Content: React.ReactNode;
  currentActiveTab: React.ReactNode;
}

const Tabs: React.FC<Props> = ({ tab1Content, tab2Content, tab3Content, tab4Content, tab5Content, currentActiveTab }) => {
  const [activeTab, setActiveTab] = useState('profile');
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const account = useAppSelector(state => state.authentication.account);
  const id = account.id;
  //console.log(id, 'myiddd');
  useEffect(() => {
    if (currentActiveTab == 'inspirations') {
      setActiveTab('inspirations');
    }
    //console.log('currentActiveTab', currentActiveTab);
    // setInspirationData(inspirationEntity);
  }, [currentActiveTab]);
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };
  const handleOk = () => {
    setModalOpen(false);
    setActiveTab('earn');
  };
  const toggle = async (tab: string) => {
    setLoading(true);
    if (activeTab !== tab) setActiveTab(tab);

    if (tab === 'connections') {
      await dispatch(getEntityNotifications(id));
      await dispatch(getEntityFilterConnections(['connection', 'all'])); // Dispatch action for tab 1
    } else if (tab === 'profile') {
      await dispatch(getEntityNotifications(id));
      await dispatch(getEntityProfileDetails(id));
      await dispatch(getEntityTotalPoints());
      await dispatch(getEntityMentorTopic(id));
      await dispatch(getEntityMenteeQuestions(id));
      await dispatch(getEntityMenteeTopic(id));
      await dispatch(getUserMentorMenteeCount());
    } else if (tab === 'meetings') {
      await dispatch(getEntityNotifications(id));
      await dispatch(getEntityMeetingDetails({ page: 0, size: 20 }));
    } else if (tab === 'inspirations') {
      await dispatch(getEntityNotifications(id));
      await dispatch(
        getEntityInspirations({
          id: id,
          page: 0,
          size: 20,
          sort: 'id,desc',
        })
      );
    } else if (tab === 'inspirations') {
      await dispatch(getEntityNotifications(id));
      await dispatch(
        getEntityInspirations({
          id: id,
          page: 0,
          size: 10,
          sort: 'id,desc',
        })
      );
    } else if (tab === 'earn') {
      setModalOpen(true);
      return; // Exit the function to avoid setting loading to false immediately
    }
    setTimeout(() => {
      // console.log('loading');
      setLoading(false);
    }, 500);
  };

  return (
    <div className="wrapper">
      <Nav tabs>
        <NavItem>
          <NavLink className={classnames({ active: activeTab === 'connections' })} onClick={() => toggle('connections')}>
            <img src="content/images/MyConnections-icon.svg" alt="icon" /> Connections
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className={classnames({ active: activeTab === 'profile' })} onClick={() => toggle('profile')}>
            <img src="content/images/My-Profile-icon.svg" alt="icon" /> My Profile
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className={classnames({ active: activeTab === 'meetings' })} onClick={() => toggle('meetings')}>
            <img src="content/images/Meetings-icon.svg" alt="icon" /> Meetings
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className={classnames({ active: activeTab === 'inspirations' })} onClick={() => toggle('inspirations')}>
            <img src="content/images/Inspiration-icon.svg" alt="icon" /> Inspirations
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className={classnames({ active: activeTab === 'earn' })} onClick={() => toggle('earn')}>
            <img src="content/images/ChartDonut.svg" alt="icon" /> Manage Points
          </NavLink>
        </NavItem>
        {/* <NavItem>
          <NavLink
            className={classnames({ active: activeTab === 'tab3' })}
            onClick={() => toggle('tab3')}
          >
            Tab 3
          </NavLink>
        </NavItem> */}
      </Nav>
      {/* <TabContent activeTab={activeTab}>
        <TabPane tabId="connections">{tab1Content}</TabPane>
        <TabPane tabId="profile">{tab2Content}</TabPane>
        <TabPane tabId="meetings">{tab3Content}</TabPane>
        <TabPane tabId="inspirations">{tab4Content}</TabPane>
        <TabPane tabId="newsfaeed">{tab5Content}</TabPane>
      </TabContent> */}
      <TabContent activeTab={activeTab}>
        <TabPane tabId="connections">{activeTab === 'connections' && tab1Content}</TabPane>
        <TabPane tabId="profile">{activeTab === 'profile' && tab2Content}</TabPane>
        <TabPane tabId="meetings">{activeTab === 'meetings' && tab3Content}</TabPane>
        <TabPane tabId="inspirations">{activeTab === 'inspirations' && tab4Content}</TabPane>
        <TabPane tabId="earn">{activeTab === 'earn' && tab5Content}</TabPane>
      </TabContent>

      <Modal className="points-popup" isOpen={modalOpen} toggle={toggleModal}>
        <ModalBody>
          {/* Modal content here */}
          <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            <img src="content/images/header-logo.png" alt="Logo" style={{ maxWidth: '80px', marginBottom: '10px' }} />
            <h2>Introducing Points</h2>
          </div>
          <div className="points-popup-content">
            <div>
              <img src="content/images/learn-earn-icon.svg" alt="icon" />
            </div>
            <div>
              <h4> Learn + Earn:</h4>
              <p>As a mentee, you have the opportunity to learn and gain valuable knowledge.</p>
              <p>As a mentor, you can share your expertise and guide others in their learning journey.</p>
            </div>
          </div>
          <div className="points-popup-content">
            <div>
              <img src="content/images/buy-icon.svg" alt="icon" />
            </div>
            <div>
              <h4>Buy:</h4>
              <p>If you need to accumulate additional points, you can purchase more to top off your balance.</p>
            </div>
          </div>
          <div className="points-popup-content">
            <div>
              <img src="content/images/give-icon.svg" alt="icon" />
            </div>
            <div>
              <h4>Give:</h4>
              <p>Donate your points to support causes and initiatives you care about.</p>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleOk}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Tabs;
