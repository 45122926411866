import { IAchievements } from 'app/shared/model/achievements.model';
import { PointAllocationTypes } from 'app/shared/model/enumerations/point-allocation-types.model';

export interface IPointDetails {
  id?: number;
  pointType?: PointAllocationTypes | null;
  point?: number | null;
  achievements?: IAchievements | null;
}

export const defaultValue: Readonly<IPointDetails> = {};
