import React, { useEffect, useState } from 'react';
import { Form, FormGroup, Input, Button } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntityReferralcode } from 'app/entities/referral-code/referral-code.reducer';
import ReferralCopyButton from './ReferralCopyButton';
import MailtoButton from './RefferalMailtoButton';

const ReferralEmailForm = () => {
  const dispatch = useAppDispatch();
  const account = useAppSelector(state => state.authentication.account);
  const referralCodeEntity = useAppSelector(state => state.referralCode.entity);
  const [referralData, setReferralData] = useState('');

  const id = account.id;
  const [recipientEmails, setRecipientEmails] = useState('');
  const handleRecipientChange = event => {
    setRecipientEmails(event.target.value);
  };

  useEffect(() => {
    console.log(referralCodeEntity, 'referralCodeEntity');
    if (Object.keys(referralCodeEntity).length > 0) {
      setReferralData(referralCodeEntity.referralCode);
    }
  }, [referralCodeEntity]);
  const port = location.port ? `:${location.port}` : '';
  const referralURL = `https://${location.hostname}/?referralCode=${referralData}`;
  const handleSendEmail = () => {
    const subject = 'Invitation to join 5+';
    const body = `Please join this invitation to 5+ mentoring platform by using this referral link: ${referralURL}`;
    const mailtoUrl = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoUrl;
  };
  return (
    <div className="refer-popup">
      {referralData && (
        <>
          {/* <span><link to='/referal-code?${referralData}</span> Link */}
          <p>Share your referral URL:</p>
          <a href={referralURL}>{referralURL}</a>
          <ReferralCopyButton referallink={referralURL} />
          <button className="sent-mail-btn" onClick={handleSendEmail}>
            Send Email
          </button>
        </>
      )}
    </div>
  );
};

export default ReferralEmailForm;
