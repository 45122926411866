import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IUserProfile, defaultValue } from 'app/shared/model/user-profile.model';

const initialState: EntityState<IUserProfile> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/admin/get/profiles';
const topFiveusers = '/api/admin/top/five/users';

// Actions

export const getEntitiesProfile = createAsyncThunk('adminProfile/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}`;
  return axios.get<IUserProfile[]>(requestUrl);
});

export const getEntityAdminTopFive = createAsyncThunk(
  'adminProfile/fetch_entity_top_five_users',
  async () => {
    const requestUrl = `${topFiveusers}`;
    const result = await axios.get<IUserProfile>(requestUrl);
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'adminProfile/create_entity',
  async (entity: IUserProfile, thunkAPI) => {
    const result = await axios.post<IUserProfile>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntitiesProfile({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);
// slice

export const AdminProfileSlice = createEntitySlice({
  name: 'adminProfile',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntityAdminTopFive.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = Array.isArray(action.payload.data) ? action.payload.data : [action.payload.data];
      })
      .addCase(getEntitiesProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = Array.isArray(action.payload.data) ? action.payload.data : [action.payload.data];
      })
      .addMatcher(isFulfilled(getEntitiesProfile), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isPending(getEntitiesProfile, getEntityAdminTopFive), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      });
  },
});

export const { reset } = AdminProfileSlice.actions;

// Reducer
export default AdminProfileSlice.reducer;
