import { IUser } from 'app/shared/model/user.model';
import { IAchievements } from 'app/shared/model/achievements.model';

export interface IUserAchievements {
  id?: number;
  fileName?: string | null;
  user?: IUser | null;
  achievements?: IAchievements | null;
}

export const defaultValue: Readonly<IUserAchievements> = {};
