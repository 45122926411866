import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';

export interface INotification {
  id?: number;
  type?: string;
  message?: string;
  createdDate?: string | null;
  isRead?: boolean | null;
  userSender?: IUser | null;
  userReceiver?: IUser | null;
}

export const defaultValue: Readonly<INotification> = {
  isRead: false,
};
