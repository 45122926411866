import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import NewsFeed from './news-feed';
import NewsFeedDetail from './news-feed-detail';
import NewsFeedUpdate from './news-feed-update';
import NewsFeedDeleteDialog from './news-feed-delete-dialog';

const NewsFeedRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<NewsFeed />} />
    <Route path="new" element={<NewsFeedUpdate />} />
    <Route path=":id">
      <Route index element={<NewsFeedDetail />} />
      <Route path="edit" element={<NewsFeedUpdate />} />
      <Route path="delete" element={<NewsFeedDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default NewsFeedRoutes;
