import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IInspiration, defaultValue } from 'app/shared/model/inspiration.model';

const initialState: EntityState<IInspiration> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/inspirations';
const apiUrlAdmin = '/api/admin/inspirations';

// Actions

export const getEntities = createAsyncThunk('inspiration/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}`;
  return axios.get<IInspiration[]>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'inspiration/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IInspiration>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'inspiration/create_entity',
  async (entity: IInspiration, thunkAPI) => {
    const result = await axios.post<IInspiration>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);
export const createEntityinspirations = createAsyncThunk(
  'inspiration/create_entity_inspirations',
  async (entity: IInspiration, thunkAPI) => {
    try {
      const result = await axios.post<IInspiration>(apiUrl, cleanEntity(entity));
      thunkAPI.dispatch(getEntities({}));
      return result;
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const errorMessage = 'We apologize, but you can add only one inspiration every 24 hours.';
        return thunkAPI.rejectWithValue(errorMessage);
      }
      return thunkAPI.rejectWithValue('An error occurred while making the request.');
    }
  },
  { serializeError: serializeAxiosError }
);
export const getEntityInspirations = createAsyncThunk(
  'inspiration/fetch_entity_inspirations',
  async ({ id, page, size, sort }: { id: string | number; page: number; size: number; sort: string }) => {
    const requestUrl = `${apiUrl}/?userId.equals=${id}&page=${page}&size=${size}&sort=${sort}`;
    return axios.get<IInspiration>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);
export const getEntityInspirationsAdmin = createAsyncThunk(
  'inspiration/fetch_entity_inspirations_admin',

  async ({ page, size, sort }: { page: number; size: number; sort: string }) => {
    const requestUrl = `${apiUrlAdmin}?page=${page}&size=${size}&sort=${sort}`;
    return axios.get<IInspiration>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'inspiration/update_entity',
  async (entity: IInspiration, thunkAPI) => {
    const result = await axios.put<IInspiration>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'inspiration/partial_update_entity',
  async (entity: IInspiration, thunkAPI) => {
    const result = await axios.patch<IInspiration>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);
export const partialUpdateEntityInspiration = createAsyncThunk(
  'inspiration/partial_update_entityinspiration',
  async (entity: IInspiration, thunkAPI) => {
    const result = await axios.patch<IInspiration>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'inspiration/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IInspiration>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);
export const deleteInspirationEntity = createAsyncThunk(
  'inspiration/delete_inspiration_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IInspiration>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const InspirationSlice = createEntitySlice({
  name: 'inspiration',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getEntityInspirations.fulfilled, (state, action) => {
        const { data, headers } = action.payload;

        state.loading = false;
        state.entity = data; // Assuming that data is an array of inspirations
        state.totalItems = parseInt(headers['x-total-count'], 10);
      })
      // .addCase(getEntityInspirationsAdmin.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.entity = action.payload.data;
      // })
      .addCase(getEntityInspirationsAdmin.fulfilled, (state, action) => {
        const { data, headers } = action.payload;

        state.loading = false;
        state.entity = data; // Assuming that data is an array of inspirations
        state.totalItems = parseInt(headers['x-total-count'], 10);
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addCase(deleteInspirationEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, createEntityinspirations, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity, getEntityInspirations, getEntityInspirationsAdmin), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, createEntityinspirations, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = InspirationSlice.actions;

// Reducer
export default InspirationSlice.reducer;
