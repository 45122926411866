import React, { useEffect, useLayoutEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { logout } from 'app/shared/reducers/authentication';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
export const Logout = () => {
  //const logoutUrl = useAppSelector(state => state.authentication.logoutUrl);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  Cookies.remove('userName');
  useEffect(() => {
    Cookies.remove('userName');
    logoutFunc();
  }, []);

  // const logoutFunc = async()=>{
  //   await dispatch(logout());
  //   navigate('/');
  //   if (logoutUrl) {
  //     // navigate(logoutUrl, { replace: true });
  //     console.log("logoutUrl",logoutUrl);
  //     debugger
  //     window.location.href = logoutUrl;
  //     debugger

  //   }
  // }
  const logoutFunc = async () => {
    try {
      // Send POST request to your logout API
      const idToken = localStorage.getItem('id_token');
      const logourapiUrl = 'api/logout';
      const requestUrl = `${logourapiUrl}?idToken=${idToken}`;
      const response = await axios.post(requestUrl);
      const logoutUrl = response.data.logoutUrl;
      if (logoutUrl) {
        localStorage.removeItem('access_token');
        localStorage.removeItem('access_token_expiry');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('id_token');
        localStorage.removeItem('expiryFrom');
        window.location.href = logoutUrl; // Redirect to logout URL
      }
    } catch (error) {
      console.error('Error occurred during logout:', error);
      // Handle errors if needed
    }
  };

  return (
    <div className="p-5">
      <h4>Logged out successfully!</h4>
    </div>
  );
};

export default Logout;
