import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Connection from './connection';
import ConnectionDetail from './connection-detail';
import ConnectionUpdate from './connection-update';
import ConnectionDeleteDialog from './connection-delete-dialog';

const ConnectionRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Connection />} />
    <Route path="new" element={<ConnectionUpdate />} />
    <Route path=":id">
      <Route index element={<ConnectionDetail />} />
      <Route path="edit" element={<ConnectionUpdate />} />
      <Route path="delete" element={<ConnectionDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ConnectionRoutes;
