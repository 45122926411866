import { IUser } from 'app/shared/model/user.model';
import { ITopic } from 'app/shared/model/topic.model';

export interface IMentorTopic {
  id?: number;
  user?: IUser | null;
  topic?: ITopic | null;
}

export const defaultValue: Readonly<IMentorTopic> = {};
