import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IAchievements } from 'app/shared/model/achievements.model';
import { getEntities as getAchievements } from 'app/entities/achievements/achievements.reducer';
import { IPointDetails } from 'app/shared/model/point-details.model';
import { PointAllocationTypes } from 'app/shared/model/enumerations/point-allocation-types.model';
import { getEntity, updateEntity, createEntity, reset } from './point-details.reducer';

export const PointDetailsUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const achievements = useAppSelector(state => state.achievements.entities);
  const pointDetailsEntity = useAppSelector(state => state.pointDetails.entity);
  const loading = useAppSelector(state => state.pointDetails.loading);
  const updating = useAppSelector(state => state.pointDetails.updating);
  const updateSuccess = useAppSelector(state => state.pointDetails.updateSuccess);
  const pointAllocationTypesValues = Object.keys(PointAllocationTypes);

  const handleClose = () => {
    navigate('/admin/point-details' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getAchievements({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...pointDetailsEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          pointType: 'Referral',
          ...pointDetailsEntity,
        };

  return (
    <div className="card point-allocation-update-tab">
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="fivePlusApp.pointDetails.home.createOrEditLabel" data-cy="PointDetailsCreateUpdateHeading">
            <Translate contentKey="fivePlusApp.pointDetails.home.createOrEditLabel">Create or edit a PointDetails</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="point-details-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('fivePlusApp.pointDetails.pointType')}
                id="point-details-pointType"
                name="pointType"
                data-cy="pointType"
                type="select"
              >
                {pointAllocationTypesValues.map(pointAllocationTypes => (
                  <option value={pointAllocationTypes} key={pointAllocationTypes}>
                    {translate('fivePlusApp.PointAllocationTypes.' + pointAllocationTypes)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('fivePlusApp.pointDetails.point')}
                id="point-details-point"
                name="point"
                data-cy="point"
                type="text"
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/admin/point-details" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default PointDetailsUpdate;
