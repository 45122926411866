import React, { useEffect, useState } from 'react';
import { RouteProps, useLocation, Navigate } from 'react-router-dom';
import { Translate } from 'react-jhipster';

import { useAppSelector } from 'app/config/store';
import ErrorBoundary from 'app/shared/error/error-boundary';
import { Alert } from 'reactstrap';

interface IOwnProps extends RouteProps {
  hasAnyAuthorities?: string[];
  children: React.ReactNode;
}

export const PrivateRoute = ({ children, hasAnyAuthorities = [], ...rest }: IOwnProps) => {
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const sessionHasBeenFetched = useAppSelector(state => state.authentication.sessionHasBeenFetched);
  const account = useAppSelector(state => state.authentication.account);
  const accountlogin = useAppSelector(state => state.authentication);
  const isAuthorized = hasAnyAuthority(account.authorities, hasAnyAuthorities);
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState('');

  if (!children) {
    throw new Error(`A component needs to be specified for private route for path ${(rest as any).path}`);
  }

  // if (!sessionHasBeenFetched) {
  //   return (
  //     <div className="insufficient-authority">
  //       <div className="alert alert-danger">
  //         <Translate contentKey="error.http.403">You are not authorized to access this page.</Translate>
  //       </div>
  //     </div>
  //   );
  // }
  useEffect(() => {
    // console.log ('no need to come here')
    // console.log(accountlogin, 'accountlogin');
    if (accountlogin?.errorMessage === 'Request failed with status code 400') {
      setErrorMessage(
        'Your account is currently inactive. Please contact our system administrator at info@5plus.us to activate your account. Thank you!'
      );
    }
  }, []);

  if (accountlogin?.errorMessage === 'Request failed with status code 400') {
    return (
      errorMessage && (
        <Alert color="danger" className="mt-3 d-flex justify-content-center">
          {errorMessage}
        </Alert>
      )
    );
  }

  if (isAuthenticated) {
    if (isAuthorized) {
      return <ErrorBoundary>{children}</ErrorBoundary>;
    }

    return (
      <div className="insufficient-authority">
        <div className="alert alert-danger">
          <Translate contentKey="error.http.403">You are not authorized to access this page.</Translate>
        </div>
      </div>
    );
  }

  return (
    <Navigate
      to={{
        pathname: '/oauth2/authorization/oidc',
        search: location.search,
      }}
      replace
      state={{ from: location }}
    />
  );
};

export const hasAnyAuthority = (authorities: string[], hasAnyAuthorities: string[]) => {
  if (authorities && authorities.length !== 0) {
    if (hasAnyAuthorities.length === 0) {
      return true;
    }
    return hasAnyAuthorities.some(auth => authorities.includes(auth));
  }
  return false;
};

/**
 * Checks authentication before showing the children and redirects to the
 * login page if the user is not authenticated.
 * If hasAnyAuthorities is provided the authorization status is also
 * checked and an error message is shown if the user is not authorized.
 */
export default PrivateRoute;
