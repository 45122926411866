import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';

export interface INewsFeed {
  id?: number;
  description?: string | null;
  imageName?: string | null;
  link?: string | null;
  createdDate?: string | null;
  modifiedDate?: string | null;
  videoFile?: string | null;
  user?: IUser | null;
}

export const defaultValue: Readonly<INewsFeed> = {};
