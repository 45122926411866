import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';

export interface IPointAllocation {
  id?: number;
  point?: number | null;
  pointType?: string | null;
  timestamp?: string | null;
  sourceId?: string | null;
  user?: IUser | null;
  rewardPoints?: string;
}

export const defaultValue: Readonly<IPointAllocation> = {};
