import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Row, Col, FormText, FormGroup, Input, Label, Spinner, Alert } from 'reactstrap';
import { Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getUsers } from 'app/shared/reducers/user-management';
import { ITopic } from 'app/shared/model/topic.model';
import { getEntities as getTopics } from 'app/entities/topic/topic.reducer';
import { createEntity, reset, getEntityProfileDetails, updateEntityUserType } from 'app/entities/user-profile/user-profile.reducer';
import { createEntityMenteeQuestions } from 'app/entities/question/question.reducer';
import { createEntityMentorTopic } from 'app/entities/mentor-topic/mentor-topic.reducer';
import { createEntityMenteeTopic } from 'app/entities/mentee-topic/mentee-topic.reducer';
import { Country, State, City } from 'country-state-city';
import { ICountry, IState, ICity } from 'country-state-city';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import axios from 'axios';
export const UserOnBoradForm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const [showInputs, setShowInputs] = useState(false);
  const users = useAppSelector(state => state.userManagement.users);
  const topics = useAppSelector(state => state.topic.entities);
  const userProfileEntity = useAppSelector(state => state.userProfile.entity);
  const loading = useAppSelector(state => state.userProfile.loading);
  const [loadingMain, setLoadingMain] = useState(false);
  const [loadingMainAI, setLoadingMainAI] = useState(false);
  const updating = useAppSelector(state => state.userProfile.updating);
  const updateSuccess = useAppSelector(state => state.userProfile.updateSuccess);
  const account = useAppSelector(state => state.authentication.account);
  const [selectedTopics, setSelectedTopics] = useState<ITopic[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<ICountry | null>(null);
  const [selectedState, setSelectedState] = useState<IState | null>(null);
  const [selectedYear, setSelectedYear] = useState<number>();
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const id = account.id;
  const isNew = undefined;
  const [currentDecadeField, setCurrentDecadeField] = useState([]);
  const [loadingIndex, setLoadingIndex] = useState<number>();
  const [userloading, setUserloading] = useState(true);
  const [loadingForm, setLoadingForm] = useState(true);
  const baseImageUrl = '/profile/get/image';

  const [isMentee, setIsMentee] = useState(false);
  const [fields, setFields] = useState([
    { name: '', value: '' },
    { name: '', value: '' },
    { name: '', value: '' },
    { name: '', value: '' },
    { name: '', value: '' },
  ]);

  const startYear = 1923; // Change this value as per your requirement
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: currentYear - startYear + 1 }, (_, index) => startYear + index);
  useEffect(() => {
    dispatch(getEntityProfileDetails(id));
    const countries = Country.getAllCountries();
    setSelectedCountry(countries[0]);
  }, []);

  const handleCountryChange = (countryName: string) => {
    const newCountry = Country.getAllCountries().find(country => country.name === countryName);
    if (newCountry) {
      setSelectedCountry(newCountry);
      setSelectedState(null);
    }
  };
  const handleStateChange = (state: IState) => {
    setSelectedState(state);
  };
  const enhanceWithAIHandler = async (index, retries = 3) => {
    const updatedFields = [...currentDecadeField];
    updatedFields[index] = {
      ...updatedFields[index], // Maintain other properties
      loadingAI: true, // Update the value property
    };
    setCurrentDecadeField(updatedFields);
    try {
      const selectedField = currentDecadeField[index];
      const message = encodeURIComponent(selectedField.value || '');

      const response = await axios.get(`/api/generate/sentence`, {
        params: {
          message: message,
        },
      });

      const generatedSentence = response.data;

      // Update the state immutably
      const updatedFieldsAfterAPI = [...updatedFields];
      updatedFieldsAfterAPI[index] = {
        ...updatedFieldsAfterAPI[index],
        value: generatedSentence,
        loadingAI: false,
      };

      setCurrentDecadeField(updatedFieldsAfterAPI); // Update the state
    } catch (error) {
      console.error('An error occurred while processing the request:', error);
      // Retry logic
      if (retries > 0 && error.response?.status === 406) {
        console.log(`Retrying in 20 seconds... (${retries} retries left)`);
        setTimeout(() => {
          enhanceWithAIHandler(index, retries - 1);
        }, 20000); // Retry after 20 seconds
      } else {
        console.error('Max retries reached or other error occurred.');
      }
    }
  };
  const enhanceQuestionWithAI = async (index, retries = 3) => {
    setLoadingMainAI(true);
    try {
      const selectedQuestion = fields[index];
      const message = encodeURIComponent(selectedQuestion.value || '');

      const response = await axios.get(`/api/generate/question`, {
        params: {
          message: message,
        },
      });
      const generatedSentence = response.data;
      const updatedFields = [...fields];
      updatedFields[index].value = generatedSentence;
      setFields(updatedFields);
      setLoadingMainAI(false);
    } catch (error) {
      console.error('An error occurred while processing the request:', error);
      // Retry logic
      if (retries > 0 && error.response?.status === 406) {
        console.log(`Retrying in 20 seconds... (${retries} retries left)`);
        setTimeout(() => {
          enhanceQuestionWithAI(index, retries - 1);
        }, 20000); // Retry after 20 seconds
      } else {
        console.error('Max retries reached or other error occurred.');
      }
    }
  };
  useEffect(() => {
    if (userProfileEntity?.length > 0) {
      const userinfo = userProfileEntity[0];
      if (userinfo.year) {
        navigate('/user-profile/' + userinfo.user.id);
      }
    }
    const countries = Country.getAllCountries();
    setUserloading(false);
  }, [userProfileEntity, navigate]);
  const handleClose = () => {
    const userinfo_close = userProfileEntity;
    navigate('/user-profile/' + userinfo_close.user.id);
  };
  const handleQuesionsInputChange = (index, event) => {
    const newValue = event.target.value;
    const updatedFields = [...fields];
    updatedFields[index].value = newValue;
    updatedFields[index].name = `question_${index + 1}`;

    setFields(updatedFields);
  };
  const handleDesInputChange = (index, event) => {
    const newValue = event.target.value;
    const updatedFields = [...currentDecadeField];
    updatedFields[index].value = newValue;

    setCurrentDecadeField(updatedFields);
  };
  const handleYearChange = value => {
    const selectedYearcurrent = parseInt(value);
    value != '' ? setSelectedYear(selectedYearcurrent) : setSelectedYear(undefined);

    const currentYear = new Date().getFullYear();
    const age = currentYear - value;

    const fieldGroups = [];
    for (let decade = 20; decade <= age; decade += 10) {
      const fieldGroup = {
        decade,
        name: `selfDescription${decade}`,
        label: `List ${decade}'s Decade Experience`,
        type: 'textarea',
        loadingAI: false,
        enhanceWithAI: true,
      };
      fieldGroups.push(fieldGroup);
    }
    setCurrentDecadeField(fieldGroups);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
      setLoadingForm(false); // Set loadingForm to false once form initialization is done
    } else {
      dispatch(getEntityProfileDetails(id)).then(() => setLoadingForm(false)); // Set loadingForm to false after profile details are fetched
    }
    dispatch(getUsers({}));
    dispatch(getTopics({ page: 0, size: 100, sort: 'id,asc' }));
  }, [isNew, id, dispatch]);

  useEffect(() => {
    setShowInputs(isChecked);
  }, [isChecked]);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = async values => {
    const entity = {
      ...userProfileEntity,
      ...values,
      user: { id: account.id },
      questions: fields.map(field => field.value),
      isMentor: isMentee ? false : true,
      year: selectedYear,
      ...currentDecadeField.reduce((acc, { name, value }) => {
        acc[name] = value;
        return acc;
      }, {}),
    };
    selectedTopics.map(async (topic, index) => {
      const topics = {
        user: { id: account.id },
        topic: { id: topic.id, name: topic.name },
      };

      await dispatch(createEntityMentorTopic(topics));
    });
    setLoadingMain(true);
    await dispatch(createEntity(entity));
    setLoadingMain(false);
    if (isMentee) {
      if (fields.length > 0) {
        fields.map(async (item, index) => {
          const question = {
            user: { id: account.id },
            question: item.value,
          };
          //console.log(question, 'question');
          await dispatch(createEntityMenteeQuestions(question));
        });
      }
      if (selectedTopics.length > 0) {
        selectedTopics.map(async (topic, index) => {
          const topics = {
            user: { id: account.id },
            topic: { id: topic.id, name: topic.name },
          };
          await dispatch(createEntityMenteeTopic(topics));
        });
      }
    }
  };

  const defaultValues = () => {
    const test3 = isNew
      ? {}
      : {
          ...userProfileEntity,
          user: userProfileEntity?.user?.id,
          firstName: account.firstName,
          lastName: account.lastName,
          email: account.email,
          imageUrl: account.imageUrl,
          ...currentDecadeField.reduce((acc, { name, value }) => {
            acc[name] = value;
            return acc;
          }, {}),
        };
    return test3;
  };

  return (
    <div className="container card fiveplus-small-screen profile-page">
      {loading || userloading ? (
        <Spinner className="body-loader-icon"></Spinner>
      ) : (
        <Row>
          <Col md="12">
            <h1 className="main-title d-flex justify-content-center">
              {/* <Translate contentKey="home.title">Sign In With LikedIN!</Translate> */}
              Welcome To <img className="home-logo" src="content/images/header-logo.svg"></img>
            </h1>
            <p id="fivePlusApp.userProfile.home.createOrEditLabel" className="form-description d-flex justify-content-center">
              We need a little extra information to find the perfect mentor for you.
            </p>
          </Col>
          <Col md="12" className="user-information-form">
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity} className="row">
              {isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="user-profile-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                  className="col-4"
                />
              ) : null}
              <div className="user-avtar pt-4 d-flex justify-content-center">
                {account && !!account.imageUrl && <img src={`${baseImageUrl}/${account.imageUrl}`} className="bg-info rounded-circle " />}
              </div>{' '}
              <div className="col-12 onboard-switch mt-5 mb-5">
                <FormGroup switch className="tabs-switch">
                  <Input
                    type="switch"
                    checked={isMentee}
                    onClick={() => {
                      setIsMentee(!isMentee);
                      //dispatch(updateEntityUserType([id, isMentee]));
                    }}
                  />
                  <Label className="mentor-label">Mentor</Label>
                  <Label className="mentee-label">Mentee</Label>
                </FormGroup>
              </div>
              <ValidatedField
                label={'First Name'}
                id="user-profile-firstName"
                name="firstName"
                data-cy="firstName"
                type="text"
                className="col-4"
              />
              <ValidatedField
                label={'Last Name'}
                id="user-profile-lastName"
                name="lastName"
                data-cy="lastName"
                type="text"
                className="col-4"
              />
              <ValidatedField
                label={'Year Of Birth'}
                id="user-profile-year"
                name="year"
                data-cy="year"
                type="select"
                onChange={event => handleYearChange(event.target.value)}
                validate={{
                  required: { value: true, message: 'year is required' },
                }}
                errorMessage={{
                  required: 'year is required',
                }}
                className="col-4"
              >
                <option value="">Select a year</option>
                {years.map(year => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField label={'Email'} id="user-profile-email" name="email" data-cy="email" type="email" className="col-4" />
              <ValidatedField
                label={translate('fivePlusApp.userProfile.phoneNumber')}
                id="user-profile-phoneNumber"
                name="phoneNumber"
                data-cy="phoneNumber"
                type="text"
                className="col-4"
                validate={{ pattern: /^(\([0-9]{3}\)|[0-9]{3})-?[0-9]{3}-?[0-9]{4}$/ }}
                errorMessage={{
                  validate: 'Please enter a valid phone number',
                }}
              />
              <ValidatedField
                label={'Area of Expertise'}
                id="user-profile-occupation"
                name="occupation"
                data-cy="occupation"
                type="text"
                className="col-4"
              />
              <Col md="12" className="mb-2">
                <div className="align-items-center d-flex form-title pb-3">
                  Address <hr className="w-100 m-0 ms-3" />
                </div>
              </Col>
              <ValidatedField
                label={translate('fivePlusApp.userProfile.country')}
                id="user-profile-country"
                name="country"
                data-cy="country"
                type="select"
                className="col-4"
                value={selectedCountry?.name}
                // value={selectedCountry?.isoCode}
                onChange={e => {
                  const selectedCountryName = e.target.value;
                  handleCountryChange(selectedCountryName);
                }}
              >
                {Country.getAllCountries().map(country => (
                  <option key={country.isoCode} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('fivePlusApp.userProfile.state')}
                id="user-profile-state"
                name="state"
                data-cy="state"
                type="select"
                className="col-4"
                value={selectedState?.isoCode}
                onChange={e => {
                  const selectedStateIsoCode = e.target.value;
                  handleStateChange(State.getStateByCodeAndCountry(selectedStateIsoCode, selectedCountry?.isoCode || ''));
                }}
              >
                {selectedCountry &&
                  State.getStatesOfCountry(selectedCountry.isoCode).map(state => {
                    return (
                      <option key={state.isoCode} value={state.isoCode}>
                        {state.name}
                      </option>
                    );
                  })}
              </ValidatedField>
              <ValidatedField
                label={translate('fivePlusApp.userProfile.zipCode')}
                id="user-profile-zipCode"
                name="zipCode"
                data-cy="zipCode"
                type="text"
                className="col-4"
                validate={{ pattern: /^(?:[A-Z]{1,2}\d[A-Z\d]? \d[A-Z]{2}|[0-9A-Za-z\s\-]+)$/i }}
                errorMessage={{
                  pattern: 'Please enter a valid ZIP code',
                }}
              />
              <Col md="12" className="mb-2">
                <div className="align-items-center d-flex form-title pb-3">
                  <h3 className="pt-3">Details</h3> <hr className="w-100 m-0 ms-3" />
                </div>
              </Col>
              <Row>
                <ValidatedField
                  label={'instagram Id'}
                  id="user-profile-instagramUrl"
                  name="instagramUrl"
                  data-cy="instagramUrl"
                  type="text"
                  className="col-4 pe-3"
                />
              </Row>
              {selectedYear !== undefined &&
                currentDecadeField.length > 0 &&
                currentDecadeField.map((field, index) => (
                  <React.Fragment key={field.name}>
                    <ValidatedField
                      label={field.label}
                      id={`user-profile-${field.name}`}
                      name={field.name}
                      data-cy={field.name}
                      onChange={e => handleDesInputChange(index, e)}
                      type={field.type}
                      className="col-4"
                      value={field.value}
                    />
                    {field.loadingAI ? <Spinner className="loading-btn">Loading...</Spinner> : ''}
                    {field.enhanceWithAI && (
                      <Button className="enhance-ai-btn" onClick={() => enhanceWithAIHandler(index)}>
                        Enhance with AI
                      </Button>
                    )}
                  </React.Fragment>
                ))}
              <ValidatedField
                label={'Community Engagement & 5 Causes to Support/Non Profits'}
                id="user-profile-helpCommunityDescription"
                name="helpCommunityDescription"
                data-cy="helpCommunityDescription"
                type="textarea"
                className="col-4"
              />
              <Col md="12" className="mb-2">
                <div className="align-items-center d-flex form-title pb-3">
                  <h3 className="pt-3"> Topics</h3> <hr className="w-100 m-0 ms-3" />
                </div>
              </Col>
              <div id="topic-ques">
                <ul className="d-flex">
                  {topics.map(topic => (
                    <li>
                      <div className="topic-checkbox" key={topic.id}>
                        <input
                          type="checkbox"
                          id={`topic-${topic.id}`}
                          name={`topic-${topic.id}`}
                          value={topic.name}
                          checked={selectedTopics.some(t => t.id === topic.id)}
                          onChange={e => {
                            if (e.target.checked) {
                              setSelectedTopics([...selectedTopics, topic]);
                            } else {
                              setSelectedTopics(selectedTopics.filter(t => t.id !== topic.id));
                            }
                          }}
                          className="onboard-topic"
                        />
                        <label htmlFor={`topic-${topic.id}`}>{topic.name}</label>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              {isMentee &&
                fields.map((field, index) => (
                  <React.Fragment>
                    <ValidatedField
                      key={index}
                      type="text"
                      name={`question_${index}`}
                      label={`${index + 1} Question For Mentor`}
                      value={field.value}
                      onChange={e => handleQuesionsInputChange(index, e)}
                      validate={{ required: true }}
                      autoFocus={index === fields.length - 1}
                    />
                    {loadingMainAI && loadingIndex === index ? (
                      <Spinner className="loading-btn mentee-loading-btn">Loading...</Spinner>
                    ) : (
                      ''
                    )}
                    <Button
                      className="enhance-ai-btn mentee-enhance-ai-btn"
                      onClick={() => {
                        setLoadingIndex(index); // Set loading index before calling enhanceQuestionWithAI
                        enhanceQuestionWithAI(index);
                      }}
                    >
                      Enhance with AI
                    </Button>
                  </React.Fragment>
                ))}
              <ValidatedField
                label={'By checking this box you are agreeing to our terms of service'}
                id="user-profile-termsAccepted"
                name="termsAccepted"
                data-cy="termsAccepted"
                check
                type="checkbox"
                required
              />
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                {/* <FontAwesomeIcon icon="save" />
                &nbsp; */}
                <Translate contentKey="entity.action.save">Submit</Translate>
              </Button>
            </ValidatedForm>
          </Col>
          {loadingMain ? (
            <div className="loader_custom">
              <Spinner>Loading...</Spinner>
            </div>
          ) : (
            ''
          )}
        </Row>
      )}
    </div>
  );
};

export default UserOnBoradForm;
