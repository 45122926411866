import React from 'react';
import { Translate } from 'react-jhipster';
import { Alert } from 'reactstrap';
import './page-not-found.css';

const PageNotFound = () => {
  const handleClick = () => {
    // Get the hostname of the current location
    const currentHostname = window.location.hostname;
    // Construct the URL for the main domain
    const mainDomainURL = `https://${currentHostname}/`;
    // Redirect to the main domain
    window.location.href = mainDomainURL;
  };
  return (
    <main className="error-block">
      <div className="container-404">
        <div className="row">
          <div className="col-md-6 align-self-center logo-img">
            <div className="app-loading">
              <div className="lds-pacman">
                <div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 align-self-center content-block">
            <h1>404</h1>
            <h2>UH OH! You're lost.</h2>
            <p>
              The page you are looking for does not exist. How you got here is a mystery. But you can click the button below to go back to
              the homepage.
            </p>
            <button className="btn yellow" onClick={handleClick}>
              <a href="#">HOME</a>
            </button>
          </div>
        </div>
      </div>
    </main>
  );
};

export default PageNotFound;
