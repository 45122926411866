import React, { useEffect, useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { useDispatch } from 'react-redux';
import { getEntityNewsFeed } from 'app/entities/news-feed/news-feed.reducer';
import { getEntities, getEntityInspirationsAdmin } from 'app/entities/inspiration/inspiration.reducer';
import { useNavigate } from 'react-router-dom';
import { getEntitiesProfile, getEntityAdminTopFive } from 'app/entities/adminprofile/adminprofile.reducer';

interface Props {
  tab1Content: React.ReactNode;
  tab2Content: React.ReactNode;
  tab3Content: React.ReactNode;
  tab4Content: React.ReactNode;
  tab5Content: React.ReactNode;
  tab6Content: React.ReactNode;
  currentActiveTab: React.ReactNode;
}

const AdminTabs: React.FC<Props> = ({ tab1Content, tab2Content, tab3Content, tab4Content, tab5Content, tab6Content, currentActiveTab }) => {
  const [activeTab, setActiveTab] = useState('admindashboard');
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const account = useAppSelector(state => state.authentication.account);
  const userProfileEntity = useAppSelector(state => state.userProfile.entity[0]);

  const id = account.id;
  const navigate = useNavigate();

  useEffect(() => {
    if (currentActiveTab === 'inspirations') {
      setActiveTab('inspirations');
    }
  }, [currentActiveTab]);

  const toggle = async (tab: string) => {
    setLoading(true);
    if (activeTab !== tab) setActiveTab(tab);

    if (tab === 'admindashboard') {
      await dispatch(getEntityAdminTopFive());
    } else if (tab === 'users') {
      await dispatch(getEntitiesProfile({ page: 0, size: 10 }));
    } else if (tab === 'newsfeed') {
      await dispatch(
        getEntityNewsFeed({
          id: id,
          page: 0,
          size: 10,
          sort: ``,
        })
      );
    } else if (tab === 'inspirations') {
      await dispatch(
        getEntityInspirationsAdmin({
          page: 0,
          size: 10,
          sort: ``,
        })
      );
    } else if (tab === 'topic') {
      await dispatch(getEntitiesProfile({ page: 0, size: 10 }));
    }
    navigate(`/admin/${tab}`);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  return (
    <div className="wrapper">
      <Nav tabs>
        <NavItem>
          <NavLink className={classnames({ active: activeTab === 'admindashboard' })} onClick={() => toggle('admindashboard')}>
            <img src="content/images/Dashboard-icon.svg" alt="icon" /> Admin Dashboard
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className={classnames({ active: activeTab === 'users' })} onClick={() => toggle('users')}>
            <img src="content/images/Users-icon.svg" alt="icon" /> Users
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className={classnames({ active: activeTab === 'newsfeed' })} onClick={() => toggle('newsfeed')}>
            <img src="content/images/News-Feed-icon.svg" alt="icon" /> Newsfeed
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className={classnames({ active: activeTab === 'inspirations' })} onClick={() => toggle('inspirations')}>
            <img src="content/images/Inspiration-icon.svg" alt="icon" /> Inspirations
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className={classnames({ active: activeTab === 'Topic' })} onClick={() => toggle('topic')}>
            <img src="content/images/MyConnections-icon.svg" alt="icon" /> Topics
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className={classnames({ active: activeTab === 'point-details' })} onClick={() => toggle('point-details')}>
            <img src="content/images/ChartDonut.svg" alt="icon" /> Point Details
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="admindashboard">{activeTab === 'admindashboard' && tab1Content}</TabPane>
        <TabPane tabId="users">{activeTab === 'users' && tab2Content}</TabPane>
        <TabPane tabId="newsfeed">{activeTab === 'newsfeed' && tab3Content}</TabPane>
        <TabPane tabId="inspirations">{activeTab === 'inspirations' && tab4Content}</TabPane>
        <TabPane tabId="topic">{activeTab === 'topic' && tab5Content}</TabPane>
        <TabPane tabId="point-details">{activeTab === 'point-details' && tab6Content}</TabPane>
      </TabContent>
    </div>
  );
};

export default AdminTabs;
