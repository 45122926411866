import axios from 'axios';
import { Storage } from 'react-jhipster';

const TIMEOUT = 1 * 60 * 1000;
axios.defaults.timeout = TIMEOUT;
axios.defaults.baseURL = SERVER_API_URL;
const setupAxiosInterceptors = onUnauthenticated => {
  let isRefreshing = false;
  const clearLocalStorage = () => {
    localStorage.clear();
  };

  const redirectToLogin = () => {
    clearLocalStorage();
    window.location.href = '/';
  };

  function getAccessTokenFromLocalStorage() {
    return localStorage.getItem('access_token');
  }

  function getAccessTokenExpiryFromLocalStorage() {
    return localStorage.getItem('access_token_expiry');
  }
  const accessTokenExpiry = localStorage.getItem('access_token_expiry');

  function isAccessTokenValid() {
    const accessToken = localStorage.getItem('access_token');
    const expiryTimestampStr = localStorage.getItem('access_token_expiry');
    if (!accessToken || !expiryTimestampStr) {
      return false;
    }

    const currentTimestamp = new Date().getTime();
    const expiryTimestamp = parseInt(expiryTimestampStr, 10);
    const expires_time_date = new Date().getTime() + expiryTimestamp;
    return currentTimestamp < expires_time_date;
  }
  async function refreshAccessToken() {
    if (isRefreshing) {
      return;
    }
    isRefreshing = true;
    const refreshToken = localStorage.getItem('refresh_token');

    try {
      const response = await axios.get(`auth/get/new/access/token?refreshToken=${refreshToken}&codeVerifier=null`);

      const newAccessToken = response.data.access_token;
      const newRefreshtokenAccessToken = response.data.refresh_token;
      localStorage.setItem('access_token', newAccessToken);
      const expires_in = response.data.expires_in; // Assuming the response contains expires_in
      const expires_time = expires_in;
      const curr_Time = new Date().getTime().toString();
      localStorage.setItem('access_token_expiry', expires_time.toString());
      localStorage.setItem('refresh_token', newRefreshtokenAccessToken);
      localStorage.setItem('expiryFrom', curr_Time);
    } catch (error) {
      console.error('Error refreshing access token:', error);
      redirectToLogin();

      onUnauthenticated();
    } finally {
      isRefreshing = false;
    }
  }

  function shouldRefreshToken(expiryTimestamp) {
    if (!expiryTimestamp) {
      return false;
    }
    const currentTime = new Date().getTime();
    const expiryTimestampfinal = parseInt(expiryTimestamp, 10);
    const expiryFrom = parseInt(localStorage.getItem('expiryFrom'));
    const timeToExpiration = expiryFrom + expiryTimestampfinal;
    const timeDiff = timeToExpiration - currentTime;
    const expirationThreshold = 0.9 * expiryTimestampfinal;
    // console.log('timeDiff', timeDiff);
    // console.log('expirationThreshold', expirationThreshold);
    // console.log('currentTime', currentTime);
    // console.log('timeToExpiration', timeToExpiration);
    const timeToExpiration1 = new Date(timeToExpiration);
    const expiryFormattedDate = timeToExpiration1.toLocaleString();
    // console.log('Expiry Timestamppp:', expiryFormattedDate);
    const currentTimeDate = new Date(currentTime).toLocaleString();
    // console.log('current Timestamp:', currentTimeDate);

    return timeDiff < expirationThreshold;
  }

  const onRequestSuccess = async config => {
    const accessToken = localStorage.getItem('access_token');
    const expiryTimestamp = getAccessTokenExpiryFromLocalStorage();
    if (accessTokenExpiry) {
    } else {
      console.log('Access Token Cookie not found or does not have an expiry attribute.');
    }
    if (accessToken) {
      try {
        if (accessToken && isAccessTokenValid()) {
          config.headers = {
            ...config.headers,
            Authorization: `Bearer ${accessToken}`,
          };
          if (shouldRefreshToken(expiryTimestamp)) {
            console.log('Refreshing token...');
            // Token is 90% spent, refresh it
            await refreshAccessToken();
            // Get the updated token and update the request header
            const updatedAccessToken = localStorage.getItem('access_token');

            config.headers.Authorization = `Bearer ${updatedAccessToken}`;
          }
        } else {
          redirectToLogin();
          // onUnauthenticated();
        }
      } catch (error) {
        // Handle non-JSON responses here
        console.error('Error parsing JSON:', error);
      }
    }

    return config;
  };
  const onResponseSuccess = response => response;
  const onResponseError = err => {
    const status = err.status || (err.response ? err.response.status : 0);
    if (!err.config || !err.config.__isRetryRequest) {
      if (status === 403 || status === 401) {
        //redirectToLogin();
        onUnauthenticated();
      }
      // if (status === 500 ) {
      //   localStorage.removeItem('access_token');
      //   localStorage.removeItem('access_token_expiry');
      //   localStorage.removeItem('refresh_token');
      //   localStorage.removeItem('id_token');
      //   localStorage.removeItem('expiryFrom');
      //   redirectToLogin();
      //   onUnauthenticated();
      // }
      return Promise.reject(err);
    }
    // return Promise.reject(err);
  };
  axios.interceptors.request.use(onRequestSuccess);
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default setupAxiosInterceptors;
