import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import UserAchivements from './user-achivements';
import UserAchivementsDetail from './user-achivements-detail';
import UserAchivementsUpdate from './user-achivements-update';
import UserAchivementsDeleteDialog from './user-achivements-delete-dialog';

const UserAchivementsRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<UserAchivements />} />
    <Route path="new" element={<UserAchivementsUpdate />} />
    <Route path=":id">
      <Route index element={<UserAchivementsDetail />} />
      <Route path="edit" element={<UserAchivementsUpdate />} />
      <Route path="delete" element={<UserAchivementsDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default UserAchivementsRoutes;
